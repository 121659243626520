import React, { Component } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";

// MUI Stuff
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";


import NumberFormatCustom from "./TextFormat";

import axios from "axios";

import CheckoutError from "./CheckoutError";



import {
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Dialog,
  FormControl,
} from "@material-ui/core";

// Redux stuff
import { connect } from "react-redux";
import { 
        postTool, 
        uploadImage,
        getProductNameList,
        getCategoryList,
        getRatingList,
        getBrandList, } from "../../redux/actions/dataActions";

class Security extends Component {
  constructor(props) {
    super();
    this.state = {
      handle: "",
      errors: {},
      open: false,
      toolImageList: [],
      productNameList: [],
      value:"",
      email:props.user.credentials.email
    };
  }

  componentDidMount() {

  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.UI.errors) {
      this.setState({ errors: nextProps.UI.errors });
    }
    if (nextProps.open) {
      this.setState({ open: nextProps.open });
    }
    if (nextProps.user.credentials) {
        this.setState({ name: nextProps.user.credentials.firstName,
                        phone: nextProps.user.credentials.phone,
                        lastName: nextProps.user.credentials.lastName,
                        email: nextProps.user.credentials.email,
                        genere: nextProps.user.credentials.genere,
                        date: nextProps.user.credentials.date });
      }
  }

  handleClickOpen = (event) => {
    event.preventDefault();
    this.setState({
      open: true,
    });
  };

  setValue = (value) => {
    this.setState({
      address: value,
    });
  }

  handleClose = (event) => {
    event.preventDefault();
    this.setState({
      open: false,
    });  
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    let data ="";
      try {
        data = await axios.post("/resetPassword", { emailAddress:this.state.email});
        this.setState({"open": true})
      } catch (error) {
        console.log(error.response.data.message);
        if(error.response){
        this.setState({"error": error.response.data.message})
        }
        else{
          this.setState({"error": error})
        }
        return;
      }
  };

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleChangeDateFrom = (date) => {
    this.setState({
      date: date,
    });
  };

  setName = (value) => {
    this.setState({value:value});
    this.setState({name: value.name})
  }

  handleFreeDeliveryClick = () => {
    let deliveryStatus = !this.state.delivery;
    this.setState({
      delivery: deliveryStatus,
    });
  }


  render() {
    const {
      classes,
      UI: { loading },
      values,
      productNameList
    } = this.props;

    const {
      user,
    } = this.props;

    console.log("User: ", user.credentials)

    const { errors, value, delivery, open, error, email } = this.state;
    let isDisabled = email == ""

    return (
          <div className={classes.fullWidth}>
                    <FormControl fullWidth noValidate autoComplete="off" onSubmit={this.handleSubmit}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={12}>
                          <Button
                            type="submit"
                            variant="contained"
                            color="secondary"
                            fullWidth
                            onClick={this.handleSubmit}
                            disabled={isDisabled}
                          >
                            Reset
                          </Button>
                          <Dialog
                            open={open}
                            keepMounted
                            onClose={this.handleClose}
                            aria-labelledby="alert-dialog-slide-title"
                            aria-describedby="alert-dialog-slide-description"
                          >
                          <DialogTitle id="alert-dialog-slide-title">{"Congrats!"}</DialogTitle>
                          <DialogContent>
                            <DialogContentText id="alert-dialog-slide-description">
                              Se ha enviado un mail para cambiar el password
                            </DialogContentText>
                          </DialogContent>
                          <DialogActions>
                            <Button onClick={this.handleClose} color="secondary" variant="contained">
                              Ok
                            </Button>
                          </DialogActions>
                        </Dialog>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                          {error && <CheckoutError>{error}</CheckoutError>}
                          </Grid>
                      </Grid>                    
                    </FormControl>
          </div>
    );
  }
}

Security.propTypes = {
  classes: PropTypes.object.isRequired,
  UI: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  UI: state.UI,
  data: state.data,
  user: state.user,
});

const mapActionsToProps = {
  //Requests with redux
    // postTool,
  };

const styles = (theme) => ({
  root:{
    marginLeft:"0px"
  },
  fullWidth :{
    width: "25vw",
    // position: "relative",
    // left: "50%",
    // right: "50%",
    // marginLeft: "-150vw",
    // marginRight: "-150vw",

    [theme.breakpoints.down("sm")]: {      
      width: "100%",
    },
  },
  input :{
    // backgroundColor: "rgba(0, 0, 0, 0.08)",
    position: 'relative',
    borderRadius:'3px',
    '& $notchedOutline': {
        borderColor: 'rgba(0, 0, 0, 0.23)',
        borderWidth: 0,
    },
    '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
        borderColor: '#4A90E2',
        borderWidth: 0,
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
            borderColor: 'rgba(0, 0, 0, 0.23)',
            borderWidth: 0,
        },
    },
    '&$focused $notchedOutline': {
        borderColor: '#4A90E2',
        borderWidth: 0,
    },
  },
  card: {
    display: "flex",
    marginLeft: "auto",
    marginRight: "auto",
    maxWidth: "400px",
    marginTop: theme.spacing(0),
  },
  h5: {
    display: "flex",
    marginBottom: theme.spacing(0),
    padding: theme.spacing(0),
    fontFamily: "Open Sans",
    fontSize: "24px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1,
    letterSpacing: "0.18px",
    color: "rgba(0, 0, 0, 0.87)",
    alignItems: "left",
    justifyContent: "left",
    textAlign: "left",
  },
  content: {
    display: "flex",
    marginBottom: theme.spacing(0),
    padding: theme.spacing(0),
    fontFamily: "Open Sans",
    fontSize: "14px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1,
    letterSpacing: "0.18px",
    color: "rgba(0, 0, 0, 0.87)",
    alignItems: "left",
    justifyContent: "left",
    textAlign: "left",
  },
});

export default connect(mapStateToProps, mapActionsToProps)(
  withStyles(styles)(Security)
);
