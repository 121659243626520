import React, { useMemo } from "react";
import { useState } from "react";

import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";

import axios from "axios";

 import CheckoutError from "./CheckoutError";

// MUI Stuff
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  TextField,
} from "@material-ui/core";


import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


const useOptions = () => {
  const options = useMemo(
    () => ({
      style: {
        base: {
          fontSize: "20px",
          color: "#424770",
          letterSpacing: "0.010em",
          fontWeight: "100",
          fontStretch: "normal",
          fontStyle: "normal",
          // lineHeight: "1.5",
          fontFamily: "Source Code Pro, monospace",
          "::placeholder": {
            color: "#aab7c4"
          }
        },
        invalid: {
          color: "#9e2146"
        }
      }
    }),
  );

  return options;
};

const CardForm = ({ price, shipping, onSuccessfulCheckout }) => {
  const stripe = useStripe();
  const elements = useElements();
  const options = useOptions();

  const [isProcessing, setProcessingTo] = useState(false);
  const [checkoutError, setCheckoutError] = useState();
  const [cardName, setCardName] = useState();
  // const [receipt_url, setReceipt] = useState();
  const [payment, setPayment] = useState();

  const isValidate = (price && cardName && shipping.email && shipping.city && shipping.line1 && shipping.state && shipping.zip) ? true : false

  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    onSuccessfulCheckout(payment);

  };

  const handleChange = ev => {
    setCardName(ev.target.value)
  }


  const handleSubmit = async event => {
    event.preventDefault();

    setProcessingTo(true);

    const billingDetails = {
      name: cardName,
      email: shipping.email,//"arielguerra07@gmail.com",
      address: {
        city: shipping.city,//"Miami",
        line1: shipping.line1,//"Abbott Ave.",
        state: shipping.state,//"Florida",
        postal_code: shipping.zip // 33141
      }
    };


    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
      billing_details: billingDetails
    });

    if (!error) {
      const { id } = paymentMethod;

      try {
        const { data } = await axios.post("/charge", { id, amount: price });
        setPayment(data.confirm.id)

        handleOpen();

        setProcessingTo(false);
        elements.getElement(CardElement).clear();
      } catch (error) {
        console.log(error);
        setCheckoutError(error.message);
        setProcessingTo(false);
        return;
      }
    }
    else{
      setCheckoutError(error.message);
      setProcessingTo(false);
      return;
    }
  };


  return (
    <Grid container spacing={3}>
      <Grid item md={10} sm={12} style={{ width: "100%"}}>
        <TextField
          id="name"
          name="name"
          type="text"
          label="Card Name"
          className="textField"
          // helperText={errors.address}
          // error={errors.address ? true : false}
          //value={this.state.name}
          onChange={handleChange}
          size="small"
          variant="outlined"
          fullWidth
        />
      </Grid>
      <Grid item md={10} sm={12} style={{ width: "100%"}}>
        <CardElement options={options} className="card-element" />
      </Grid>
      <Grid item md={10} sm={12} style={{ width: "100%"}}>
        <Button
          type="submit"
          variant="contained"
          color="secondary"
          fullWidth
          onClick={handleSubmit}
          disabled={!isValidate || isProcessing || !stripe}
        >
          Pay
          {isProcessing || !stripe && (
                              <CircularProgress
                                size={30}
                                // className={classes.progress}
                              />
                            )}
        </Button>
        <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{"Congrats!"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Su compra esta en proceso de aprobacion.
            {/* See  <Link href={receipt_url} color="inherit" target="_blank" rel="noreferrer"> receipt </Link> */}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary" variant="contained">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
      </Grid>
      <Grid item md={10} sm={12} style={{ width: "100%"}}>
        {checkoutError && <CheckoutError>{checkoutError}</CheckoutError>}
        </Grid>

    </Grid>
  );
};

export default CardForm;
