import React, { Component } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash.isempty';

import GridProductCard from "../../pages/GridProductCard";


// examples:
import GoogleMap from './GoogleMap';

// consts: [34.0522, -118.2437]
var CENTER = [34.0522, -118.2437];

// InfoWindow component
const InfoWindow = (props) => {
  const { place, endDate, startDate } = props;
  const infoWindowStyle = {
    position: 'relative',
    borderRadius:8,
    bottom: 25,
    left: '20px',
    width: 220,
    backgroundColor: 'white',
    boxShadow: '0 2px 7px 1px rgba(0, 0, 0, 0.3)',
    padding: 0,
    // fontSize: 14,
    zIndex: 100,
  };

  return (
    <div style={infoWindowStyle}>
    <GridProductCard 
                    product={place}
                    endDate= {endDate}
                    startDate={startDate}
                    addProductToCart={props.addProductToCart}
    ></GridProductCard>
     
    </div>
  );
};

// Marker component
const Marker = ({ show, place, endDate,  startDate, addProductToCart }) => {
  const markerStyle = {
    border: '1px solid white',
    borderRadius: '50%',
    height: 20,
    width: 20,
    backgroundColor: show ? 'red' : 'blue',
    cursor: 'pointer',
    zIndex: 10,
  };

  return (
    <>
      <div style={markerStyle} />
      {show && <InfoWindow 
                    place={place} 
                    endDate= {endDate}
                    startDate={startDate}
                    addProductToCart={addProductToCart}
                    />}
    </>
  );
};

class MarkerInfoWindow extends Component {
  constructor(props) {
    super(props);

    this.state = {
      places: [],
      products: this.props.productList,
      endDate: this.props.endDate,
      startDate: this.props.startDate
    };
  }

  componentDidMount() {
    // fetch('places.json')
    //   .then((response) => response.json())
    //   .then((data) => {
    //     data.results.forEach((result) => {
    //       result.show = false; // eslint-disable-line no-param-reassign
    //     });
    //     this.setState({ places: data.results });
    //   });
  }

  // onChildClick callback can take two arguments: key and childProps
  onChildClickCallback = (key) => {
    this.setState((state) => {
      const index = state.products.findIndex((e) => e.toolId === key);
      state.products[index].address.show = !state.products[index].address.show; // eslint-disable-line no-param-reassign
      return { products: state.products };
    });
  };

  render() {
    const { places, products, endDate,  startDate} = this.state;
    !isEmpty(products)&& (
        CENTER = [products[0].address.geometry.location.lat, products[0].address.geometry.location.lng]
    )
    return (
      <div className="map">
        {!isEmpty(products) && (
          <GoogleMap
            defaultZoom={10}
            defaultCenter={CENTER}
            bootstrapURLKeys={{ key: "AIzaSyDpdv91FCJxMvanW382mbvo1wpy14kiA-Q" }}
            onChildMouseUp={this.onChildClickCallback}
          >
            {products.map((place) => (
              <Marker
                key={place.toolId}
                lat={place.address.geometry.location.lat}
                lng={place.address.geometry.location.lng}
                show={place.address.show}
                place={place}
                endDate= {endDate}
                startDate={startDate}
                addProductToCart={this.props.addProductToCart}
              />
            ))}
          </GoogleMap>
        )}
      </div>
    );
  }

}

MarkerInfoWindow.propTypes = {
    addProductToCart: PropTypes.func.isRequired,
};


InfoWindow.propTypes = {
    addProductToCart: PropTypes.func.isRequired,
  place: PropTypes.shape({
    name: PropTypes.string,
    formatted_address: PropTypes.string,
    rating: PropTypes.number,
    types: PropTypes.arrayOf(PropTypes.string),
    price_level: PropTypes.number,
    opening_hours: PropTypes.shape({
      open_now: PropTypes.bool,
    }),
  }).isRequired,
};

Marker.propTypes = {
  show: PropTypes.bool.isRequired,
  addProductToCart: PropTypes.func.isRequired,
  place: PropTypes.shape({
    name: PropTypes.string,
    formatted_address: PropTypes.string,
    rating: PropTypes.number,
    types: PropTypes.arrayOf(PropTypes.string),
    price_level: PropTypes.number,
    opening_hours: PropTypes.shape({
      open_now: PropTypes.bool,
    }),
  }).isRequired,
};

export default MarkerInfoWindow;