import React, { useState } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import ProductHeroLayout from "./ProductHeroLayout";

import SearchTool from "../../../components/search/searchTool";

const styles = (theme) => ({
  button: {
    borderRadius: 5,
    minWidth: 200,
    margin: theme.spacing(1),
  },
  h5: {
    position: "relative",
    top: 155,
    minHeight: 235,
    marginBottom: theme.spacing(0),
    padding: theme.spacing(0),
    textAlign: "center",
    fontSize: 44,
    fontFamily: "Open Sans",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.64,
    letterSpacing: "normal",
    color: "#ffffff",

    [theme.breakpoints.down("sm")]: {
      //marginTop: theme.spacing(30),
      top: -50,
      minHeight: 0,
      fontSize: 30,
      textShadow: "0 2px 2px rgba(0, 0, 0, 0.25)",
      lineHeight: 1.2,
    },
  },
  more: {
    marginBottom: theme.spacing(3),
    padding: theme.spacing(0),
    maxWidth: 850,
    textAlign:"center",
	  margin:"0 auto",
    fontFamily: "Open Sans",
    fontSize: 23,
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.39,
    letterSpacing: 0.8,
    color: "#ffffff",
    [theme.breakpoints.down("sm")]: {
      fontSize: 15,
      lineHeight: 1.25,
      position: "relative",
      top: -20,
      marginBottom: theme.spacing(0),
      letterSpacing: 1.2,
    },
  },
  margin: {
    margin: theme.spacing(1),
  },
});

function ProductHero(props) {
  const { classes } = props;
  return (
    // <ProductHeroLayout>
          <Container style={{padding:6}}>

      <Typography
        align="center"
        variant="body2"
        marked="left"
        className={classes.h5}
      >
        Your next project has never been closer
      </Typography>
      <Typography
        color="inherit"
        align="center"
        variant="body2"
        className={classes.more}
      >
        Find the tools and equipment you need for all your home improvement and construction needs.
      </Typography>
      <SearchTool></SearchTool>

      </Container>
  );
}

ProductHero.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProductHero);
