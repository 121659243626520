import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import ToolImage from "../components/tool/ToolImage";
import StaticProfile from "../components/profile/StaticProfile";
import Grid from "@material-ui/core/Grid";
import Chip from "@material-ui/core/Chip";
import Button from "@material-ui/core/Button";

import Navbar from "../components/layout/Navbar";

import Typography from "@material-ui/core/Typography";
import {
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  ExpansionPanel,
} from "@material-ui/core";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Link from "@material-ui/core/Link";

import withStyles from "@material-ui/core/styles/withStyles";
import { connect } from "react-redux";
import { getComments, getUserData } from "../redux/actions/userActions";
import AppBar from '@material-ui/core/AppBar';
import TabPanel from './TabPanel';

import axios from "axios";


import ShoppingCartOutlinedIcon from "@material-ui/icons/ShoppingCartOutlined";
import DeleteForeverOutlinedIcon from "@material-ui/icons/DeleteForeverOutlined";

import {
  Box,
  IconButton,
} from "@material-ui/core";

import Rating from "@material-ui/lab/Rating";

import formatDistance from "date-fns/formatDistance";
import differenceInDays from "date-fns/differenceInDays";
import { format } from "date-fns";



// import AppBar from '@material-ui/core/AppBar';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';

// import { TabContext } from '@material-ui/lab';
// import Tabs from '@material-ui/lab/TabList';
// import TabPanel from '@material-ui/lab/TabPanel';

import ButtonAddTool from "../components/tool/ButtonAddTool";

class user extends Component {
  state = {
    profile: null,
    toolIdParam: null,
    toolsForRevision:[],
    orders:[],
    value: 1
  };

  getToolsForRevision = () => {
    return axios.post("/revisions", {  });
  };

  getOrders = () => {
    return axios.post("/orders", {  });
  };

  setRevision = (user, product, payment, revision) => {
    return axios.post("/revision", { user, product, payment, revision });
  }

  updatePayment = (user, payment) => {
    return axios.post("/updatePayment", { user, payment });
  }

  componentWillMount() {
    // const handle = this.props.match.params.handle;
    this.props.getComments();

    this.getToolsForRevision().then((data) =>
      this.setState({ toolsForRevision: [...data.data] })
    );

    this.getOrders().then((data) =>
      this.setState({ orders: [...data.data] })
    );
  }

  handleChange = (event, newValue) => {
    this.setState({ value: newValue });
  };

  retrieveRevisions= (  ) => {
    this.getToolsForRevision().then((data) =>
      this.setState({ toolsForRevision: [...data.data] })
    );
  }

  retreiveOrders= (  ) => {
    this.getOrders().then((data) =>
      this.setState({ orders: [...data.data] })
    );
  }

  handleAprove = (event, user, product, payment  ) => {
    console.log("Payment: ", payment);
    console.log("Product: ", product);
    console.log("User: ", user);

    let revision = "Approved"

    this.setRevision(user, product, payment, revision)
        .then((data) =>
          this.retrieveRevisions()
        );
  };

  handleCancel = (event, user, product, payment  ) => {
    console.log("Payment: ", payment);
    console.log("Product: ", product);
    console.log("User: ", user);

    let revision = "Cancel"

    this.setRevision(user, product, payment, revision)
        .then((data) =>
          this.retrieveRevisions()
        );
  };

  a11yProps = (index) => {
    return {
      id: `wrapped-tab-${index}`,
      'aria-controls': `wrapped-tabpanel-${index}`,
    };
  }

  
  render() {
    const {
      classes,
      user: { authenticated, loading, tools, commentsOfUser },
    } = this.props;

    const {
      orders
    } = this.state;

    let countTool= tools=== null ? 0 : tools.length
    let countOrders = orders === null ? 0 : orders.length
    let countRevisions= this.state.toolsForRevision=== null ? 0 : this.state.toolsForRevision.length

    const toolsMarkup = !loading ? (
      tools === null ? (
        <p>No tools from this user</p>
      ) : (
        tools.map((product) => (
          <Grid item xs={12} sm={12} md={4}>
            <Grid container spacing={2}>
              <Grid item md={3}>
              <Link
                href={`/users/auth/tool/${product.toolId}`}
              >
                    <img style={{display: "block", marginLeft: "auto",  marginRight: "auto", padding: "0px", borderRadius: "8px", width: "90px", height: "90px"}}
                        src={product.toolImageList[0]} alt="..." />
              </Link>
              </Grid>
              <Grid item md={9}>
                <div key={product.id} style={{ display: "flex", marginTop:"8px"}}>
                  <div style={{ display: "block", width: "100%", textAlign: "left"}}>
                    <div>
                    <h6 style={{ width: "100%", marginBottom: "2px" }}>
                      {product.name}
                    </h6>
                    </div>
                    <div>
                    <small style={{ width: "100%", marginBottom: "2px" }}className="text-muted">
                      Price: {product.price}
                    </small>
                    </div>
                    <div>
                      <small style={{ width: "100%", marginBottom: "2px" }}className="text-muted">
                        Category:{" "} <Chip label={product.category} />
                      </small>
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Grid>
        ))
      )
    ) : (
      ""
    );

    const toolsRevision = !loading ? (
      this.state.toolsForRevision === null ? (
        <p>No tools for revision</p>
      ) : (
        this.state.toolsForRevision.map((product) => (
          <Grid item  xs={12} sm={12} md={4}>
            <Grid container spacing={2}>
              <Grid item md={3}>
                    <img style={{display: "block", marginLeft: "auto",  marginRight: "auto", padding: "0px", borderRadius: "8px", width: "90px", height: "90px"}}
                        src={product.image} alt="..." />
              </Grid>
              <Grid item md={9}>
                <div key={product.id} style={{ display: "flex", marginTop:"8px"}}>
                  <div style={{ display: "block", width: "100%", textAlign: "left"}}>
                    <div>
                    <h6 style={{ width: "100%", marginBottom: "2px" }}>
                      {product.name}
                    </h6>
                    </div>
                    <div>
                              <small className="text-muted">
                                Start date: {" "}
                                  {format(
                                    new Date(product.startDate).getTime(),
                                    "MMMM dd yyyy"
                                  )}
                              </small>
                              </div>
                              <div>
                              <small className="text-muted">
                                End date: {" "}
                                  {format(
                                    new Date(product.endDate).getTime(),
                                    "MMMM dd yyyy"
                                  )}
                              </small>
                              </div>
                    <div>
                    <small className="text-muted">
                                End date: 
                              {format(
                  new Date(product.endDate).getTime(),
                  "MMMM dd yyyy"
                )}
                              </small>
                    </div>
                    <div>
                    <small style={{ width: "100%", marginBottom: "2px" }}className="text-muted">
                      Price: {product.price}
                    </small>
                    </div>
                    <div>
                    <small style={{ width: "100%", marginBottom: "2px" }}className="text-muted">
                      User: {product.user}
                    </small>
                    </div>
                  </div>
                  <div style={{ textAlign: "left" }}>
                    
                  </div>
                </div>
              </Grid>
            </Grid>
          </Grid>
        ))
      )
    ) : (
      ""
    );


    const ordersMarkup = !loading ? (
      orders === null ? (
        <p>No orders from this user</p>
      ) : (
        orders.map((order) => (
          <Grid item xs={12} sm={12} md={12}>
          <ExpansionPanel name="panel1" defaultExpanded={true} elevation={1}>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              >
                  <h5 style={{ width: "100%"}}>
                    Order: {order.paymentId}
                  </h5>
              </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Grid container style={{padding:"0px"}}>
              <Grid item md={12} sm={12} style={{ width: "100%"}}>
                    {order.products.map((product) => (
                      <Grid container spacing={2}>
                        <Grid item md={2} sm={2} style={{}}>
                          <div style={{ padding: "0px", borderRadius: "8px", width: "90px", height: "90px"}}>
                            <img style={{display: "block", marginLeft: "auto",  marginRight: "auto", padding: "0px", borderRadius: "8px", width: "90px", height: "90px"}} 
                                  src={product.image} alt="..." />
                          </div>
                        </Grid>

                        <Grid item md={10} sm={10} style={{paddingLeft: "0px" }}>
                          <div style={{alignItems: "baseline" }} key={product.id} className="cart__item">
                            <div style={{ marginRight: "0px", textAlign: "left", lignItems: "baseline", minWidth:"130px" }}>
                              <h6
                                className={classes.subtitle}
                                style={{ marginBottom: "2px" }}
                              >
                                {product.name}
                              </h6>
                              <div>
                              <small className="text-muted">
                              {`${product.address.street_number} 
                                ${product.address.route}, 
                                ${product.address.administrative_area_level_1}, 
                                ${product.address.postal_code}`}
                              </small>
                              </div>
                              <div>
                              <small className="text-muted">
                                Start date: {" "}
                                  {format(
                                    new Date(product.startDate).getTime(),
                                    "MMMM dd yyyy"
                                  )}
                              </small>
                              </div>
                              <div>
                              <small className="text-muted">
                                End date: {" "}
                                  {format(
                                    new Date(product.endDate).getTime(),
                                    "MMMM dd yyyy"
                                  )}
                              </small>
                              </div>

                                <div  className={classes.mobile} style={{ position:"relative", right: "-40px",   float: "right", marginRight: "0px", textAlign: "right", lignItems: "baseline" }}>
                                <div style={{display:"flex", justifyContent: "space-between"}}>
                                  <div>
                                    <h6 style={{ marginBottom: "2px", width: "50px"  }}>
                                      Price
                                    </h6>
                                  </div>
                                  <div>
                                    <h6 style={{ marginLeft:"10px", marginBottom: "2px" }}>
                                      -
                                    </h6>
                                  </div>
                                  <div>
                                    <h6 style={{ marginBottom: "2px", width: "70px" }}>
                                      ${parseFloat(product.price).toFixed(2)}
                                    </h6>
                                  </div>
                                </div>
                                <div style={{display:"flex", justifyContent: "space-between"}}>
                                  <div>
                                    <h6 style={{ marginBottom: "2px", width: "50px"  }}>
                                      Tax
                                    </h6>
                                  </div>
                                  <div>
                                    <h6 style={{ marginLeft:"10px", marginBottom: "2px" }}>
                                      -
                                    </h6>
                                  </div>
                                  <div>
                                    <h6 style={{ marginBottom: "2px", width: "70px"  }}>
                                      $0.00
                                    </h6>
                                  </div>
                                </div>
                                </div>
                            </div>

                            <div  className={classes.desktop} style={{ marginRight: "0px", textAlign: "right", lignItems: "baseline" }}>
                            <div style={{display:"flex", justifyContent: "space-between"}}>
                                  <div>
                                    <h6 style={{ marginBottom: "2px", width: "50px"  }}>
                                      Price
                                    </h6>
                                  </div>
                                  <div>
                                    <h6 style={{ marginLeft:"10px", marginBottom: "2px" }}>
                                      -
                                    </h6>
                                  </div>
                                  <div>
                                    <h6 style={{ marginBottom: "2px", width: "70px" }}>
                                      ${parseFloat(product.price).toFixed(2)}
                                    </h6>
                                  </div>
                                </div>
                                <div style={{display:"flex", justifyContent: "space-between"}}>
                                  <div>
                                    <h6 style={{ marginBottom: "2px", width: "50px"  }}>
                                      Tax
                                    </h6>
                                  </div>
                                  <div>
                                    <h6 style={{ marginLeft:"10px", marginBottom: "2px" }}>
                                      -
                                    </h6>
                                  </div>
                                  <div>
                                    <h6 style={{ marginBottom: "2px", width: "70px"  }}>
                                      $0.00
                                    </h6>
                                  </div>
                                </div>
                            </div>
                          </div>
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                  
               </Grid>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </Grid>
        ))
      )) : 
      (
        ""
      );


    return (
      <Fragment>
        <Navbar />
        <Grid container spacing={1} style={{paddingTop: "50px"}}>
          <Grid item sm={12}>
            <div className={classes.main}>
              <div className={classes.a}>
                <Typography
                  color="inherit"
                  align="left"
                  variant="h4"
                  marked="left"
                  className={classes.h5}
                >
                  Profile
                </Typography>
              </div>
            </div>
            {!loading ? (
              this.props.user === null ? null : (
                <StaticProfile user={this.props.user} />
              )
            ) : null}
          </Grid>
          <Grid item sm={12}>
            <div className={classes.main}>
              <div className={classes.a}>
                <Typography
                  color="inherit"
                  align="left"
                  variant="h4"
                  marked="left"
                  className={classes.h5}
                >
                  Your tools
                </Typography>
              </div>
              <div className={classes.c}>
                <ButtonAddTool />
              </div>
            </div>
          </Grid>

          <Grid item sm={12}>

              <AppBar style={{marginTop:"8px"}} position="static" elevation={0}>
                <Tabs value={this.state.value} onChange={this.handleChange} aria-label="wrapped label tabs example">
                  <Tab value="one" label= {`All (${countTool})`} wrapped
                    {...this.a11yProps('one')}
                  />
                  <Tab value="two" label={`Rented (${countRevisions})`} {...this.a11yProps('two')} />
                  <Tab value="three" label={`In Stored (${countTool})`} {...this.a11yProps('three')} />
                  <Tab value="four" label={`Orders (${countOrders})`} {...this.a11yProps('four')} />
                </Tabs>
              </AppBar>
              <TabPanel value={this.state.value} index="one">
                <Grid container spacing={2} style={{marginTop:"4px"}}>
                  {toolsMarkup}
                </Grid>
              </TabPanel>
              <TabPanel value={this.state.value} index="two">
                <Grid container spacing={2} style={{marginTop:"4px"}}>
                  {toolsRevision}
                </Grid>
              </TabPanel>
              <TabPanel value={this.state.value} index="three">
                <Grid container spacing={2} style={{marginTop:"4px"}}>
                  {toolsMarkup}
                </Grid>
              </TabPanel>
              <TabPanel value={this.state.value} index="four">
                <Grid container spacing={2} style={{marginTop:"4px"}}>
                  {ordersMarkup}
                </Grid>
              </TabPanel>


          </Grid>
        </Grid>
      </Fragment>
    );
  }
}

user.propTypes = {
  user: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  commentsOfUser: PropTypes.object.isRequired,
  getUserData: PropTypes.func.isRequired,
  getComments: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  data: state.data,
  user: state.user,
  commentsOfUser: state.user.commentsOfUser,
});

const styles = (theme) => ({
  grow: {
    flexGrow: 1,
  },
  heading: {
    display: "flex",
    marginBottom: theme.spacing(0),
    padding: theme.spacing(0),
    fontFamily: "Open Sans",
    fontSize: "20px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.2,
    letterSpacing: "0.15px",
    color: "rgba(0, 0, 0)",
    alignItems: "center",
    justifyContent: "left",
    textAlign: "left",
  },
  subtitle: {
    fontFamily: "Open Sans",
    fontSize: "16px",
    fontWeight: "600",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.5",
    letterSpacing: "0.15px",
    color: "#000000",
    marginRight: "5px",
  },
  rating: {
    width: 200,
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {      
      display: "none",
    }
  },
  desktop:{
    display: "block",
    [theme.breakpoints.down("sm")]: {      
      display: "none",
    },
  },

  mobile:{
    display: "none",
    [theme.breakpoints.down("sm")]: {      
      display: "block",
    },
  },
  card: {
    display: "flex",
    marginLeft: "auto",
    marginRight: "auto",
    maxWidth: "375px",
    marginTop: theme.spacing(10),
    // paddingRight: theme.spacing(2),
    // paddingTop: theme.spacing(0.6),
    // paddingBottom: theme.spacing(0.6),
  },
  cardcontent: {
    //margin: '4px',
    padding: "30px",
    "&:last-child": {
      paddingBottom: "30px",
    },
  },
  button: {
    borderRadius: 5,
    height: "40px",
    fontSize: 14,
    margin: theme.spacing(0),
  },
  title: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  main: { display: "flex" },
  c: { position: "absolute", right: 170 },
  h5: {
    display: "flex",
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(2),
    padding: theme.spacing(0),
    fontFamily: "Open Sans",
    fontSize: "24px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1,
    letterSpacing: "0.18px",
    color: "rgba(0, 0, 0, 0.87)",
    alignItems: "center",
    justifyContent: "left",
    textAlign: "left",
  },
  more: {
    display: "flex",
    fontFamily: "Open Sans",
    fontSize: "12px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: " normal",
    lineHeight: "1.33",
    letterSpacing: "0.4px",
    color: "#000000",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
  },
  background: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 62,
    bottom: 0,
    minHeight: 990,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    zIndex: -10,
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
    root: {
      backgroundColor: "#ffffff",
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: "center",
      color: theme.palette.text.secondary,
    },
  },
});

export default connect(mapStateToProps, { getUserData, getComments })(
  withStyles(styles)(user)
);
