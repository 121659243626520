import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import ToolImage from "../components/tool/ToolImage";
import StaticProfile from "../components/profile/StaticProfile";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";

import Navbar from "../components/layout/Navbar";

import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/core/styles/withStyles";
import { connect } from "react-redux";
import { getUserData } from "../redux/actions/userActions";
import AppBar from '@material-ui/core/AppBar';
import TabPanel from './TabPanel';
import Account from "../components/setting/Account"
import Security from "../components/setting/Security"
import PaymentForm from "../components/setting/PaymentForm"



import axios from "axios";


import ShoppingCartOutlinedIcon from "@material-ui/icons/ShoppingCartOutlined";
import DeleteForeverOutlinedIcon from "@material-ui/icons/DeleteForeverOutlined";

import formatDistance from "date-fns/formatDistance";
import differenceInDays from "date-fns/differenceInDays";

import MyTab from "../components/setting/MyTab"
import VerticalTabs from "../components/setting/VerticalTabs"

import {
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  ExpansionPanel,
} from "@material-ui/core";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";


class Settings extends Component {
  state = {
    profile: null,
    toolIdParam: null,
    toolsForRevision:[],
    value: 0,
    customer:{},
    retreive: true,
    cardName:""
  };

  getToolsForRevision = () => {
    return axios.post("/revisions", {  });
  };

  setRevision = (user, product, payment, revision) => {
    return axios.post("/revision", { user, product, payment, revision });
  }

  updatePayment = (user, payment) => {
    return axios.post("/updatePayment", { user, payment });
  }

  getCustomer = (customer) => {
    return axios.post("/retreiveCustomer", { id: customer });
  };

  componentWillMount() {
    // const handle = this.props.match.params.handle;
    // const toolId = this.props.match.params.toolId;

    // if (toolId) this.setState({ toolIdParam: toolId });

    //this.props.getUserData();
    // this.props.getCommentsOfUser(handle);


    this.getToolsForRevision().then((data) =>
      this.setState({ toolsForRevision: [...data.data] })
    );

  }

  componentWillReceiveProps = (props) => {
    // if (nextProps.customer) {
    //   this.setState({ cardName: nextProps.customer });
    // }
  }

  handleChange = (event, newValue) => {
    this.setState({ value: newValue });
  };

  retrieveRevisions= (  ) => {
    this.getToolsForRevision().then((data) =>
      this.setState({ toolsForRevision: [...data.data] })
    );
  }

  handleCardName = (ev) =>{
    this.setState({cardName: ev.target.value})
  }

  handleAprove = (event, user, product, payment  ) => {
    console.log("Payment: ", payment);
    console.log("Product: ", product);
    console.log("User: ", user);

    let revision = "Approved"

    this.setRevision(user, product, payment, revision)
        .then((data) =>
          this.retrieveRevisions()
        );
  };

  handleCancel = (event, user, product, payment  ) => {
    console.log("Payment: ", payment);
    console.log("Product: ", product);
    console.log("User: ", user);

    let revision = "Cancel"

    this.setRevision(user, product, payment, revision)
        .then((data) =>
          this.retrieveRevisions()
        );
  };

  a11yProps = (index) => {
    return {
      id: `wrapped-tab-${index}`,
      'aria-controls': `wrapped-tabpanel-${index}`,
    };
  }

  setData = (data) => {
    this.setState({ customer: data.data })
    this.setState({ cardName: data.data.payment_method.billing_details.name })
    this.setState({retreive: false})
  }

  
  render() {
    const {
      classes,
      user,
    } = this.props;


    if(this.props.user.credentials.customer){
      if(this.state.retreive){
        this.getCustomer(this.props.user.credentials.customer)
          .then((data) =>
          this.setData(data)
          // this.setState({ customer: data.data })
          )
          // .then((data) =>
            // this.setState({ cardName: data.data.payment_method.billing_details.name }),
            // this.setState({retreive: false})
          // )
      }
    }

    return (
      <Fragment>
        <Navbar />
        <div className={classes.rootDesktop} style={{paddingTop: "50px"}}>
          <VerticalTabs
            orientation="vertical"
            // variant="scrollable"
            value={this.state.value}
            onChange={this.handleChange}
            aria-label="Vertical tabs example"
            //  className={classes.tabs}
          >
            <MyTab  label="Account Details" {...this.a11yProps(0)} />
            <MyTab label="Login and Security" {...this.a11yProps(1)} />
            <MyTab label="Payments and Credit" {...this.a11yProps(2)} />
          </VerticalTabs>
          <TabPanel value={this.state.value} index={0}>
            <div style={{marginLeft:"12vw"}}>
              <Account user={user}></Account>
            </div>
          </TabPanel>
          <TabPanel value={this.state.value} index={1}>
            <div style={{marginLeft:"12vw"}}>
              <Security user={user}></Security>
            </div>
          </TabPanel>
          <TabPanel value={this.state.value} index={2}>
            <div style={{marginLeft:"12vw"}}>
              {<PaymentForm 
                  user={user} 
                  customer_obj={this.state.customer} 
                  cardName={this.state.cardName}
                  handleCardName={this.handleCardName}>

              </PaymentForm>}
            </div>
          </TabPanel>
        </div>
        <div className={classes.rootMobile}>
          <Grid container spacing={2} style={{width:"100%"}}>
              <Grid item md={12} sm={12} xs={12} style={{width:"100%"}}>
                <ExpansionPanel name="panel1" elevation={0} style={{width:"100%"}}>
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    style={{width:"100%"}}
                    >
                      <Typography
                          color="inherit"
                          align="left"
                          variant="h4"
                          marked="left"
                          className={classes.h5}
                        >
                        Account
                      </Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      <div style={{width:"100%"}}>
                        <Account user={user}></Account>
                      </div>
                    </ExpansionPanelDetails>
                    </ExpansionPanel>
              </Grid>
              <Grid item md={12} sm={12} xs={12} style={{width:"100%"}}>
                <ExpansionPanel name="panel1" elevation={0}>
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    >
                      <Typography
                        color="inherit"
                        align="left"
                        variant="h4"
                        marked="left"
                        className={classes.h5}
                      >
                          Login and Security
                      </Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                    <div style={{width:"100%"}}>
                      <Security user={user}></Security>
                    </div>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                </Grid>
                <Grid item md={12} sm={12} xs={12} style={{width:"100%"}}>
                  <ExpansionPanel name="panel1" elevation={0}>
                    <ExpansionPanelSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      >
                        <Typography
                          color="inherit"
                          align="left"
                          variant="h4"
                          marked="left"
                          className={classes.h5}
                        >
                      Payments and Credit
                    </Typography>
                  </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      <div style={{width:"100%"}}>
                        {<PaymentForm 
                            user={user} 
                            customer_obj={this.state.customer} 
                            cardName={this.state.cardName}
                            handleCardName={this.handleCardName}>

                        </PaymentForm>}
                      </div>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                </Grid>
          </Grid>
        </div>

      </Fragment>
    );
  }
}

Settings.propTypes = {
  user: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  commentsOfUser: PropTypes.object.isRequired,
  getUserData: PropTypes.func.isRequired,
  // getCommentsOfUser: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  data: state.data,
  user: state.user,
  commentsOfUser: state.user.commentsOfUser,
});

const styles = (theme) => ({
  grow: {
    flexGrow: 1,
  },
  rootDesktop: {
    flexGrow: 1,
    // backgroundColor: "red",
    display: 'flex',
    // height: 224,
    [theme.breakpoints.down("sm")]: {      
      display: "none",
    },
  },
  rootMobile:{
    display: "none",
    [theme.breakpoints.down("sm")]: {      
      display: "flex",
    },
  },
  tabs: {
    borderRight: `1px solid gray`,
  },
  heading: {
    display: "flex",
    marginBottom: theme.spacing(0),
    padding: theme.spacing(0),
    fontFamily: "Open Sans",
    fontSize: "20px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.2,
    letterSpacing: "0.15px",
    color: "rgba(0, 0, 0)",
    alignItems: "center",
    justifyContent: "left",
    textAlign: "left",
  },
  card: {
    display: "flex",
    marginLeft: "auto",
    marginRight: "auto",
    maxWidth: "375px",
    marginTop: theme.spacing(10),
    // paddingRight: theme.spacing(2),
    // paddingTop: theme.spacing(0.6),
    // paddingBottom: theme.spacing(0.6),
  },
  cardcontent: {
    //margin: '4px',
    padding: "30px",
    "&:last-child": {
      paddingBottom: "30px",
    },
  },
  button: {
    borderRadius: 5,
    height: "40px",
    fontSize: 14,
    margin: theme.spacing(0),
  },
  title: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  main: { display: "flex" },
  c: { position: "absolute", right: 170 },
  h5: {
    display: "flex",
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(2),
    padding: theme.spacing(0),
    fontFamily: "Open Sans",
    fontSize: "24px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1,
    letterSpacing: "0.18px",
    color: "rgba(0, 0, 0, 0.87)",
    alignItems: "center",
    justifyContent: "left",
    textAlign: "left",
  },
  more: {
    display: "flex",
    fontFamily: "Open Sans",
    fontSize: "12px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: " normal",
    lineHeight: "1.33",
    letterSpacing: "0.4px",
    color: "#000000",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
  },
  background: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 62,
    bottom: 0,
    minHeight: 990,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    zIndex: -10,
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: "center",
      color: theme.palette.text.secondary,
    },
  },
});

export default connect(mapStateToProps, { getUserData })(
  withStyles(styles)(Settings)
);
