import React, { Component } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import PlaceSearch from "../../components/search/PlaceSearch";

// MUI Stuff
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import AttachMoneyOutlinedIcon from "@material-ui/icons/AttachMoneyOutlined";

import Autocomplete from "@material-ui/lab/Autocomplete";

import {
  FormControlLabel,
  Switch,
  InputAdornment,
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
} from "@material-ui/core";

// Redux stuff
import { connect } from "react-redux";
import {
  postTool,
  uploadImage,
  getProductNameList,
  getCategoryList,
  getRatingList,
  getBrandList,
} from "../../redux/actions/dataActions";

import MultipleImageUploadComponent from "./multiple-image-upload.component";

class AddTool extends Component {
  constructor() {
    super();
    this.state = {
      email: "",
      password: "",
      confirmPassword: "",
      handle: "",
      lastName: "",
      name: "",
      errors: {},
      open: false,
      toolImageList: [],
      productNameList: [],
      categoryList: [],
      delivery: true,
      value: "",
    };
  }

  componentDidMount() {
    this.props.getCategoryList();
    this.props.getProductNameList();
    this.props.getRatingList();
    this.props.getBrandList();
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.UI.errors) {
      this.setState({ errors: nextProps.UI.errors });
    }
    if (nextProps.open) {
      this.setState({ open: nextProps.open });
    }
    if (nextProps.data.toolImageList) {
      this.setState({ toolImageList: nextProps.data.toolImageList });
    }
  }

  handleClickOpen = (event) => {
    event.preventDefault();
    this.setState({
      open: true,
    });
  };

  setValue = (value) => {
    this.setState({
      address: value,
    });
  };

  handleClose = (event) => {
    event.preventDefault();
    this.props.close();
  };
  handleSubmit = (event) => {
    event.preventDefault();
    const toolData = {
      name: this.state.name,
      description: this.state.description,
      price: this.state.dailyRate,
      category: this.state.category,
      delivery: this.state.delivery,
      address: this.state.address,
      state: this.state.state,
      zip: this.state.zip,
      toolImageList: this.state.toolImageList,
      active: true,
      noc: 0,
      rating: 0,
    };
    this.props.postTool(toolData);
    this.props.close();
  };

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  setName = (value) => {
    if (value) {
      this.setState({ value: value });
      this.setState({ name: value.name });
    }
  };
  setCategory = (value) => {
    this.setState({ category: value.title });
  };

  setAddress = (value) => {
    this.setState({ address: value });
  };

  handleFreeDeliveryClick = () => {
    let deliveryStatus = !this.state.delivery;
    this.setState({
      delivery: deliveryStatus,
    });
  };

  render() {
    const {
      classes,
      values,
      productNameList,
      categoryList,
    } = this.props;

    const { errors, value, delivery } = this.state;

    return (
      <Dialog
        open={values.open}
        onClose={this.handleClose}
        aria-labelledby="form-dialog-title"
        className={classes.card}
        elevation={0}
        style={{ zIndex: "100" }}
      >
        <DialogTitle id="form-dialog-title">
          <Typography
            color="inherit"
            align="left"
            variant="h4"
            marked="left"
            className={classes.h5}
          >
            Add Tool
          </Typography>
        </DialogTitle>
        <DialogContent>
          <div className={classes.root}>
            <Grid className={classes.root} container spacing={5}>
              <Grid item sm={12}>
                <Grid container spacing={5}>
                  <Grid item sm={12}>
                    <MultipleImageUploadComponent />
                  </Grid>
                  <Grid item sm={12}>
                    <form noValidate onSubmit={this.handleSubmit} spacing={5}>
                      <Grid container spacing={2}>
                        <Grid item sm={12}>
                          <Autocomplete
                            value={value}
                            onChange={(event, newValue) => {
                              this.setName(newValue);
                            }}
                            // inputValue={inputValue}
                            // onInputChange={(event, newInputValue) => {
                            //   this.setInputValue(newInputValue);
                            // }}
                            id="product-name"
                            options={productNameList}
                            className={classes.textField}
                            helperText={errors.name}
                            error={errors.name ? true : false}
                            getOptionSelected={(option, value) =>
                              option.name === value.name
                            }
                            getOptionLabel={(option) => option.name}
                            style={{ width: 300 }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Product name"
                                variant="outlined"
                              />
                            )}
                          />
                        </Grid>
                        <Grid item sm={12}>
                          <TextField
                            id="description"
                            name="description"
                            type="text"
                            label="Description"
                            multiline
                            rows={2}
                            rowsMax={2}
                            className={classes.textField}
                            helperText={errors.description}
                            error={errors.description ? true : false}
                            value={this.state.description}
                            onChange={this.handleChange}
                            size="small"
                            variant="outlined"
                            fullWidth
                          />
                        </Grid>
                        <Grid item sm={12}>
                          <Autocomplete
                            id="asynchronous-category"
                            style={{ width: 300 }}
                            onChange={(event, newValue) => {
                              this.setCategory(newValue);
                            }}
                            getOptionSelected={(option, value) =>
                              option.title === value.title
                            }
                            getOptionLabel={(option) => option.title}
                            options={categoryList}
                            // loading={loading}
                            renderInput={(paramsCat) => (
                              <TextField
                                {...paramsCat}
                                variant="outlined"
                                placeholder="Category"
                              />
                            )}
                          />
                        </Grid>
                        <Grid item sm={7}>
                          <TextField
                            id="dailyRate"
                            name="dailyRate"
                            type="number"
                            label="Daily Rate"
                            className={classes.textField}
                            helperText={errors.dailyRate}
                            error={errors.dailyRate ? true : false}
                            value={this.state.dailyRate}
                            onChange={this.handleChange}
                            size="small"
                            variant="outlined"
                            fullWidth
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <AttachMoneyOutlinedIcon />
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>
                        <Grid item sm={6}>
                          <FormControlLabel
                            className={classes.content}
                            control={
                              <Switch
                                checked={delivery}
                                onChange={this.handleFreeDeliveryClick}
                                name="checkedB"
                                color={delivery ? "secondary" : "inherit"}
                              />
                            }
                            label="Delivery"
                          />
                        </Grid>
                        <Grid item sm={12}>
                          <PlaceSearch
                            setAddress={this.setAddress}
                          ></PlaceSearch>
                        </Grid>
                      </Grid>
                    </form>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={this.handleClose}
            color="secondary"
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={this.handleSubmit}
            color="secondary"
          >
            Create
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

AddTool.propTypes = {
  classes: PropTypes.object.isRequired,
  UI: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  uploadImage: PropTypes.func.isRequired,
  toolImageList: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  UI: state.UI,
  data: state.data,
  toolImageList: state.data.toolImageList,
  getRatingList: PropTypes.func.isRequired,
  getBrandList: PropTypes.func.isRequired,
  getProductNameList: PropTypes.func.isRequired,
  getCategoryList: PropTypes.func.isRequired,
  categoryList: state.data.categoryList,
  ratingList: state.data.ratingList,
  brandList: state.data.brandList,
  productNameList: state.data.productNameList,
});

const mapActionsToProps = {
  uploadImage,
  postTool,
  getCategoryList,
  getProductNameList,
  getRatingList,
  getBrandList,
};

const styles = (theme) => ({
  card: {
    display: "flex",
    marginLeft: "auto",
    marginRight: "auto",
    maxWidth: "400px",
    marginTop: theme.spacing(0),
  },
  h5: {
    display: "flex",
    marginBottom: theme.spacing(0),
    padding: theme.spacing(0),
    fontFamily: "Open Sans",
    fontSize: "24px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1,
    letterSpacing: "0.18px",
    color: "rgba(0, 0, 0, 0.87)",
    alignItems: "left",
    justifyContent: "left",
    textAlign: "left",
  },
  content: {
    display: "flex",
    marginBottom: theme.spacing(0),
    padding: theme.spacing(0),
    fontFamily: "Open Sans",
    fontSize: "14px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1,
    letterSpacing: "0.18px",
    color: "rgba(0, 0, 0, 0.87)",
    alignItems: "left",
    justifyContent: "left",
    textAlign: "left",
  },
});

export default connect(
  mapStateToProps,
  mapActionsToProps
)(withStyles(styles)(AddTool));
