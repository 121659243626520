import Tabs from "@material-ui/core/Tabs";
import { withStyles } from "@material-ui/core/styles";

const VerticalTabs = withStyles(theme => ({
  flexContainer: {
    flexDirection: "column"
  },
  indicator: {
    display: "none"
  },
  tabsRoot: {
    textAlign: "right"
  }
}))(Tabs);
export default VerticalTabs;