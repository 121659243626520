import axios from "axios";

export const getPostId = postId => {
  return axios.get(`/post/${postId}`);
};
export const addNewPost = post => {
  return axios.post("/post", post);
};
export const getAllPosts = (criteria) => {
  return axios.post("/allPosts", { criteria });
};
export const getPosts = (type) => {
  return axios.post("/posts", { type });
};
