export default {
  palette: {
    primary: {
      light: '#33c9dc',
      main: '#fff',
      dark: '#008394',
      contrastText: '#000000'
    },
    secondary: {
      light: '#ff6333',
      main: '#f4722d',
      dark: '#b22a00',
      contrastText: '#fff'
    }
  },
  fontFamily: 'Open Sans',


  typography: {
    useNextVariants: true
  },

  h5: {
    display: "flex",
    marginBottom: 1,
    marginLeft: 1,
    fontFamily: "Open Sans",
    fontSize: "24px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1,
    letterSpacing: "0.18px",
    color: "rgba(0, 0, 0, 0.87)",
    alignItems: "center",
    justifyContent: "left",
    textAlign: "left",
  },
  form: {
    textAlign: 'center'
  },
  image: {
    margin: '20px auto 20px auto'
  },
  pageTitle: {
    margin: '10px auto 10px auto',
    textAlign: 'center',
    position: 'relative',
  },
  textField: {
    margin: '10px auto 10px auto'
  },
  button: {
    marginTop: 20,
    position: 'relative'
  },
  customError: {
    color: 'red',
    fontSize: '0.8rem',
    marginTop: 10
  },
  progress: {
    position: 'absolute'
  },
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: 2,
    textAlign: 'center'
  },
  invisibleSeparator: {
    border: 'none',
    margin: 4
  },
  visibleSeparator: {
    width: '100%',
    borderBottom: '1px solid rgba(0,0,0,0.1)',
    marginBottom: 20
  },
  overrides: {
    MuiInput: {
      underline: {
        '&:before': {
          // The MUI source seems to use this but it doesn't work
          borderBottom: '1px solid white',
        },
        '&:hover': {
          // The MUI source seems to use this but it doesn't work
          borderBottom: '1px solid white',
        },
        '&:cssFocused': {
          // The MUI source seems to use this but it doesn't work
          borderBottom: '1px solid white',
        },
        '&:hover:not($disabled):after': {
          borderBottom: '1px solid white',
        },
        '&:hover:not($disabled):before': {
          borderBottom: '1px solid white',          // String should be terminated
        },
        '&:notchedOutline': {
          // The MUI source seems to use this but it doesn't work
          borderBottom: '1px solid white',
        },
      }
    },
    MuiChip: {
      root: {
        maxHeight: "22px",
      }},

    MuiOutlinedInput: {
      input:{
        padding: "12px 2px 12px"
      },
      root: {
          backgroundColor: "rgba(0, 0, 0, 0.08)",
          position: 'relative',
          borderRadius:'3px',
          '& $notchedOutline': {
              borderColor: 'rgba(0, 0, 0, 0.23)',
              borderWidth: 0,
          },
          '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
              borderColor: '#4A90E2',
              borderWidth: 0,
              // Reset on touch devices, it doesn't add specificity
              '@media (hover: none)': {
                  borderColor: 'rgba(0, 0, 0, 0.23)',
                  borderWidth: 0,
              },
          },
          '&$focused $notchedOutline': {
              borderColor: '#4A90E2',
              borderWidth: 0,
          },
      },
  },
  
  MuiFormLabel: {
      root: {
          '&$focused': {
              color: '#4A90E2'
          }
      }
  },
    MuiInputLabel: {
      root: {
        color: "gray",
        "&$focused": {
          color: "gray"
        }
      }
    }
  },
  profile: {
    '& .image-wrapper': {
      textAlign: 'center',
      position: 'relative',
      '& button': {
        position: 'absolute',
        top: '80%',
        left: '70%'
      }
    },
    '& .profile-image': {
      width: 200,
      height: 200,
      objectFit: 'cover',
      maxWidth: '100%',
      borderRadius: '50%'
    },
    '& .profile-details': {
      textAlign: 'center',
      '& span, svg': {
        verticalAlign: 'middle'
      },
      '& a': {
        color: '#00bcd4'
      }
    },
    '& hr': {
      border: 'none',
      margin: '0 0 10px 0'
    },
    '& svg.button': {
      '&:hover': {
        cursor: 'pointer'
      }
    }
  },
  buttons: {
    textAlign: 'center',
    '& a': {
      margin: '20px 10px'
    }
  },
    myComponent: {
      "& .MuiIconButton-root": {
        padding: 0
      }
    },
    MuiExpansionPanelSummary:{
      expanded: {
        minHeight: '36px'
      },
    },

    mySummary: {
      "& .MuiExpansionPanelSummary-root": {
        "& .Mui-expanded":{
          minHeight: '36px'
        } 
      }
    },
};
