import AppBar from "@material-ui/core/AppBar";
import IconButton from "@material-ui/core/IconButton";
import { withStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";

import { connect } from "react-redux";
import PropTypes from "prop-types";

import { Link } from "react-router-dom";

import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import FacebookIcon from "@material-ui/icons/Facebook";
import TwitterIcon from "@material-ui/icons/Twitter";
import InstagramIcon from "@material-ui/icons/Instagram";

import React, { Component } from "react";
import { logoutUser, getUserData } from "../../redux/actions/userActions";
import { productsCart } from "../../redux/actions/dataActions";


import Brand from "../shared/Brand";
import ShoppingCart from "./ShoppingCart"

class FooterBar extends Component {

    // Set some state
    state = {
      setAnchorEl: "",
      mobileMoreAnchorEl: "",
      setMobileMoreAnchorEl: "",
      isMenuOpen: false,
      isMobileMenuOpen: false,
      open: false,
      auth: true,
      user: {
        authenticated: false,
        userhandle: "",
      },
      anchorEl: null,
      openMenu: true,
    };

  componentWillMount() {
    //console.log( this.props);

    //this.props.getUserData();

    //this.props.productsCart("Ariel");
  }

  componentWillReceiveProps(nextProps) {
    console.log( nextProps);

    if (nextProps.user) {
      this.setState({ user: nextProps.user });
    }
  }

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleCloseMenu = () => {
    this.setState({ anchorEl: null });
  };

  handleLogout = () => {
    this.props.logoutUser();
    this.setState({ anchorEl: null });
    window.location.href = "/login";
  };

  openNewTool = (event) => {
    event.preventDefault();
    this.setState({
      open: true,
    });
  };

  handleClose = () => {
    this.setState({
      open: false,
    });
  };

  render() {
    const { classes, handle, isTransparent } = this.props;

    const { open, anchorEl, user } = this.state;

    console.log(user)
    console.log("Is Transparent", isTransparent)

    let backgroundColor = isTransparent ? "transparent" : "secondary";

    let color =  classes.WButton;


    let MenuItemSession = !user.authenticated ? (
      <MenuItem onClick={this.handleLogout}>Login</MenuItem>
    ) : (
      <MenuItem onClick={this.handleLogout}>Logout</MenuItem>
    );

    return (
      <div style={{padding:"0px"}}>
        <AppBar style={{padding:"0px"}} color={backgroundColor} elevation={0} position="relative">
          <Toolbar className={classes.toolBar} >
            <div className={classes.container} >
            <div className={classes.brand}>
              <Brand isTransparent= {true}/>
              </div>

              <div className={classes.grow} />
                <Link
                  className={`${color} ${classes.marginR}`}
                  tip="HOME"
                  to="/"
                  color="inherit"
                >
                  Home
                </Link>
                <Link
                  className={`${color} ${classes.marginR}`}
                  tip="SEARCH"
                  to="/search"
                  color="inherit"
                >
                  Search
                </Link>

                {/* <PostTool /> */}

                <Link
                  className={`${color} ${classes.marginR}`}
                  tip="Login"
                  to="/login"
                  color="inherit"
                >
                  Join For Free
                </Link>

                <Link
                  className={`${color} ${classes.marginR}`}
                  tip="Community"
                  to="/blog"
                  color="inherit"
                >
                  Community
                </Link>

                <Link
                  className={`${color} ${classes.marginR}`}
                  tip="Help"
                  to="/faq"
                  color="inherit"
                >
                  Help
                </Link>



                <div className={classes.grow} />

                <div className={classes.social}>

                <IconButton
                  color="inherit"
                  component={Link}
                  to="/messages"
                  aria-label="upload picture"
                  className={color}
                >
                  <TwitterIcon />
                </IconButton>
                <IconButton
                  color="inherit"
                  component={Link}
                  to="/messages"
                  aria-label="upload picture"
                  className={color}
                >
                  <FacebookIcon />
                </IconButton>
                <IconButton
                  color="inherit"
                  component={Link}
                  to="/messages"
                  aria-label="upload picture"
                  className={color}
                >
                  <InstagramIcon />
                </IconButton>
                </div>
                </div>

          </Toolbar>
        </AppBar>
      </div>
    );
  }
}

const styles = (theme) => ({
  grow: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",

    [theme.breakpoints.down("sm")]: {
      flexDirection: "row",
      flexGrow: 0,
      display: "flex",

    },
  },
  menu: {
    top: "80px",
  },
  toolBar: {
    minHeight: 62,
    //backgroundColor: "transparent",
    paddingLeft:150,
    paddingRight:150,

    [theme.breakpoints.down("sm")]: {
      // paddingLeft:80,
      // paddingRight:80,
      display:"contents"
    },
  },
  container:{

    alignItems: "center",
    [theme.breakpoints.up("sm")]: {
      flexGrow: 1, 
      display:"flex"
    },
    [theme.breakpoints.down("sm")]: {
      // padding:"0px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
  },
  brand:{
    [theme.breakpoints.down("sm")]: {
      marginTop:"20px",
      paddingLeft:"2px", 
      marginBottom:"12px"
    }
  },
  social:{
    display:"flex", 
    [theme.breakpoints.down("sm")]: {
      marginTop:"28px"
    }

  },
  marginR: {
    marginRight: 22,
    [theme.breakpoints.down("sm")]: {
      marginRight: 0,
      marginTop:"20px"
    },
  },
  marginL: {
    marginLeft: 22,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
    },
  },
  WButton: {
    fontFamily: "Open Sans",
    fontSize: 14,
    color: "white",
    fontWeight: 600,
    fontStretch: "normal",
    fontStyle: "normal",
    letterSpacing: "1.25px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textTransform: "uppercase",
  },
  BButton: {
    fontFamily: "Open Sans",
    fontSize: 14,
    color: "black",
    fontWeight: 600,
    fontStretch: "normal",
    fontStyle: "normal",
    letterSpacing: "1.25px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textTransform: "uppercase",
  },
  sectionDesktop: {
    display: "none",
    spacing: 10,
    [theme.breakpoints.up("sm")]: {
      display: "flex",
    },
  },
});

const mapStateToProps = (state) => ({
  user: state.user,
  cartList: state.data.cartList
});

FooterBar.propTypes = {
  classes: PropTypes.object.isRequired,
  getUserData: PropTypes.func.isRequired,
  productsCart: PropTypes.func.isRequired,
  logoutUser: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired
};

export default connect(mapStateToProps, { logoutUser, getUserData, productsCart })(
  withStyles(styles)(FooterBar)
);