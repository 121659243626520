import i18n from 'i18next'
import {initReactI18next} from 'react-i18next'

import LanguageDetector from 'i18next-browser-languagedetector'
// import { es } from 'date-fns/locale'

i18n.use(LanguageDetector)
    .use(initReactI18next).init({
    fallbackLng: 'en',
    resources: {
        es: {
            translation:{
                login:{
                    "title":"Sign in to your account",
                    "email":"Email",
                    "password":"Password",
                    "signIn":"Sign In",
                    "titleCreateAccount":"Don’t have an account?",
                    "createAccount":"Create account",
                    "addYourTools":"Add your tools",
                    "rentTools":"Rent tools",
                    "makingMoney":"Start making money"
                },
                navBar:{
                    "login": "Login",
                    "logout": "Logout",
                    "messages":"Messages",
                    "profile":"Profile",
                    "home":"Home",
                    "search":"Search",
                    "joinForFree":"Join for free",
                    "community":"Community",
                    "help":"Help",
                    "dispute":"Dispute",
                    "settings":"Settings"
                }
            }
        },
        en: {
            translation:{
                login:{
                    "title":"Sign in to your account",
                    "email":"Email",
                    "password":"Password",
                    "signIn":"Sign In",
                    "titleCreateAccount":"Don’t have an account?",
                    "createAccount":"Create account",
                    "addYourTools":"Add your tools",
                    "rentTools":"Rent tools",
                    "makingMoney":"Start making money"
                },
                navBar:{
                    "login": "Login",
                    "logout": "Logout",
                    "messages":"Messages",
                    "profile":"Profile",
                    "home":"Home",
                    "search":"Search",
                    "joinForFree":"Join for free",
                    "community":"Community",
                    "help":"Help",
                    "dispute":"Dispute",
                    "settings":"Settings"
                }
            }
        }
    }
})