import {
  SET_TOOL_LIST,
  SET_CATEGORY_LIST,
  SET_PRODUCTS_NAME_LIST,
  SET_BRAND_LIST,
  SET_RATING_LIST,
  SET_TOOLS_USER,
  LOADING_DATA,
  LIKE_TOOL,
  UNLIKE_TOOL,
  DELETE_TOOL,
  SET_ERRORS,
  POST_TOOL,
  CLEAR_ERRORS,
  LOADING_UI,
  SET_TOOL,
  STOP_LOADING_UI,
  SUBMIT_COMMENT,
  UPDATE_DATA,
  UPDATED_TOOL,
  SET_NOTIFICATIONS,
  DELETE_NOTIFICATION,
  ADD_PRODUCT_TO_CART,
  SET_PRODUCTS_TO_CART,
  DELETE_PRODUCT_FROM_CART,
  SET_PAYMENT
} from "../types";
import axios from "axios";

export const stripeTokenHandler= (token) => (dispatch) => {
  const paymentData = {token: token.id};

  // Use fetch to send the token ID and any other payment data to your server.
  // https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch
  axios
    .post("/charge", { paymentData })
    .then((res) => {
      dispatch({
        type: SET_PAYMENT,
        payload: res.data,
      });
    })
};

// Get tools
export const getProductList = (product, zip, startDate, endDate) => (dispatch) => {
  dispatch({ type: LOADING_DATA });
  if(product !== null && zip !== null){
    axios
      .post("/tools", { product, zip, startDate, endDate })
      .then((res) => {
        dispatch({
          type: SET_TOOL_LIST,
          payload: res.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: SET_ERRORS,
          payload: [],
        });
      });
  }
  else if(product === null && zip === null){
    dispatch({ type: LOADING_DATA });
    axios
      .post("/allTools", { startDate, endDate })
      .then((res) => {
        dispatch({
          type: SET_TOOL_LIST,
          payload: res.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: SET_ERRORS,
          payload: [],
        });
      });
  }
  else if(product !== null && zip === null){
    axios
    .post("/toolsByName", { product, startDate, endDate })
    .then((res) => {
      dispatch({
        type: SET_TOOL_LIST,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: SET_ERRORS,
        payload: [],
      });
    });
  }
  else if(product === null && zip !== null){
    axios
    .post("/toolsByZip", { zip, startDate, endDate })
    .then((res) => {
      dispatch({
        type: SET_TOOL_LIST,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: SET_ERRORS,
        payload: [],
      });
    });
  }
};


// Get tools
export const getAllProducts = () => (dispatch) => {
  dispatch({ type: LOADING_DATA });
  axios
    .post("/allTools")
    .then((res) => {
      dispatch({
        type: SET_TOOL_LIST,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: SET_ERRORS,
        payload: [],
      });
    });
};

export const getProductNameList = () => dispatch => {
  axios.get("/products").then(res => {
    dispatch({
      type: SET_PRODUCTS_NAME_LIST,
      payload: res.data
    });
  });
}

export const getCategoryList = () => dispatch => {
  axios.get("/categories").then(res => {
    dispatch({
      type: SET_CATEGORY_LIST,
      payload: res.data
    });
  });
};

export const getRatingList = () => dispatch => {
  axios.get("/ratings").then(res => {
    dispatch({
      type: SET_RATING_LIST,
      payload: res.data
    });
  });
};

export const getBrandList = () => dispatch => {
  axios.get("/brands").then(res => {
    dispatch({
      type: SET_BRAND_LIST,
      payload: res.data
    });
  });
};

export const addProductToCart = (productData) => dispatch => {
  axios.post("/addToCart", { productData }).then(res => {
    dispatch({
      type: ADD_PRODUCT_TO_CART,
      payload: res.data
    });
    dispatch(productsCart())
  });
};

export const deleteProductFromCart = (productId) => dispatch => {
  axios
    .delete(`/cart/${productId}`)
    .then(res => {
      dispatch({
        type: DELETE_PRODUCT_FROM_CART,
        payload: res.data
      });
      dispatch(productsCart())
    });
};

export const productsCart = () => dispatch => {
  axios.get(`/cart`).then(res => {
    dispatch({
      type: SET_PRODUCTS_TO_CART,
      payload: res.data
    });
  });
};

export const getNotification = () => dispatch => {
  console.log("Get notifications")
  axios.get(`/notifications`).then(res => {
    dispatch({
      type: SET_NOTIFICATIONS,
      payload: res.data
    });
  });
};

export const deleteNotification = (notificationId) => dispatch => {
  axios
    .delete(`/notifications/${notificationId}`)
    .then(res => {
      dispatch({
        type: DELETE_NOTIFICATION,
        payload: res.data
      });
      dispatch(getNotification())
    });
};
// export const deleteAllNotification

export const clearNotifications = () => dispatch => {
  console.log("LLamada a Redux")
  axios
    .post(`/notifications/clearNotifications`)
    .then(res => {
      dispatch({
        type: DELETE_NOTIFICATION,
        payload: res.data
      });
      dispatch(getNotification())
    });
};

export const getTool = (toolId) => (dispatch) => {
  dispatch({ type: LOADING_DATA });
  axios
    .get(`/tool/${toolId}`)
    .then((res) => {
      dispatch({
        type: SET_TOOL,
        payload: res.data,
      });
      //dispatch(getCommentsOfUser(res.data.userHandle));
    })
    .catch((err) => {
      dispatch({
        type: SET_ERRORS,
        payload: [],
      });
    });
};

export const getToolsOfUser = (userHandle) => (dispatch) => {
  dispatch({ type: LOADING_DATA });
  axios
    .get(`/user/${userHandle}`)
    .then((res) => {
      dispatch({
        type: SET_TOOLS_USER,
        payload: res.data.tools,
      });
    })
    .catch(() => {
      dispatch({
        type: SET_TOOLS_USER,
        payload: null,
      });
    });
};
// Post a tool
export const postTool = (newTool) => (dispatch) => {
  dispatch({ type: LOADING_UI });
  axios
    .post("/tool", newTool)
    .then((res) => {
      dispatch({
        type: POST_TOOL,
        payload: res.data,
      });
      dispatch(clearErrors()/*, (window.location.href = "/")*/);
    })
    .catch((err) => {
      dispatch({
        type: SET_ERRORS,
        payload: err.data,
      });
    });
};

// create a Booking
export const createBooking = (newBooking) => (dispatch) => {
  dispatch({ type: LOADING_UI });
  axios
    .post("/createBooking", newBooking)
    .then((res) => {
      dispatch({
        type: POST_TOOL,
        payload: res.data,
      });
      dispatch(clearErrors(), (window.location.href = "/"));
    })
    .catch((err) => {
      dispatch({
        type: SET_ERRORS,
        payload: err.data,
      });
    });
};

// Upadte a tool
export const updateTool = (updateTool) => (dispatch) => {
  dispatch({ type: UPDATE_DATA });
  axios
    .post("/tool/updateTool", updateTool)
    .then((res) => {
      dispatch({
        type: UPDATED_TOOL,
        payload: res.data,
      });
      dispatch(clearErrors());
    })
    .catch((err) => {
      dispatch({
        type: SET_ERRORS,
        payload: err.data,
      });
    });
};
export const uploadImage = (formData, toolId) => (dispatch) => {
  dispatch({ type: LOADING_UI });
  axios
    .post("/tool/images", formData, { params: { toolId: toolId } })
    .then((res) => {
      dispatch({
        type: UPDATED_TOOL,
        payload: res.data,
      });
      dispatch(clearErrors());
    })
    .catch((err) => {
      dispatch({
        type: SET_ERRORS,
        payload: err.data,
      });
    });
};

// Like a tool
export const likeTool = (toolId) => (dispatch) => {
  axios
    .get(`/tool/${toolId}/like`)
    .then((res) => {
      dispatch({
        type: LIKE_TOOL,
        payload: res.data,
      });
    })
    .catch((err) => console.log(err));
};
// Unlike a tool
export const unlikeTool = (toolId) => (dispatch) => {
  axios
    .get(`/tool/${toolId}/unlike`)
    .then((res) => {
      dispatch({
        type: UNLIKE_TOOL,
        payload: res.data,
      });
    })
    .catch((err) => console.log(err));
};
// Submit a comment
export const submitComment = (toolId, commentData) => (dispatch) => {
  axios
    .post(`/tool/${toolId}/comment`, commentData)
    .then((res) => {
      dispatch({
        type: SUBMIT_COMMENT,
        payload: res.data,
      });
      dispatch(clearErrors());
    })
    .catch((err) => {
      dispatch({
        type: SET_ERRORS,
        payload: err.response.data,
      });
    });
};
export const deleteTool = (toolId) => (dispatch) => {
  axios
    .delete(`/tool/${toolId}`)
    .then(() => {
      dispatch({ type: DELETE_TOOL, payload: toolId });
    })
    .catch((err) => console.log(err));
};

export const clearErrors = () => (dispatch) => {
  dispatch({ type: CLEAR_ERRORS });
};
