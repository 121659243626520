import React, { useMemo } from "react";
import { useState } from "react";

import { useStripe, useElements, CardElement, CardNumberElement, CardCvcElement, CardExpiryElement, CardName } from "@stripe/react-stripe-js";

import axios from "axios";

import CheckoutError from "./CheckoutError";

import PlaceSearch from "../search/PlaceSearch"


// MUI Stuff
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  TextField,
} from "@material-ui/core";

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import IconButton from '@material-ui/core/IconButton';

import EditIcon from '@material-ui/icons/Edit';


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

let retreive = true;
const useOptions = () => {
  const options = useMemo(
    () => ({
      style: {
        root:{
          backgroundColor:"lightgray",
        },
        base: {
          fontSize: "20px",
          color: "#424770",
          letterSpacing: "0.010em",
          fontWeight: "100",
          fontStretch: "normal",
          fontStyle: "normal",
          // backgroundColor:"lightgray",
          // lineHeight: "1.5",
          fontFamily: "Source Code Pro, monospace",
          "::placeholder": {
            color: "#aab7c4"
          }
        },
        invalid: {
          color: "#9e2146"
        }
      }
    }),
  );

  return options;
};


const PaymentForm = ({ price, shipping, onSuccessfulCheckout, user, customer_obj, cardName, handleCardName }) => {
  const stripe = useStripe();
  const elements = useElements();
  const options = useOptions();

  const [isProcessing, setProcessingTo] = useState(false);
  const [checkoutError, setCheckoutError] = useState();
  // const [cardName, setCardName] = useState();
  const [payment, setPayment] = useState();
  const [address, setAddress] = React.useState({});
  const [customer, setCustomer] = React.useState(customer_obj);
  const [initialAddress, setInitialAddress] = React.useState("");
  const [editCard, setEditCard] = React.useState(false)



  const isValidate = (cardName && address.route) ? true : false

  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    // onSuccessfulCheckout(payment);
  };

  const handleEditCard = () => {
    setEditCard(true);
  }

  const handleCardDetailsChange = ev => {
    ev.error ? setCheckoutError(ev.error.message) : setCheckoutError();
  };

  // const handleChange = ev => {
  //   setCardName(ev.target.value)
  // }

  const handleUpdate = async (event) =>{
    event.preventDefault();
    setProcessingTo(true);
    const billingDetails = {
      name: cardName,
      address: {
        city: address.locality,
        line1: address.street_number +" "+ address.route,
        state: address.administrative_area_level_1,
        postal_code: address.postal_code,
        country: address.country
      }
    }

    try {
      let id=customer.confirm.id
      const { data } = await axios.post("/UpdateCustomer", { id, cardName, address });
      handleOpen();
      setProcessingTo(false);
      // elements.getElement(CardElement).clear();
    } catch (error) {
      console.log(error);
      setCheckoutError(error.message);
      setProcessingTo(false);
      return;
    }
  }

  const handleSubmit = async event => {
    event.preventDefault();
    setProcessingTo(true);
    const billingDetails = {
      name: cardName,
      address: {
        city: address.locality,
        line1: address.street_number +" "+ address.route,
        state: address.administrative_area_level_1,
        postal_code: address.postal_code,
        country: address.country
      }
    };

    if(elements.getElement(CardElement)){

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
      billing_details: billingDetails
    });

    if (!error) {
      const { id } = paymentMethod;

      try {
        const { data } = await axios.post("/customer", { id, cardName, address });
        handleOpen();
        setProcessingTo(false);
        // elements.getElement(CardElement).clear();
      } catch (error) {
        console.log(error);
        setCheckoutError(error.message);
        setProcessingTo(false);
        return;
      }
    }
    else{
      setCheckoutError(error.message);
      setProcessingTo(false);
      return;
    }
    }
    else{
      setCheckoutError("Edit card data");
      setProcessingTo(false);
      return;
    }
  };

  return (
    <Grid container spacing={2} style={{maxWidth:"400px"}}>
      <Grid item md={12} sm={12} xs={12}>
        <TextField
          id="n-toowlz"
          name="n-toowlz"
          type="text"
          placeholder="Card Name"
          className="textField"
          // helperText={errors.address}
          // error={errors.address ? true : false}
          value={cardName}
          onChange={handleCardName}
          size="small"
          variant="outlined"
          fullWidth
          autoComplete="nope"
          onFocus={(event) =>
                      event.target.autocomplete = "whatever"
                      }
        />
      </Grid>
      {!editCard && customer.confirm?
      <Grid item md={12} sm={12} xs={12}>
        <div style={{display: "flex", justifyContent: "space-between", width: "100%"}}>
          <div style={{textAlign: "left"}}>
            {customer.payment_method.card.brand.toUpperCase()} *** *** *** {customer.payment_method.card.last4}
          </div>
          <div style={{textAlign: "rigth"}}>
            {customer.payment_method.card.exp_month}/{customer.payment_method.card.exp_year}
          </div>
        <div>
          <IconButton
              style={{ margin: 0, padding: 4, borderRadius: 5 }}
              onClick={() => handleEditCard()}
            >
              <EditIcon
                style={{ fontSize: 22, margin: 0 }}
                // color={view === "list" ? "secondary" : "inherit"}
              />
          </IconButton>
        </div>
        </div>
      </Grid>
      :
      null}
      {editCard || !customer ? 
      <Grid item md={12} sm={12} xs={12}>
      
        <CardElement options={options} className="card-element" />
      
      </Grid>
      :
        null
      }
      <Grid item md={12} sm={12} xs={12}>
        <div style={{maxWidth:"400px"}}>
        <PlaceSearch setAddress={setAddress} initialAddress={customer.payment_method ? customer.payment_method.billing_details.address.line1 : null}></PlaceSearch>
        </div>
      </Grid>
      <Grid item md={12} sm={12} xs={12}>
        {customer.confirm ?
        <Button
          type="submit"
          variant="contained"
          color="secondary"
          fullWidth
          onClick={handleUpdate}
          disabled={!isValidate || isProcessing || !stripe}
        >
          Update
          {isProcessing || !stripe && (
                              <CircularProgress
                                size={30}
                                // className={classes.progress}
                              />
                            )}
        </Button>
        :
        <Button
          type="submit"
          variant="contained"
          color="secondary"
          fullWidth
          onClick={handleSubmit}
          disabled={!isValidate || isProcessing || !stripe}
        >
          Save
          {isProcessing || !stripe && (
                              <CircularProgress
                                size={30}
                                // className={classes.progress}
                              />
                            )}
        </Button>
        }
        <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{"Congrats!"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Perfil guardado con exito
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary" variant="contained">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
      </Grid>
      <Grid item md={12} sm={12} xs={12}>
        {checkoutError && <CheckoutError>{checkoutError}</CheckoutError>}
        </Grid>

    </Grid>
  );
};

export default PaymentForm;
