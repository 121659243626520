import React, { Component } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";

// MUI Stuff
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

import DateFnsUtils from "@date-io/date-fns"; // import

import CheckoutError from "./CheckoutError";

import axios from "axios";



import { compareAsc, format, addDays, formatDistance, formatRelative, subDays } from 'date-fns'

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  TimePicker,
  DatePicker
} from "@material-ui/pickers";

import Input from '@material-ui/core/Input';
import Autocomplete from '@material-ui/lab/Autocomplete';

import NumberFormatCustom from "./TextFormat";

import {
  FormControlLabel,
  Switch,
  InputAdornment,
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";

import DialogContentText from '@material-ui/core/DialogContentText';
import Slide from '@material-ui/core/Slide';

// Redux stuff
import { connect } from "react-redux";

class Account extends Component {
  constructor(props) {
    super();
    this.state = {
      handle: "",
      lastName: props.user.credentials.lastName,
      name:props.user.credentials.firstName,
      errors: {},
      open: false,
      toolImageList: [],
      productNameList: [],
      value:"",
      phone: props.user.credentials.phone,
      numberformat: '',
      email:props.user.credentials.email
    };
  }

  componentDidMount() {

  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.UI.errors) {
      this.setState({ errors: nextProps.UI.errors });
    }
    if (nextProps.open) {
      this.setState({ open: nextProps.open });
    }
    if (nextProps.user.credentials) {
        this.setState({ name: nextProps.user.credentials.firstName,
                        phone: nextProps.user.credentials.phone,
                        lastName: nextProps.user.credentials.lastName,
                        email: nextProps.user.credentials.email,
                        genere: nextProps.user.credentials.genere,
                        date: nextProps.user.credentials.date });
      }
  }

  handleClickOpen = (event) => {
    event.preventDefault();
    this.setState({
      open: true,
    });
  };

  setValue = (value) => {
    this.setState({
      address: value,
    });
  }

  handleClose = (event) => {
    event.preventDefault();
    this.setState({
      open: false,
    });  };
  handleSubmit = async (event) => {
    event.preventDefault();
    const user = {
      name: this.state.name,
      lastName: this.state.lastName,
      genere: this.state.genere,
      date: this.state.date,
      phone: this.state.phone
    };

      try {
        const { data } = await axios.post("/updateUser", { user });
        // this.handleOpen();
        this.setState({"open": true})
      } catch (error) {
        console.log(error);
        this.setState({"error": error.message})
        return;
      }
  };

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleChangeDateFrom = (date) => {
    this.setState({
      date: date,
    });
  };

  setName = (value) => {
    this.setState({value:value});
    this.setState({name: value.name})
  }

  handleFreeDeliveryClick = () => {
    let deliveryStatus = !this.state.delivery;
    this.setState({
      delivery: deliveryStatus,
    });
  }


  render() {
    const {
      classes,
      user,
    } = this.props;

    const { errors, open, error, email } = this.state;
    let isDisabled = email == ""

    return (
          <div className={classes.fullWidth}>
                    <FormControl fullWidth noValidate autoComplete="off" onSubmit={this.handleSubmit}>
                    <Grid container spacing={2}>
                      <Grid item md={12} sm={12} xs={12}>
                          <TextField
                            id="name"
                            name="name"
                            type="text"
                            placeholder="Name"
                            className={classes.textField}
                            helperText={errors.name}
                            error={errors.name ? true : false}
                            value={this.state.name}
                            onChange={this.handleChange}
                            size="small"
                            variant="outlined"
                            fullWidth
                          />
                        </Grid> 
                        <Grid item md={12} sm={12} xs={12}>
                          <TextField
                            id="lastName"
                            name="lastName"
                            type="text"
                            placeholder="Last Name"
                            className={classes.textField}
                            helperText={errors.lastName}
                            error={errors.lastName ? true : false}
                            value={this.state.lastName}
                            onChange={this.handleChange}
                            size="small"
                            variant="outlined"
                            fullWidth
                          />
                        </Grid>
                        <Grid item md={4} sm={6} xs={6}>
                          <Select
                            id="genere"
                            name="genere"
                            className={classes.textField}
                            placeholder="Genere"
                            helperText={errors.genere}
                            error={errors.genere ? true : false}
                            value={this.state.genere}
                            onChange={this.handleChange}
                            variant="outlined"
                            size="small"
                            fullWidth
                            style={{ height: "36px" }}
                          >
                            <MenuItem value="Male">Male</MenuItem> 
                            <MenuItem value="Female">Female</MenuItem>
                          </Select>
                        </Grid>
                        <Grid item md={8} sm={6} xs={6}>
                          <MuiPickersUtilsProvider
                            utils={DateFnsUtils}
                            className={classes.margin}
                          >
                            <KeyboardDatePicker
                              disableToolbar={true}
                              name="date"
                              variant="inline"
                              inputVariant="outlined"
                              autoOk
                              format="MM/dd/yyyy"
                              id="date-picker-inline"
                              placeholder="Date"
                              value={this.state.date ? this.state.date : null}
                              className={classes.margin}
                              onChange={this.handleChangeDateFrom}
                              size="small"
                              maxDate={new Date()}
                              InputLabelProps={{
                                classes: {
                                  root: classes.cssLabel,
                                  focused: classes.cssFocused,
                                },
                              }}
                              InputProps={{
                                classes: {
                                  root: classes.cssOutlinedInput,
                                  focused: classes.cssFocused,
                                  notchedOutline: classes.notchedOutline,
                                  input: classes.resizeDate,
                                },
                                inputMode: "numeric",
                              }}
                              KeyboardButtonProps={{
                                "aria-label": "change date",
                              }}
                            />
                          </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item md={12} sm={12} xs={12}>
                        <TextField
                            value={this.state.phone}
                            onChange={this.handleChange}
                            name="phone"
                            placeholder="Phone"
                            id="formatted-numberformat-input"
                            InputProps={{
                              inputComponent: NumberFormatCustom,
                            }}
                            className={classes.textField}
                            helperText={errors.phone}
                            error={errors.phone ? true : false}
                            size="small"
                            variant="outlined"
                            fullWidth
                          />
                        </Grid>
                        <Grid item md={12} sm={12} xs={12}>
                          <TextField
                            id="email"
                            name="email"
                            type="text"
                            placeholder="Email"
                            className={classes.textField}
                            helperText={errors.email}
                            error={errors.email ? true : false}
                            value={this.state.email}
                            onChange={this.handleChange}
                            size="small"
                            variant="outlined"
                            fullWidth
                            disabled
                          />
                        </Grid>
                        <Grid item md={12} sm={12} xs={12}>
                          <Button
                            type="submit"
                            variant="contained"
                            color="secondary"
                            fullWidth
                            onClick={this.handleSubmit}
                            disabled={isDisabled}
                          >
                            Save
                          </Button>
                          <Dialog
                            open={open}
                            keepMounted
                            onClose={this.handleClose}
                            aria-labelledby="alert-dialog-slide-title"
                            aria-describedby="alert-dialog-slide-description"
                          >
                          <DialogTitle id="alert-dialog-slide-title">{"Congrats!"}</DialogTitle>
                          <DialogContent>
                            <DialogContentText id="alert-dialog-slide-description">
                              Perfil guardado con exito
                            </DialogContentText>
                          </DialogContent>
                          <DialogActions>
                            <Button onClick={this.handleClose} color="secondary" variant="contained">
                              Ok
                            </Button>
                          </DialogActions>
                        </Dialog>
                        </Grid>
                        <Grid item md={12} sm={12} xs={12}>
                          {error && <CheckoutError>{error}</CheckoutError>}
                          </Grid>

                      </Grid>                    
                    </FormControl>
          </div>
    );
  }
}

Account.propTypes = {
  classes: PropTypes.object.isRequired,
  UI: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  UI: state.UI,
  data: state.data,
  user: state.user,
});

const mapActionsToProps = {
  //Requests with redux
    // postTool,
  };

const styles = (theme) => ({
  root:{
    marginLeft:"0px"
  },
  fullWidth :{
    width: "25vw",
    // position: "relative",
    // left: "50%",
    // right: "50%",
    // marginLeft: "-150vw",
    // marginRight: "-150vw",

    [theme.breakpoints.down("sm")]: {      
      width: "100%",
    },
  },
  input :{
    // backgroundColor: "rgba(0, 0, 0, 0.08)",
    position: 'relative',
    borderRadius:'3px',
    '& $notchedOutline': {
        borderColor: 'rgba(0, 0, 0, 0.23)',
        borderWidth: 0,
    },
    '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
        borderColor: '#4A90E2',
        borderWidth: 0,
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
            borderColor: 'rgba(0, 0, 0, 0.23)',
            borderWidth: 0,
        },
    },
    '&$focused $notchedOutline': {
        borderColor: '#4A90E2',
        borderWidth: 0,
    },
  },
  card: {
    display: "flex",
    marginLeft: "auto",
    marginRight: "auto",
    maxWidth: "400px",
    marginTop: theme.spacing(0),
  },
  h5: {
    display: "flex",
    marginBottom: theme.spacing(0),
    padding: theme.spacing(0),
    fontFamily: "Open Sans",
    fontSize: "24px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1,
    letterSpacing: "0.18px",
    color: "rgba(0, 0, 0, 0.87)",
    alignItems: "left",
    justifyContent: "left",
    textAlign: "left",
  },
  content: {
    display: "flex",
    marginBottom: theme.spacing(0),
    padding: theme.spacing(0),
    fontFamily: "Open Sans",
    fontSize: "14px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1,
    letterSpacing: "0.18px",
    color: "rgba(0, 0, 0, 0.87)",
    alignItems: "left",
    justifyContent: "left",
    textAlign: "left",
  },
});

export default connect(mapStateToProps, mapActionsToProps)(
  withStyles(styles)(Account)
);
