import React, { Component, Fragment } from "react";
import { Card, Grid } from "@material-ui/core";

import PropTypes from "prop-types";
import { connect } from "react-redux";

import Navbar from "../../components/layout/Navbar";

import {
  getAllContact,
  sendNewMessage,
  getChatRoomByContactId
} from "./ChatService";

import { getUserData } from "../../redux/actions/userActions";


import ChatSidenav from "./ChatSidenav";
import ChatContainer from "./ChatContainer";
import { isMobile } from "../../util/utils";

class AppChat extends Component {
  state = {
    user: null,
    chatInfoList: [],
    messageList: [],
    currentChatRoom: "",
    opponentUser: null,
    open: true
  };

  bottomRef = React.createRef();

  componentDidMount() {
    // let { id } = this.state.currentUser;

    this.props.getUserData();

    // getAllContact(this.state.currentUser.id).then(data =>
    //   this.setState({ chatInfoList: [...data.data] })
    // );
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.user) {
      this.setState({ user: nextProps.user });

      getAllContact(nextProps.user.userHandle).then(data =>
        this.setState({ chatInfoList: [...data.data] })
      );
    }
  }

  scrollToBottom = () => {
    this.bottomRef.current.scrollIntoView({ behavior: "smooth" });
  };

  handleContactClick = chatInfo => {
    if (isMobile()) this.toggleSidenav();

    getChatRoomByContactId(this.state.user.userHandle, chatInfo).then(
      ({ data }) => {
        let { messages } = data;

        this.setState(
          {
            currentChatRoom: chatInfo.chatId,
            messageList: messages
          },
          () => {
            this.bottomRef.scrollTop = 9999999999999;
          }
        );
      }
    );
  };

  handleMessageSend = message => {
    let id = this.state.user.userHandle;
    let { currentChatRoom, opponentUser } = this.state;
    if (currentChatRoom === "") return;
    sendNewMessage({
      chatId: currentChatRoom,
      text: message,
      contactId: id,
      time: new Date()
    }).then(data => {

      this.setState(
        {
          messageList: [...this.state.messageList, data.data.messages]         
        },
        () => {
          this.bottomRef.scrollTop = 9999999999999;
        }
      );
    });
  };

  setBottomRef = ref => {
    this.bottomRef = ref;
  };

  toggleSidenav = () => this.setState({ open: !this.state.open });

  render() {
    let {
      currentUser,
      chatInfoList,
      messageList,
      opponentUser,
      currentChatRoom
    } = this.state;

    const {
      user
    } = this.props;

    return (
      <Fragment>
        <Navbar />
        <Grid container spacing={5}>
          <Grid item sm={3}>
              <ChatSidenav
                currentUser={currentUser}
                contactList={chatInfoList}
                // recentContactList={recentContactList}
                handleContactClick={this.handleContactClick}
              />
              </Grid>
            <Grid item sm={9}>
              <ChatContainer
                id={user.userHandle}
                opponentUser={opponentUser}
                messageList={messageList}
                currentChatRoom={currentChatRoom}
                setBottomRef={this.setBottomRef}
                handleMessageSend={this.handleMessageSend}
                toggleSidenav={this.toggleSidenav}
              />
            </Grid>
        </Grid>
      </Fragment>
    );
  }
}

AppChat.propTypes = {
  user: PropTypes.object.isRequired,
  getUserData: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user,
});


export default connect(mapStateToProps, { getUserData })((AppChat));
