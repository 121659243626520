import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
// MUI Stuff
import Button from "@material-ui/core/Button";

// Redux stuff
import { connect } from "react-redux";
import {
  clearErrors,
} from "../../redux/actions/dataActions";

import AddTool from "./AddTool";

class ButtonAddTool extends Component {
  state = {
    open: false,
    active: true,
    errors: {},
    handle: "",
    authenticated: false,
    crendentials: {},
  };

  static defaultProps = {
    classes: PropTypes.object.isRequired,
  };

  fileObj = [];
  fileArray = [];
  addTool = "";

  componentWillReceiveProps(nextProps) {
    if (nextProps.UI.errors) {
      console.log("Errors:", nextProps.UI.errors);
      this.setState({ errors: nextProps.UI.errors });
    }

    if (nextProps.user.authenticated) {
      this.setState({ authenticated: nextProps.user.authenticated });
    }
  }

  handleOpen = () => {
    !this.state.authenticated
      ? (window.location.href = "/login")
      : this.setState({ open: true });
  };
  handleClose = () => {
    this.setState({ open: false});
  };

  render() {
    const {
      open,
      active,
      errors,
      crendentials,
      handle,
    } = this.state;
    const values = {
      active,
      open,
      handle,
      errors,
      crendentials,
    };
    const { classes } = this.props;

    this.addTool = (
      <AddTool
        close={this.handleClose}
        values={values}
      />
    );

    return (
      <Fragment>
        <Button
          color="secondary"
          className={classes.button}
          onClick={this.handleOpen}
          variant="outlined"
          fullWidth
        >
          Add Tool
        </Button>

        {this.addTool}
      </Fragment>
    );
  }
}

const styles = (theme) => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  button: {
    borderRadius: 5,
    height: "40px",
    fontSize: 14,
    margin: theme.spacing(0),
  },
});

ButtonAddTool.propTypes = {
  clearErrors: PropTypes.func.isRequired,
  UI: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  UI: state.UI,
  user: state.user,
  authenticated: state.user.authenticated,
  crendentials: state.user.crendentials,
});
const mapActionsToProps = { clearErrors };

export default connect(
  mapStateToProps,
  mapActionsToProps
)(withStyles(styles)(ButtonAddTool));
