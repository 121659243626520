import React from "react";
import { Icon, Badge, IconButton, Drawer, Card } from "@material-ui/core";
import { ThemeProvider, withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { getTimeDifference } from "../../util/utils.js";

import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import MailIcon from "@material-ui/icons/Mail";
import Container from "@material-ui/core/Container";

import AccountCircleOutlinedIcon from "@material-ui/icons/AccountCircleOutlined";

import ChatAvatar from "../../pages/messages/ChatAvatar";



import {
  getNotification,
  deleteNotification,
  // deleteAllNotification
} from "../../redux/actions/dataActions";

import NotificationsOutlinedIcon from "@material-ui/icons/NotificationsOutlined";
import DeleteForeverOutlinedIcon from "@material-ui/icons/DeleteForeverOutlined";
import MenuIcon from "@material-ui/icons/Menu";

import formatDistance from "date-fns/formatDistance";
import differenceInDays from "date-fns/differenceInDays";

import { Button, Grid } from "@material-ui/core";

import { logoutUser, getUserData } from "../../redux/actions/userActions";
import { makeStyles } from '@material-ui/core/styles';



let cartListLoaded = false;

const useStyles = makeStyles({
  primary:{
  fontFamily: "Open Sans",
  fontSize: "0.875rem",
  fontWeight: "normal",
  fontStretch: "normal",
  fontStyle: "normal",
  lineHeight: "1.3",
  letterSpacing: "0.15px",
  color: "rgba(0, 0, 0, 0.87)",
  },
  label: {
    fontFamily: "Open Sans",
    fontSize: "16px",
    fontWeight: "600",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1",
    letterSpacing: "1.25px",
    color: "#ffffff",
  }
});

function MenuProfile(props) {
  const {
    container,
    theme,
    settings,
    notifications: notifcationList = [],
    getNotification,
    // deleteAllNotification,
    deleteNotification,
    user,
    color,
  } = props;

  const [panelOpen, setPanelOpen] = React.useState(false);

const classes = useStyles(props);

  if (!cartListLoaded) {
    console.log(user);
    getNotification();
    cartListLoaded = true;
  }

  function handleDrawerToggle() {
    if(typeof user.credentials.handle != "undefined"){
      setPanelOpen(!panelOpen);
    }
    else{
      window.location.href="/login";
    }
  }

  function Logout() {
    console.log("Logout");
    props.logoutUser();
    window.location.href = "/login";
  }

  const list = () => (
    <div
      // className={classes.list}
      role="presentation"
      onClick={handleDrawerToggle}
      onKeyDown={handleDrawerToggle}
    >
      <List>
        <ListItem button component="a" href="/users" key="0">
          <ListItemText classes={classes} primary={"Account Profile"} />
        </ListItem>
        <ListItem button component="a" href="/messages" key="1">
          <ListItemText classes={classes} primary={"Message"} />
        </ListItem>
        <ListItem button component="a" href="/resolution" key="2">
          <ListItemText classes={classes} primary={"Resolution"} />
        </ListItem>
        <ListItem button component="a" href="/setting" key="3">
          <ListItemText classes={classes} primary={"Settings"} />
        </ListItem>
        <ListItem button component="a" href="/search" key="4">
          <ListItemText classes={classes} primary={"Tools"} />
        </ListItem>
      </List>
      <Divider />
      <Container style={{ paddingTop: "16px" }}>
            <Button 
              style={{ marginBottom: "16px" }}
              fullWidth
              variant="contained"
              color="secondary"
              onClick={Logout}>
                SIGN OUT
              </Button>

      </Container>
    </div>
  );

  return (
    <ThemeProvider>
      <IconButton style={{paddingLeft:"10px" }} onClick={handleDrawerToggle} className={color}>
        <AccountCircleOutlinedIcon />
      </IconButton>

      <Drawer
        width={"100px"}
        container={container}
        variant="temporary"
        anchor={"right"}
        open={panelOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true,
        }}
      >
        <div style={{padding:"24px 0px 12px 24px", display:"flex"}}>
            <div className="p-2 col-example text-left">
              <ChatAvatar src={user.credentials.imageUrl} status="" />
            </div>
            <div className="p-2 col-example text-left">
              <div className={`px-8 py-8 list__message`} >
                <span style={{
                  fontFamily: "Open Sans",
                  fontSize: "16px",
                  fontWeight: "bold",
                  fontStretch: "normal",
                  fontStyle: "normal",
                  lineHeight: "1.25",
                  letterSpacing: "0.18px",
                  color: "rgba(0, 0, 0, 0.87)",

                }} className="whitespace-pre-wrap">{user.credentials.firstName} {user.credentials.lastName}</span>
              </div>
            </div>
        </div>
        <div style={{padding:"0px 0px 22px 20px"}} className="mini-cart">{list()}</div>
      </Drawer>
    </ThemeProvider>
  );
}

MenuProfile.propTypes = {
  // settings: PropTypes.object.isRequired,
  notifications: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user,
  getNotification: PropTypes.func.isRequired,
  deleteNotification: PropTypes.func.isRequired,
  deleteAllNotification: PropTypes.func.isRequired,
  notifications: state.data.notificationList,
  logoutUser: PropTypes.func.isRequired,

});

export default withStyles(
  {},
  { withTheme: true }
)(
  connect(mapStateToProps, {
    getNotification,
    deleteNotification,
    logoutUser,

    // deleteAllNotification
  })(MenuProfile)
);
