import {
  SET_TOOL_LIST,
  SET_CATEGORY_LIST,
  SET_BRAND_LIST,
  SET_PRODUCTS_NAME_LIST,
  SET_RATING_LIST,
  SET_TOOLS_USER,
  LIKE_TOOL,
  UNLIKE_TOOL,
  LOADING_DATA,
  DELETE_TOOL,
  POST_TOOL,
  UPDATED_TOOL,
  UPDATE_DATA,
  SET_TOOL,
  SUBMIT_COMMENT,
  SET_PRODUCTS_TO_CART,
  SET_NOTIFICATIONS,
} from "../types";

const initialState = {
  toolList: [],
  categoryList: [],
  ratingList: [],
  brandList: [],
  productNameList: [],
  toolImageList:[],
  cartList: [],
  notificationList: [],
  tool: {},
  toolsOfUser: [],
  loading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LOADING_DATA:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_DATA:
      return {
        ...state,
        update: true,
      };
    case SET_TOOL_LIST:
      return {
        ...state,
        toolList: action.payload,
        loading: false,
      };
    case SET_TOOLS_USER:
      return {
        ...state,
        toolsOfUser: action.payload,
        loading: false,
      };
    case SET_CATEGORY_LIST:
      return {
        ...state,
        categoryList: [...action.payload],
        loading: false,
      };
    case SET_BRAND_LIST:
      return {
        ...state,
        brandList: [...action.payload],
        loading: false,
      };
      case SET_PRODUCTS_NAME_LIST:
      return {
        ...state,
        productNameList: [...action.payload],
        loading: false,
      };
    case SET_RATING_LIST:
      return {
        ...state,
        ratingList: [...action.payload],
        loading: false,
      };
    case SET_NOTIFICATIONS:
      return{
          ...state,
          notificationList: [...action.payload],
          loading: false,
      };
      case SET_PRODUCTS_TO_CART:
        return {
          ...state,
        cartList: [...action.payload],
        loading: false,
        };
    case SET_TOOL:
      return {
        ...state,
        tool: action.payload,
        loading: false,
      };
    case LIKE_TOOL:
    case UNLIKE_TOOL:
      let index = state.tools.findIndex(
        (tool) => tool.toolId === action.payload.toolId
      );
      state.tools[index] = action.payload;
      if (state.tool.toolId === action.payload.toolId) {
        state.tool = action.payload;
      }
      return {
        ...state,
      };
    case DELETE_TOOL:
      index = state.tools.findIndex((tool) => tool.toolId === action.payload);
      state.tools.splice(index, 1);
      return {
        ...state,
      };
    case POST_TOOL:
      return {
        ...state,
        loading: false,
        nextStep: action.nextStep,
        tools: [action.payload, ...state.tools],
      };
    case UPDATED_TOOL:
      return {
        ...state,
        toolImageList: action.payload.imageUrls,
        update: false,
      };
    case SUBMIT_COMMENT:
      return {
        ...state,
        tool: {
          ...state.tool,
          comments: [action.payload, ...state.tool.comments],
        },
      };
    default:
      return state;
  }
}
