import React, { Component, Fragment } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";

// MUI Stuff
// --- Post bootstrap -----
import Navbar from "../components/layout/Navbar";
import BlogNavBar from "../components/blog/BlogNavbar";
import PostContent from "../components/blog/PostContent";

import { getPostId, addNewPost, getAllPosts } from "./BlogService";

// Redux stuff
import { connect } from "react-redux";

class Post extends Component {
  constructor() {
    super();
    this.state = {
      postList: [],
      post: {},
    };
  }

  componentWillMount() {

    getAllPosts("title").then((data) =>
    this.setState({ postList: [...data.data] })
  );

  let postId = this.props.match.params.id;

  getPostId(postId).then((data) => this.setState({ post: { ...data.data } }));

  }
  componentWillReceiveProps(nextProps) {
    
  }




  render() {
    const {
      classes,
    } = this.props;

    let { postList, post } = this.state;

    return (
      <Fragment>
      <Navbar isTransparent={false} />
      <BlogNavBar isTransparent={false} />
      <div className={classes.header} style={{backgroundImage: `url(${post.image})`}}/>
      <PostContent post={post} postList={postList} />
    </Fragment>
    );
  }
}

Post.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  UI: state.UI,
  data: state.data,
});

const mapActionsToProps = {
    

  };

const styles = (theme) => ({
  header: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 284,
    bottom: 0,
    height: "550px",
    objectFit: "cover",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    zIndex: -10,
    [theme.breakpoints.down("sm")]: {
      top: 70,
      height: "320px",
      // backgroundSize: "cover",
      // minWidth:"375px",
      // maxWidth:"375px"

    },
  }
});

export default connect(mapStateToProps, mapActionsToProps)(
  withStyles(styles)(Post)
);

