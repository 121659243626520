import React from "react";
import { Icon, Badge, IconButton, Drawer, Card } from "@material-ui/core";
import { ThemeProvider, withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { getTimeDifference } from "../../util/utils.js";

import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import MailIcon from "@material-ui/icons/Mail";
import Container from "@material-ui/core/Container";

import {
  getNotification,
  deleteNotification,
  // deleteAllNotification
} from "../../redux/actions/dataActions";

import NotificationsOutlinedIcon from "@material-ui/icons/NotificationsOutlined";
import DeleteForeverOutlinedIcon from "@material-ui/icons/DeleteForeverOutlined";
import MenuIcon from "@material-ui/icons/Menu";

import formatDistance from "date-fns/formatDistance";
import differenceInDays from "date-fns/differenceInDays";

import { Button, Grid } from "@material-ui/core";

let cartListLoaded = false;

function SideNav(props) {
  const {
    container,
    theme,
    settings,
    notifications: notifcationList = [],
    getNotification,
    // deleteAllNotification,
    deleteNotification,
    user,
    color,
  } = props;

  const [panelOpen, setPanelOpen] = React.useState(false);

  if (!cartListLoaded) {
    console.log(user);
    getNotification();
    cartListLoaded = true;
  }

  function handleDrawerToggle() {
    setPanelOpen(!panelOpen);
  }

  const list = () => (
    <div
      // className={classes.list}
      role="presentation"
      onClick={handleDrawerToggle}
      onKeyDown={handleDrawerToggle}
    >
      <List>
        <ListItem button component="a" href="/" key="0">
          <ListItemText primary={"HOME"} />
        </ListItem>
        <ListItem button component="a" href="/search" key="1">
          <ListItemText primary={"SEARCH"} />
        </ListItem>
        <ListItem button component="a" href="/blog" key="2">
          <ListItemText primary={"COMMUNITY"} />
        </ListItem>
        <ListItem button component="a" href="/faq" key="3">
          <ListItemText primary={"HELP"} />
        </ListItem>
        <ListItem button component="a" href="/login" key="4">
          <ListItemText primary={"JOIN FOR FREE"} />
        </ListItem>
      </List>
      <Divider />
      <Container style={{ paddingTop: "16px" }}>
        <Button
          style={{ marginBottom: "16px" }}
          fullWidth
          variant="contained"
          color="secondary"
          href="/signup"
        >
          CREATE AN ACCOUNT
        </Button>

        <Button 
          fullWidth 
          variant="outlined" 
          color="secondary" 
          href="/login"
        >
          SIGN IN
        </Button>
      </Container>
    </div>
  );

  return (
    <ThemeProvider>
      <IconButton style={{paddingLeft:"0px" }} onClick={handleDrawerToggle} className={color}>
        <MenuIcon />
      </IconButton>

      <Drawer
        width={"100px"}
        container={container}
        variant="temporary"
        anchor={"left"}
        open={panelOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true,
        }}
      >
        <div className="mini-cart">{list()}</div>
      </Drawer>
    </ThemeProvider>
  );
}

SideNav.propTypes = {
  // settings: PropTypes.object.isRequired,
  notifications: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user,
  getNotification: PropTypes.func.isRequired,
  deleteNotification: PropTypes.func.isRequired,
  deleteAllNotification: PropTypes.func.isRequired,
  notifications: state.data.notificationList,
});

export default withStyles(
  {},
  { withTheme: true }
)(
  connect(mapStateToProps, {
    getNotification,
    deleteNotification,
    // deleteAllNotification
  })(SideNav)
);
