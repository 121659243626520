import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";

import header from "../../../images/header.jpg";
import headerM from "../../../images/header_m.jpg";

const styles = (theme) => ({
  root: {
    /* [theme.breakpoints.up('sm')]: {
      minHeight: 500,
      maxHeight: 1300,
    },*/
    /*paddingLeft: theme.spacing(20),
    paddingRight: theme.spacing(20),*/
  },
  background: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    minHeight: "720px",
    objectFit: "contain",
    backgroundSize: 'contain',
    backgroundRepeat: "no-repeat",
    zIndex: -10,
    backgroundImage: `url(${header})`,
    [theme.breakpoints.down("sm")]: {
      backgroundImage: `url(${headerM})`
    },
  },
});

function HomeLayout(props) {
  const { backgroundClassName, children, classes } = props;

  return (
    <section className={classes.root} style={{paddingTop: "50px"}}>
      <Container>
        <div className={classes.background} />
      </Container>
    </section>
  );
}

HomeLayout.propTypes = {
  backgroundClassName: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(HomeLayout);
