import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./styles/_app.scss";
import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider";
import createMuiTheme from "@material-ui/core/styles/createMuiTheme";
import jwtDecode from "jwt-decode";

import 'react-quill/dist/quill.snow.css'; // ES6


import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
// Redux
import { Provider } from "react-redux";
import store from "./redux/store";
import { SET_AUTHENTICATED } from "./redux/types";
import { logoutUser, getUserData } from "./redux/actions/userActions";
// Components
import FooterBar from "./components/layout/FooterBar";

import themeObject from "./util/theme";
import AuthRoute from "./util/AuthRoute";
// Pages
import home from "./pages/home";
import Shop from "./pages/Shop";
import help from "./pages/faq";
import login from "./pages/login";
import signup from "./pages/signup";
import user from "./pages/user";
import detail from "./pages/ToolDetail";
import Checkout from "./pages/Checkout";
import PlaceOrder from "./pages/PlaceOrder";
import ConfirmOrder from "./pages/ConfirmOrder";
import Messages from "./pages/messages/AppChat"
import Blog from "./pages/Blog";
import Post from "./pages/Post";
import EditorForm from "./pages/EditorForm"
import Setting from "./pages/Settings"


import axios from "axios";

const theme = createMuiTheme(themeObject);

axios.defaults.baseURL = "https://us-central1-toowlz.cloudfunctions.net/api";
// axios.defaults.baseURL = 'http://localhost:5000/toowlz/us-central1/api'

const stripePromise = loadStripe("pk_test_OZIu41A46gg7X1bNqrFqRv0Z00wNbhrBvL");


const token = localStorage.FBIdToken;
if (token) {
  const decodedToken = jwtDecode(token);
  if (decodedToken.exp * 1000 < Date.now()) {
    store.dispatch(logoutUser());
    window.location.href = "/login";
  } else {
    store.dispatch({ type: SET_AUTHENTICATED });
    axios.defaults.headers.common["Authorization"] = token;
    store.dispatch(getUserData());
  }
}

class App extends Component {
  render() {
    return (
      <MuiThemeProvider theme={theme}>
        <Provider store={store}>
          <Router>
            <Elements stripe={stripePromise}>
              <div className="app-container">
                <Switch>
                  <Route exact path="/" component={home} />
                  <Route exact path="/faq" component={help} />
                  <Route exact path="/search" component={Shop} />
                  <AuthRoute exact path="/messages" component={Messages} />
                  <Route exact path="/search/:name/:zip" component={Shop} />
                  <Route exact path="/detail/:id" component={detail} />
                  <AuthRoute exact path="/checkout" component={Checkout} />
                  <AuthRoute exact path="/order" component={PlaceOrder} />
                  <AuthRoute exact path="/confirm/:payment" component={ConfirmOrder} />
                  <Route exact path="/login" component={login} />
                  <Route exact path="/signup" component={signup} />
                  <AuthRoute exact path="/users" component={user} />
                  <AuthRoute exact path="/setting" component={Setting} />
                  <Route exact path="/blog" component={Blog} />
                  <Route exact path="/blog/:query" component={Blog} />
                  <Route exact path="/post/:id" component={Post} />
                  <Route exact path="/editor" component={EditorForm} />
                  <AuthRoute
                    exact
                    path="/users/:profileId/tool/:toolId"
                    component={detail}
                  />
                </Switch>
              </div>
              <FooterBar isTransparent={false}/>
            </Elements>
          </Router>
        </Provider>
      </MuiThemeProvider>
    );
  }
}

export default App;
