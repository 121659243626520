import React, { Component } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import rectangle from "../images/bg_login.jpg";
import img_m from "../images/bg_login_m.jpg";

import Navbar from "../components/layout/Navbar";

import i18next from "i18next";
import {useTranslation, withTranslation } from "react-i18next"

import axios from "axios";


// MUI Stuff
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Card, 
         CardContent,
         Checkbox,
         Link,
         FormGroup,
         FormControlLabel } from "@material-ui/core";

// Redux stuff
import { connect } from "react-redux";
import { loginUser } from "../redux/actions/userActions";

import "./_sessions.scss";

class login extends Component {
  constructor() {
    super();
    this.state = {
      email: "",
      password: "",
      errors: {},
    };
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.UI.errors) {
      this.setState({ errors: nextProps.UI.errors });
    }
  }
  handleSubmit = (event) => {
    event.preventDefault();
    const userData = {
      email: this.state.email,
      password: this.state.password,
    };
    this.props.loginUser(userData, this.props.history);
  };
  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleForgot = async() =>{
    console.log("handleForgot")
    let data ="";
    if(this.state.email===""){
      this.setState({"errors": { general: "Debe rellenar el campo de email" }})
      return
    }
      try {
        data = await axios.post("/resetPassword", { emailAddress:this.state.email});
        this.setState({"errors": { general: "Se ha enviado un email con las instrucciones" }})
      } catch (error) {
        console.log(error.response.data.message);
        if(error.response){
        this.setState({"errors": { general: error.response.data.message}})
        }
        else{
          this.setState({"errors": { general: error}})
        }
        return;
      }
  }
  render() {
    const {
      classes,
      UI: { loading },
    } = this.props;
    const { errors } = this.state;

    const { t } = this.props;

    // const {t} = useTranslation()

    return (

      <div className={classes.root}>
            <Navbar isTransparent={true}/>

        <div className={classes.background} />

      <div style={{padding:"50px 20px 0px 20px"}}>
        <Card className={classes.card} display="flex" justifyContent="center">
          <CardContent className={classes.cardcontent}>
            <Grid container spacing={5}>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Grid container className={classes.form} spacing={3}>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Typography
                      color="inherit"
                      align="left"
                      variant="h4"
                      marked="left"
                      className={classes.h5}
                    >
                      { i18next.t('login.title')}
                    </Typography>
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <form noValidate onSubmit={this.handleSubmit} spacing={5}>
                      <Grid container spacing={2}>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <TextField
                            id="email"
                            name="email"
                            type="email"
                            label={ i18next.t('login.email')}
                            className={classes.textField}
                            helperText={errors.email}
                            error={errors.email ? true : false}
                            value={this.state.email}
                            onChange={this.handleChange}
                            variant="outlined"
                            size="small"
                            fullWidth
                            InputLabelProps={{
                              classes: {
                                root: classes.cssLabel,
                                focused: classes.cssFocused,
                              },
                            }}
                            InputProps={{
                              classes: {
                                root: classes.cssOutlinedInput,
                                focused: classes.cssFocused,
                                notchedOutline: classes.notchedOutline,
                              },
                              inputMode: "numeric",
                            }}
                          />
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <TextField
                            id="password"
                            name="password"
                            type="password"
                            label={ i18next.t('login.password')}
                            className={classes.textField}
                            helperText={errors.password}
                            error={errors.password ? true : false}
                            value={this.state.password}
                            onChange={this.handleChange}
                            size="small"
                            variant="outlined"
                            fullWidth
                            InputLabelProps={{
                              classes: {
                                root: classes.cssLabel,
                                focused: classes.cssFocused,
                              },
                            }}
                            InputProps={{
                              classes: {
                                root: classes.cssOutlinedInput,
                                focused: classes.cssFocused,
                                notchedOutline: classes.notchedOutline,
                              },
                              inputMode: "numeric",
                            }}
                          />
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <small className={classes.more}>
                            <Link className={classes.more} href="#" onClick={this.handleForgot}>
                              Forgot Passwolrd?
                            </Link>
                          </small>
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          {errors.general && (
                            <Typography
                              variant="body2"
                              className={classes.customError}
                            >
                              {errors.general}
                            </Typography>
                          )}
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <Button
                            color="secondary"
                            className={classes.button}
                            variant="contained"
                            type="submit"
                            disabled={loading}
                            fullWidth
                          >
                              { i18next.t('login.signIn')}
                            {loading && (
                              <CircularProgress
                                size={30}
                                className={classes.progress}
                              />
                            )}
                          </Button>
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <small className={classes.more}>
                            { i18next.t('login.titleCreateAccount') /* Don’t have an account?*/}
                          </small>
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <Button
                            type="link"
                            variant="outlined"
                            color="secondary"
                            className={classes.button}
                            disabled={loading}
                            fullWidth
                            href="/signup"
                          >
                          { i18next.t('login.createAccount') /* Create account?*/}
                          </Button>
                        </Grid>
                      </Grid>
                    </form>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <div className={classes.side} >
            <FormGroup>
                <FormControlLabel
                  classes={{ label: classes.label }}
                  control={<Checkbox value={true} checked={true}/>}
                  label={ i18next.t('login.addYourTools') /* "Add your tools"*/}
                />
                <FormControlLabel
                  classes={{ label: classes.label }}
                  control={<Checkbox value={true} checked={true}/>}
                  label={ i18next.t('login.rentTools') /* "Rent tools"*/}
                />
                <FormControlLabel
                  classes={{ label: classes.label }}
                  control={<Checkbox value={true} checked={true}/>}
                  label={ i18next.t('login.makingMoney') /* "Start making money"*/}
                />
              </FormGroup>
            </div>
          </CardContent>
        </Card>
      </div>
      </div>
    );
  }
}

login.propTypes = {
  classes: PropTypes.object.isRequired,
  loginUser: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  UI: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user,
  UI: state.UI,
});

const mapActionsToProps = {
  loginUser,
};

const styles = (theme) => ({
  root:{
    minHeight: 900,
  },
  card: {
    display: "flex",
    marginLeft: "auto",
    marginRight: "auto",
    maxWidth: "650px",
    marginTop: theme.spacing(10),

    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(5),
    }
  },
  cardcontent: {
    padding: "30px",
    "&:last-child": {
      paddingBottom: "30px",
    },
    display:"flex",
    alignItems: "center",
  },
  side:{
    minWidth: "210px", 
    marginLeft:"40px",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    }
  },
  form:{
    maxWidth: "400px", 
    borderRight: "solid 1px gray", 
    paddingRight: "18px",
    [theme.breakpoints.down("sm")]: {
      borderRight: "solid 0px gray", 
      paddingRight: "12px"    
    }
  },
  button: {
    borderRadius: 5,
    height: "48px",
    fontSize: 14,
    margin: theme.spacing(0),
  },
  label:{
    fontFamily: "Open Sans",
    fontSize: "16px",
    fontWeight: 600,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.5,
    letterSpacing: "0.15px",
    color: "rgba(0, 0, 0, 0.87)",
  },
  h5: {
    display: "flex",
    marginBottom: theme.spacing(2),
    padding: theme.spacing(0),
    fontFamily: "Open Sans",
    fontSize: "24px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1,
    letterSpacing: "0.18px",
    color: "rgba(0, 0, 0, 0.87)",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
  },
  more: {
    display: "flex",
    fontFamily: "Open Sans",
    fontSize: "12px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: " normal",
    lineHeight: "1.33",
    letterSpacing: "0.4px",
    color: "#000000",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
  },
  background: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    minHeight: 900,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    zIndex: -10,
    backgroundImage: `url(${rectangle})`,

    [theme.breakpoints.down("sm")]: {
      backgroundImage: `url(${img_m})`,
    }
  },
  cssLabel: {
    color: "gray",
  },
  cssOutlinedInput: {
    "&$cssFocused $notchedOutline": {
      borderColor: `gray !important`,
      color: "gray",
    },
  },
  cssFocused: {
    color: "gray",
  },
  notchedOutline: {
    borderColor: "gray !important",
  },
});

export default connect(
  mapStateToProps,
  mapActionsToProps
)(withStyles(styles)(login));

