import AppBar from "@material-ui/core/AppBar";
import IconButton from "@material-ui/core/IconButton";
import { withStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";

import { connect } from "react-redux";
import PropTypes from "prop-types";

import { Link } from "react-router-dom";

import MenuItem from "@material-ui/core/MenuItem";
import Icon from '@material-ui/core/Icon';
import Menu from "@material-ui/core/Menu";
import AccountCircleOutlinedIcon from "@material-ui/icons/AccountCircleOutlined";
import MailOutlinedIcon from "@material-ui/icons/MailOutlined";
import ShoppingCartOutlinedIcon from "@material-ui/icons/ShoppingCartOutlined";

import Typography from '@material-ui/core/Typography';
import InputBase from '@material-ui/core/InputBase';
import Badge from '@material-ui/core/Badge';
import SearchIcon from '@material-ui/icons/Search';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MailIcon from '@material-ui/icons/Mail';
import NotificationsIcon from '@material-ui/icons/Notifications';
import MoreIcon from '@material-ui/icons/MoreVert';
import LanguageOutlinedIcon from '@material-ui/icons/LanguageOutlined';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';


import React, { Component } from "react";
import { logoutUser, getUserData } from "../../redux/actions/userActions";
import { productsCart } from "../../redux/actions/dataActions";


import PostTool from "../../components/tool/PostTool";
import Brand from "../shared/Brand";
import ShoppingCart from "./ShoppingCart";
import Notifications from "./Notification";
import SideNav from "./SideNav";
import MenuProfile from "./MenuProfile"

import axios from "axios";

import i18next from "i18next";

import i18n from "i18next";


class NavBar extends Component {

    // Set some state
    state = {
      setAnchorEl: "",
      mobileMoreAnchorEl: "",
      setMobileMoreAnchorEl: "",
      isMenuOpen: false,
      isMobileMenuOpen: false,
      open: false,
      auth: true,
      user: {
        authenticated: false,
        userhandle: "",
      },
      anchorEl: null,
      openMenu: true,
      payment:""
    };

  componentWillMount() {
    //console.log( this.props);

    this.props.getUserData();

    //this.props.productsCart("Ariel");

    this.payment().then((data) =>
      this.setState({ payment: data.data[0].id })
    );
  }

  payment = ( ) => {
    return axios.post("/intentPayment", {  });
  }

  handleOrder = ( ) => {
  this.order(this.state.payment).then((data) =>
   console.log( data.data)
);
}

order = ( payment) => {
return axios.post("/order", { payment  });
}

  componentWillReceiveProps(nextProps) {
    console.log( nextProps);

    if (nextProps.user) {
      this.setState({ user: nextProps.user });
    }
  }

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleCloseMenu = () => {
    this.setState({ anchorEl: null });
  };

  changeToSpanish = () => {
    i18n.changeLanguage('es');
    this.setState({ anchorEl: null });
  };

  changeToEnglish = () => {
    i18n.changeLanguage('en');
    this.setState({ anchorEl: null });
  };


  handleLogout = () => {
    this.props.logoutUser();
    this.setState({ anchorEl: null });
    window.location.href = "/login";
  };

  openNewTool = (event) => {
    event.preventDefault();
    this.setState({
      open: true,
    });
  };

  handleClose = () => {
    this.setState({
      open: false,
    });
  };

  render() {
    const { classes, handle, isTransparent } = this.props;

    const { open, anchorEl, user, payment } = this.state;

    console.log("Props", this.props)

    let backgroundColor = isTransparent ? "transparent" : "primary";

    let color = isTransparent ? classes.WButton : classes.BButton;

    let line = isTransparent ? "0px" : "1px";



    let MenuItemSession = !user.authenticated ? (
      <MenuItem onClick={this.handleLogout}>{ i18next.t('navBar.login') /*Login*/}</MenuItem>
    ) : (
      <MenuItem onClick={this.handleLogout}>{ i18next.t('navBar.logout') /*Logout*/}</MenuItem>
    );

    const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={Boolean(anchorEl)}
                onClose={this.handleCloseMenu}
                PopoverClasses={{
                  paper: classes.menu,
                }}
    >
      <MenuItem>   
      <Icon className="fa fa-plus-circle" />    
     </MenuItem>
      <MenuItem>     
        <IconButton aria-label="show 4 new mails" color="inherit">
          <Badge badgeContent={4} color="secondary">
            <MailIcon />
          </Badge>
        </IconButton>
        <p>{ i18next.t('navBar.messages') /*Messages*/ }</p>
      </MenuItem>
      <MenuItem>
        <IconButton aria-label="show 11 new notifications" color="inherit">
          <Badge badgeContent={11} color="secondary">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem>
      <MenuItem>
        <IconButton
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>{ i18next.t('navBar.profile') /*Profile*/ }</p>
      </MenuItem>
    </Menu>
  );

    return (
      <div style={{paddingLeft:"0px", paddingRight:"0px"}} className={classes.root}>
        <AppBar style={{paddingLeft:"0px", paddingRight:"0px" }} color={backgroundColor} elevation={0} position="static">
          <Toolbar style={{paddingLeft:"0px", paddingRight:"0px" }} className={classes.toolBar}>
          <div style={{paddingLeft:"0px" }} className={classes.sectionMobile}>
              <SideNav style={{paddingLeft:"0px" }} color={color} user={user} />
            </div>
            <Brand isTransparent= {isTransparent}/>
            
            <div className={classes.grow} />
            <div className={classes.sectionDesktop}>
              <Link
                className={`${color} ${classes.marginR}`}
                tip={ i18next.t('navBar.home') /*Home*/ }
                to="/"
                color="inherit"
              >
                { i18next.t('navBar.home') /*Home*/ }
              </Link>
              <Link
                className={`${color} ${classes.marginR}`}
                tip={ i18next.t('navBar.search') /*Search*/ }
                to="/search"
                color="inherit"
              >
                { i18next.t('navBar.search') /*Search*/ }
              </Link>

              {/* <PostTool /> */}

              <Link
                className={`${color} ${classes.marginR}`}
                tip={ i18next.t('navBar.joinForFree') /*Join for free*/ }
                to="/login"
                color="inherit"
              >
                { i18next.t('navBar.joinForFree') /*Join for free*/ }
              </Link>

              <Link
                className={`${color} ${classes.marginR}`}
                tip={ i18next.t('navBar.community') /*Community*/ }
                to="/blog"
                color="inherit"
              >
                { i18next.t('navBar.community') /*Community*/ }
              </Link>

              <Link
                className={`${color} ${classes.marginR}`}
                tip={ i18next.t('navBar.help') /*Help*/ }
                to="/faq"
                color="inherit"
              >
                { i18next.t('navBar.help') /*Help*/ }
              </Link>

              <Link
                className={`${color} ${classes.marginR}`}

              >
                |
              </Link>
              <IconButton
                color="inherit"
                className={color}
                style={{paddingRight:"0px"}}

              >
                <LanguageOutlinedIcon />
              </IconButton>
              <IconButton
                color="inherit"
                aria-owns={anchorEl ? "simple-menu" : null}
                aria-haspopup="true"
                onClick={this.handleClick}
                className={color}
                style={{paddingLeft:"6px"}}
              >
                <ExpandMoreIcon/>
              </IconButton>

              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={this.handleCloseMenu}
                PopoverClasses={{
                  paper: classes.menu,
                }}
              >
                <MenuItem
                  onClick={this.changeToSpanish}
                >
                  Spanish
                </MenuItem>
                <MenuItem
                  onClick={this.changeToEnglish}
                >
                  English
                </MenuItem>
              </Menu>



              <ShoppingCart order={this.handleOrder} color={color} user={user} payment={payment}></ShoppingCart>


              <IconButton
                color="inherit"
                component={Link}
                to="/messages"
                aria-label="upload picture"
                className={color}
              >
                <MailOutlinedIcon />
              </IconButton>

              <Notifications color={color} user={user}></Notifications>

              <MenuProfile color={color} user={user} logoutUser={logoutUser}></MenuProfile>

              
              </div>
              <div className={classes.sectionMobile}>
              <ShoppingCart order={this.handleOrder} color={color} user={user} payment={payment}></ShoppingCart>

              <Notifications color={color} user={user}></Notifications>

              <IconButton
                color="inherit"
                component={Link}
                to="/messages"
                aria-label="upload picture"
                className={color}
              >
                <MailOutlinedIcon />
              </IconButton>
            </div>
          </Toolbar>
        </AppBar>
        <div style={{position:"absolute",left:"0px", top:"62px", borderBottom: `solid ${line} lightgray`, width:"100%"}}></div>
      </div>
    );
  }
}

const styles = (theme) => ({
  root: {
    marginBottom: 0,
  },
  grow: {
    flexGrow: 1,
  },
  menu: {
    top: "100px",
  },
  toolBar: {
    minHeight: 62,
    //backgroundColor: "transparent",
  },
  marginR: {
    marginRight: 22,
  },
  marginL: {
    marginLeft: 22,
  },
  WButton: {
    fontFamily: "Open Sans",
    fontSize: 14,
    color: "white",
    fontWeight: 600,
    fontStretch: "normal",
    fontStyle: "normal",
    letterSpacing: "1.25px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textTransform: "uppercase",
  },
  BButton: {
    fontFamily: "Open Sans",
    fontSize: 14,
    color: "black",
    fontWeight: 600,
    fontStretch: "normal",
    fontStyle: "normal",
    letterSpacing: "1.25px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textTransform: "uppercase",
  },
  sectionDesktop: {
    display: "none",
    spacing: 22,
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
      display: 'flex',
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
  }
});

const mapStateToProps = (state) => ({
  user: state.user,
  cartList: state.data.cartList,
  payment: state.payment
});

NavBar.propTypes = {
  classes: PropTypes.object.isRequired,
  getUserData: PropTypes.func.isRequired,
  productsCart: PropTypes.func.isRequired,
  logoutUser: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired
};

export default connect(mapStateToProps, { logoutUser, getUserData, productsCart })(
  withStyles(styles)(NavBar)
);