// Imports
import React, { Component } from 'react';
import {TextField, 
        Grid} from '@material-ui/core';

import Script from 'react-load-script';

class PlaceSearch extends Component {
  // Define Constructor

  constructor(props) {
    super(props);

    console.log("Props", props)
    // Declare State
    this.state = {
      city: '',
      query: '',
      route: props.initialAddress,
    };

  }
  
  handleChange = ev => {
      if(ev.target.value===""){
        this.props.setAddress({});
        this.resetState();
      }
      else{
          this.setState({"route": ev.target.value})
      }
  }

  handleScriptLoad = () => {
    // Declare Options For Autocomplete
    // Initialize Google Autocomplete
    /*global google*/ // To disable any eslint 'google not defined' errors
    this.autocomplete = new google.maps.places.Autocomplete(
      document.getElementById('d-toowlz'), {types: ['geocode'],
                                                componentRestrictions: {country: 'US'}});

    // Avoid paying for data that you don't need by restricting the set of
    // place fields that are returned to just the address components and formatted
    // address.
    this.autocomplete.setFields(['address_components', 'place_id', 'geometry']);

    // Fire Event when a suggested name is selected
    this.autocomplete.addListener('place_changed', this.handlePlaceSelect);
  }

  resetState = () => {
    this.setState({ "street_number":'',
                    "route": '',
                    "neighborhood": '',
                    "locality": '',
                    "administrative_area_level_1": '',
                    "postal_code": '', 
                    "country": ''});

  }
  
  handlePlaceSelect = () => {

    // Extract City From Address Object
    const addressObject = this.autocomplete.getPlace();
    const address = addressObject.address_components;

    console.log("Adrress Objct ",addressObject.place_id)

    this.resetState();

    let address_object={}

    // Check if address is valid
    if (address) {
        let number="";
        let street="";
        address_object["place_id"]=addressObject.place_id;
        address_object["geometry"] = addressObject.geometry;
        for (var i = 0; i < addressObject.address_components.length; i++) {
            var addressType = addressObject.address_components[i].types[0];
            var val = addressObject.address_components[i].short_name;   
            if(addressType === "street_number"){
              number = val;
            } 
            if(addressType === "route"){
              street = val;
            }
            this.setState({[addressType]:val});
            address_object[addressType]=val
        }
        this.setState({"route":number+" "+street});
        this.setState({"address_object":address_object});
    }

    this.props.setAddress(address_object);

  }

  render() {
    return (
      <div>
          <Script
            url="https://maps.googleapis.com/maps/api/js?key=AIzaSyDpdv91FCJxMvanW382mbvo1wpy14kiA-Q&libraries=places"
            onLoad={this.handleScriptLoad}
          />
        <Grid container spacing={2} >

            <Grid item md={12} sm={12} style={{ width: "100%"}}>
                <TextField
                    id="d-toowlz"
                    name="d-toowlz"
                    placeholder="Address"
                    type="text"
                    className="textField"
                    size="small"
                    variant="outlined"
                    fullWidth
                    value={this.state.route}
                    onChange={this.handleChange}
                    autoComplete="nope"
                    onFocus={(event) =>
                                event.target.autocomplete = "whatever"
                                }
                    />
            </Grid>
            <Grid item md={6} sm={6} xs={6}>
                <TextField
                    id="apto"
                    name="apto"
                    placeholder="Apto"
                    type="text"
                    className="textField"
                    size="small"
                    value={this.state.apto}
                    variant="outlined"
                    fullWidth
                    />
            </Grid>
            {/* <Grid item md={9} sm={9} xs={9}>
                <TextField
                    id="route"
                    name="route"
                    placeholder="Route"
                    type="text"
                    className="textField"
                    size="small"
                    variant="outlined"
                    value={this.state.route}
                    fullWidth
                    disabled
                    />
            </Grid> */}
            {/* <Grid item md={6} sm={6} xs={6}>
                <TextField
                    id="neighborhood"
                    name="neighborhood"
                    placeholder="Neighborhood"
                    type="text"
                    className="textField"
                    size="small"
                    variant="outlined"
                    value={this.state.neighborhood}
                    fullWidth
                    disabled
                    />
            </Grid> */}
            {/* <Grid item md={6} sm={6} xs={6}>
                <TextField
                    id="locality"
                    name="locality"
                    placeholder="Locality"
                    type="text"
                    className="textField"
                    size="small"
                    variant="outlined"
                    value={this.state.locality}
                    fullWidth
                    disabled
                    />
            </Grid> */}
            <Grid item md={6} sm={8} xs={8}>
                <TextField
                    id="administrative_area_level_1"
                    name="administrative_area_level_1"
                    placeholder="State"
                    type="text"
                    className="textField"
                    size="small"
                    variant="outlined"
                    value={this.state.administrative_area_level_1}
                    fullWidth
                    disabled
                    />
            </Grid>
            <Grid item md={6} sm={4} xs={4}>
                <TextField
                    id="postal_code"
                    name="postal_code"
                    placeholder="Postal Code"
                    type="text"
                    className="textField"
                    size="small"
                    variant="outlined"
                    value={this.state.postal_code}
                    fullWidth
                    disabled
                    />
            </Grid>
            <Grid item md={6} sm={6} xs={6}>
                <TextField
                    id="country"
                    name="country"
                    placeholder="Country"
                    type="text"
                    className="textField"
                    size="small"
                    variant="outlined"
                    value={this.state.country}
                    fullWidth
                    disabled
                    />
            </Grid>
        </Grid>
        </div>
    );
  }
}

export default PlaceSearch;