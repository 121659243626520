import React, { Component, Fragment } from "react";

import { connect } from "react-redux";

// --- Post bootstrap -----
import AppFooter from "./modules/views/AppFooter";
import ProductHero from "./modules/views/ProductHero";
import ProductValues from "./modules/views/ProductValues";
import ProductHowItWorks from "./modules/views/ProductHowItWorks";
import HomeLayout from "./modules/views/HomeLayout";
import Navbar from "../components/layout/Navbar";
import FooterBar from "../components/layout/FooterBar";


class home extends Component {
  componentDidMount() {}

  render() {
    return (
      <Fragment>
        <Navbar isTransparent={true}/>
        <HomeLayout />
        <ProductHero />
        {/* <ProductValues /> */}
        <ProductHowItWorks />
        {/* <AppFooter /> */}
      </Fragment>
    );
  }
}

home.propTypes = {};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(home);
