import React, { Component } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash.isempty';

// examples:
import GoogleMap from './GoogleMap';
import GoogleMapReact from 'google-map-react';


// consts: [34.0522, -118.2437]
var CENTER = [34.0522, -118.2437];

// InfoWindow component
const InfoWindow = (props) => {
  const { place } = props;
  const infoWindowStyle = {
    position: 'relative',
    borderRadius:8,
    bottom: 25,
    left: '20px',
    width: 220,
    backgroundColor: 'white',
    boxShadow: '0 2px 7px 1px rgba(0, 0, 0, 0.3)',
    padding: 0,
    // fontSize: 14,
    zIndex: 100,
  };

  return (
    <div style={infoWindowStyle}>
      {place.name}     
    </div>
  );
};

// Marker component
const Marker = ({ show, place, it }) => {
  const markerStyle = {
    border: '1px solid white',
    borderRadius: '50%',
    height: 25,
    width: 25,
    backgroundColor: show ? 'lightgray' : 'gray',
    cursor: 'pointer',
    zIndex: 10,
  };

  return (
    <>
      <div style={markerStyle} >
      <div style={{ position:"relative", 
                    left:8, 
                    top:5,  
                    fontFamily: "Open Sans",
                    fontWeight: "bold",
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: 1,
                    letterSpacing: " 0.18px",
                    color: "#fff",}}>
        {it} 
      </div>
        
      </div>

      {show && <InfoWindow 
                    place={place} 
                    />}
    </>
  );
};

class MarkerInfoWindow extends Component {
  constructor(props) {
    super(props);

    console.log("Products Const",this.props.productList)
    this.state = {
      places: [],
      products: this.props.productList
    };
  }

  componentDidMount() {
    // fetch('places.json')
    //   .then((response) => response.json())
    //   .then((data) => {
    //     data.results.forEach((result) => {
    //       result.show = false; // eslint-disable-line no-param-reassign
    //     });
    //     this.setState({ places: data.results });
    //   });
  }

  // onChildClick callback can take two arguments: key and childProps
  onChildClickCallback = (key) => {
    this.setState((state) => {
      const index = state.products.findIndex((e) => e.toolId === key);
      state.products[index].address.show = !state.products[index].address.show; // eslint-disable-line no-param-reassign
      return { products: state.products };
    });
  };

  apiIsLoaded = (map,maps,products) => {
    const directionsService = new maps.DirectionsService();
    const directionsDisplay = new maps.DirectionsRenderer();
    const directionsRenderer = new maps.DirectionsRenderer();
    const start = `${products[0].address.geometry.location.lat}, ${products[0].address.geometry.location.lng}`;
    const end = `${products[products.length-1].address.geometry.location.lat}, ${products[products.length-1].address.geometry.location.lng}`;
    
    const waypts = [];

  for (let i = 0; i < products.length; i++) {
    const startW = `${products[i].address.geometry.location.lat}, ${products[i].address.geometry.location.lng}`;
      waypts.push({
        location: startW,
        stopover: true
      });
  }
    
    directionsService.route({
      origin: start,
      destination: end,
      waypoints: waypts,
      optimizeWaypoints: true,
      travelMode: 'DRIVING'
    }, (response, status) => {
      if (status === 'OK') {
        directionsDisplay.setDirections(response);
        directionsRenderer.setDirections(response);
        // directionsRenderer.setMap(map);
        const routePolyline = new window.google.maps.Polyline({
          path: response.routes[0].overview_path,
          strokeOpacity :0.8
        });
        routePolyline.setMap(map);
      } else {
        window.alert('Directions request failed due to ' + status);
        }
      });
};

  render() {
    const { products} = this.state;
    console.log("Products", products)
    !isEmpty(products)&& (
        CENTER = [products[0].address.geometry.location.lat, products[0].address.geometry.location.lng]
    )
    let it=0
    return (
      <div className="mapCheckout">
        {!isEmpty(products) && (
          <GoogleMap
            defaultZoom={12}
            defaultCenter={CENTER}
            bootstrapURLKeys={{ key: "AIzaSyDpdv91FCJxMvanW382mbvo1wpy14kiA-Q" }}
            // onChildMouseUp={this.onChildClickCallback}
            yesIWantToUseGoogleMapApiInternals 
            onGoogleApiLoaded={({ map, maps }) => this.apiIsLoaded(map, maps, products)}
          >
            
            {products.map((place) => (
              <Marker
                key={place.toolId}
                lat={place.address.geometry.location.lat}
                lng={place.address.geometry.location.lng}
                show={place.address.show}
                place={place}
                it={++it}
              />
            ))}
          </GoogleMap>
        )}
      </div>
    );
  }

}


InfoWindow.propTypes = {
    place: PropTypes.shape({
    name: PropTypes.string,
    formatted_address: PropTypes.string,
    rating: PropTypes.number,
    types: PropTypes.arrayOf(PropTypes.string),
    price_level: PropTypes.number,
    opening_hours: PropTypes.shape({
      open_now: PropTypes.bool,
    }),
  }).isRequired,
};

Marker.propTypes = {
  show: PropTypes.bool.isRequired,
  place: PropTypes.shape({
    name: PropTypes.string,
    formatted_address: PropTypes.string,
    rating: PropTypes.number,
    types: PropTypes.arrayOf(PropTypes.string),
    price_level: PropTypes.number,
    opening_hours: PropTypes.shape({
      open_now: PropTypes.bool,
    }),
  }).isRequired,
};

export default MarkerInfoWindow;