import React from "react";
import { Icon, Badge, IconButton, Drawer, Card } from "@material-ui/core";
import { ThemeProvider, withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { getTimeDifference } from "../../util/utils.js";


import {
  getNotification,
  deleteNotification,
  clearNotifications
} from "../../redux/actions/dataActions";

import NotificationsOutlinedIcon from "@material-ui/icons/NotificationsOutlined";
import DeleteForeverOutlinedIcon from "@material-ui/icons/DeleteForeverOutlined";

import formatDistance from "date-fns/formatDistance";
import differenceInDays from "date-fns/differenceInDays";

import { Button, Grid } from "@material-ui/core";

let cartListLoaded = false;

function Notifications(props) {
  const {
    container,
    theme,
    settings,
    notifications: notifcationList = [],
    getNotification,
    // deleteAllNotification,
    deleteNotification,
    clearNotifications,
    user,
    color,
  } = props;

  const [panelOpen, setPanelOpen] = React.useState(false);

  if (!cartListLoaded) {
    console.log(user);
    getNotification();
    cartListLoaded = true;
  }

  function handleDrawerToggle() {
    setPanelOpen(!panelOpen);
  }

  return (
    <ThemeProvider>
      <IconButton onClick={handleDrawerToggle} className={color}>
        <Badge color="secondary" badgeContent={notifcationList.length}>
          <NotificationsOutlinedIcon />
        </Badge>
      </IconButton>

      <Drawer
        width={"100px"}
        container={container}
        variant="temporary"
        anchor={"right"}
        open={panelOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true
        }}
      >
        <div className="mini-cart">
          <div className="cart__topbar">
            <NotificationsOutlinedIcon style={{ maxWidth: "30px" }} />
            <h5 style={{ minWidth: "200px", marginTop: "5px" }}>
              Notifications
            </h5>
            <Button color="inherit" onClick={clearNotifications}>
              Clear
            </Button>
          </div>
          <Grid container spacing={0}>
            <Grid item md={12}>
              <Grid container spacing={0} className="notification">
                <Grid item md={12}>
                {notifcationList.map(notification => (
            <div
              key={notification.id}
              className="notification__card position-relative"
            >
              <IconButton
                size="small"
                className="delete-button bg-transperant mr-10"
                onClick={() => deleteNotification(notification.id)}
              >
                <DeleteForeverOutlinedIcon style={{maxWidth: "24px" }}/>
              </IconButton>
                <Card className="mx-8 mt-8" elevation={3}>
                  <div className="card__topbar flex items-center justify-between p-2 bg-secondary">
                    <div className="flex items-center">
                      <div className="card__topbar__button">

                        <NotificationsOutlinedIcon 
                          fontSize="small"
                          style={{ maxWidth: "16px" }} />
                      </div>
                      <span className="ml-4 mt-4 font-medium text-white">
                        {notification.heading}
                      </span>
                    </div>
                  </div>
                  <div className="px-8 pt-4 pb-8">
                    <p className="m-0">{notification.title}</p>
                    <small className="text-muted">
                      {notification.subtitle}
                    </small>
                  </div>
                </Card>
            </div>
          ))}
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {/* {cartList.map(product => (
            <div
              key={product.id}
              className="mini-cart__item"
            >
              <div  style={{ marginRight: '2px' }}>
                <img src={product.image} alt="..." />
              </div>
              <div style={{ marginLeft: '3px' , textAlign: 'left' }} >
                <h6 style={{ marginBottom: '2px' }}>{product.name}</h6>
                <small className="text-muted">
                  price: {product.price}
                </small>
              </div>

              <IconButton
                size="small"
                onClick={() => deleteProductFromCart(product.productId)}
              >
                <DeleteForeverOutlinedIcon fontSize="small"/>
              </IconButton>
            </div>
          ))} */}
        </div>
      </Drawer>
    </ThemeProvider>
  );
}

Notifications.propTypes = {
  // settings: PropTypes.object.isRequired,
  notifications: PropTypes.array.isRequired
};

const mapStateToProps = (state) => ({
  user: state.user,
  getNotification: PropTypes.func.isRequired,
  deleteNotification: PropTypes.func.isRequired,
  clearNotifications: PropTypes.func.isRequired,
  notifications: state.data.notificationList,
});

export default withStyles(
  {},
  { withTheme: true }
)(
  connect(mapStateToProps, {
    getNotification,
    deleteNotification,
    clearNotifications,
  })(Notifications)
);
