import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  getTool,
  addProductToCart,
  productsCart,
  deleteProductFromCart,
  // stripeTokenHandler
} from "../redux/actions/dataActions";

import formatDistance from 'date-fns/formatDistance'
import differenceInDays from 'date-fns/differenceInDays'

import Navbar from "../components/layout/Navbar";

import CheckoutForm from "./CheckoutForm";
import Payment from "./Payment"


import withStyles from "@material-ui/core/styles/withStyles";

// MUI Stuff
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {
  Box,
  TextField,
  IconButton,
} from "@material-ui/core";

import DeleteForeverOutlinedIcon from "@material-ui/icons/DeleteForeverOutlined";

import Rating from "@material-ui/lab/Rating";

import PlaceSearch from "../components/search/PlaceSearch";


import axios from "axios";

class PlaceOrder extends React.Component {
  constructor() {
    super();
    this.state = {
      user: {},
      email: "",
      password: "",
      profile: false,
      errors: {},
      image: "",
      toolId: "",
      price: 0,
      userHandle:""
      // stripe: false
    };
  }

  handleSuccessCheckout(data){
    window.location.href = `/confirm/${data}`
  }

  componentWillMount() {
    // const profileId = this.props.match.params.profileId;
    // let toolId = null;

    // if (profileId) {
    //   this.setState({ profile: true });
    //   toolId = this.props.match.params.toolId;
    // } else {
    //   this.setState({ profile: false });
    //   toolId = this.props.match.params.id;
    // }
    // this.setState({toolId: toolId});
    // this.props.getTool(toolId);
    this.props.productsCart();

    // const stripe = this.props.useStripe();

    //   if (stripe) {
    //   this.setState({ stripe: stripe });
    // }

  }

  componentWillReceiveProps(nextProps) {
    // if (nextProps.tool) {
    //   this.setState({ image: nextProps.tool.toolImageList[0] });
    //   this.setState({ name: nextProps.tool.name });
    //   this.setState({ price: nextProps.tool.price });
    // }
  }

  // handleSubmit = async (event) => {
  //   event.preventDefault();
    
  //   const stripe = this.state.stripe;
  //   const elements = useElements();

  //   const { error, paymentMethod } = await stripe.createPaymentMethod({
  //     type: "card",
  //     card: elements.getElement(CardElement)
  //   });

  //   if (!error) {
  //     const { id } = paymentMethod;

  //     try {
  //       const { data } = await axios.post("/charge", { id, amount: 1099 });
  //       console.log(data);
  //      // success();
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   }
  // };

  // handleSubmitPayment = async (event) => {
  //   // We don't want to let default form submission happen here,
  //   // which would refresh the page.
  //   event.preventDefault();

  //   console.log("Payment")

  //   const {elements} = this.props

  //   // if (!stripe || !elements) {
  //   //   // Stripe.js has not yet loaded.
  //   //   // Make  sure to disable form submission until Stripe.js has loaded.
  //   //   return;
  //   // }

  //   // const card = elements.getElement(CardElement);
  //   // const result = await stripe.createToken(card);

  //   if (result.error) {
  //     // Show error to your customer.
  //     console.log(result.error.message);
  //   } else {
  //     // Send the token to your server.
  //     // This function does not exist yet; we will define it in the next step.
  //     this.props.stripeTokenHandler(result.token);
  //   }
  // };

  getTotalCost = () => {
    let { cartList = [] } = this.props;
    let totalCost = 0;
    cartList.forEach(product => {
      totalCost += product.amount * product.price * 
      differenceInDays(new Date(product.endDate), new Date(product.startDate));    });
    return totalCost;
  };

  setAddress = (value) => {
    this.setState({ address: value });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    console.log(this.state.toolId);

    const productData = {
      productId: this.state.toolId,
      price: this.state.price,
      imageURL: this.state.image,
      name: this.state.name,
      owner: this.state.userHandle
    };
    this.props.addProductToCart(productData);
  };

  changeImage = (event) => {
    event.preventDefault();
    this.setState({ image: event.target.src });
  };

  handleShipping = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  render() {
    //const stripePromise = loadStripe("pk_test_OZIu41A46gg7X1bNqrFqRv0Z00wNbhrBvL");

    //const options = useOptions();

    let height = "205px";

    let { classes, tool, cartList } = this.props;
    let { profile, zip, email, address, city, state } = this.state;
    let total=0;

    const shipping = {
      line1: address,
      email: email,
      zip: zip,
      city: city,
      state: state
    }; 
    // console.log(this.props.stripe);

    // if (tool.toolId) {
    return (
     <Payment></Payment>
    );
    // } else {
    //   return (
    //     <Backdrop>
    //       <CircularProgress color="secondary" />
    //     </Backdrop>
    //   );
    // }
  }
}

// function InjectedCheckoutForm() {
//   return (
//     <ElementsConsumer>
//       {({stripe, elements}) => (
//         <PlaceOrder  stripe={stripe} elements={elements} />
//       )}
//     </ElementsConsumer>
//   );
// }

function mapStateToProps(state) {
  return {
    tool: state.data.tool,
    cartList: state.data.cartList,
  };
}
const mapActionsToProps = {
  getTool,
  addProductToCart,
  productsCart,
  deleteProductFromCart,
  // stripeTokenHandler,
  // useStripe,
};

PlaceOrder.propTypes = {
  tool: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  profile: PropTypes.bool.isRequired,
};

const styles = (theme) => ({
  card: {
    padding: "30px 67px",
  },
  media: {
    height: 0,
    paddingTop: "480px",
    backgroundSize: "contain", // or cover
  },
  rating: {
    width: 200,
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {      
      display: "none",
    }
  },
  desktop:{
    display: "block",
    [theme.breakpoints.down("sm")]: {      
      display: "none",
    },
  },

  mobile:{
    display: "none",
    [theme.breakpoints.down("sm")]: {      
      display: "block",
    },
  },
  title: {
    maxWidth: "270px",
    fontFamily: "Open Sans",
    fontSize: "24px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1,
    letterSpacing: " 0.18px",
    color: "#000000",
  },
  subtitle: {
    fontFamily: "Open Sans",
    fontSize: "16px",
    fontWeight: "600",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.5",
    letterSpacing: "0.15px",
    color: "#000000",
    marginRight: "5px",
  },
  description: {
    fontFamily: "Open Sans",
    fontSize: "16px",
    fontWeight: "600",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.5",
    letterSpacing: "0.15px",
  },
  text: {
    fontFamily: "Open Sans",
    fontSize: "16px",
    fontWeight: "600",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.5",
    letterSpacing: "0.15px",
  },
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    flexWrap: "nowrap",
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: "translateZ(0)",
  },
  titleBar: {
    background:
      "linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
  },
  main: {
    display: "flex",
  },
  left: {
    position: "absolute",
    right: 180,
  },
});

export default connect(
  mapStateToProps,
  mapActionsToProps
)(withStyles(styles)(PlaceOrder));
