import React from "react";
import {
  Grid,
  TablePagination,
  Card
} from "@material-ui/core";


import Chip from '@material-ui/core/Chip';
import Paper from '@material-ui/core/Paper';

import { makeStyles } from "@material-ui/core/styles";

import GridProductCard from "./GridProductCard";
import ToolsGoogleMap from "../components/rental/ToolsGoogleMap";

import AdSense from 'react-adsense-ad';

import * as _ from "lodash";

import ads from "../images/google-ads-logo.svg";


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'left',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0),
    margin: 1,
  },
  ads: {
    height: "100%",
    paddingTop: "0px",
    paddingLeft: "0px",
    paddingRight: "0px",
    paddingBottom: "0px",
    boxShadow: "0 7px 64px 0 rgba(0, 0, 0, 0.07)",
    // backgroundImage: `url(${ads})`,
    // objectFit: "contain",
    // backgroundSize: 'auto',
    // backgroundRepeat: "no-repeat",
    [theme.breakpoints.down("sm")]: {
       minWidth: 315,
    }
  },
  fakeAds: {
    backgroundImage: `url(${ads})`,
  },
  desktop:{
    display:"flex",
    [theme.breakpoints.down("sm")]: {
      display:"none", 
    }
  },
  grid:{
    padding:"0px", 
    [theme.breakpoints.down("sm")]: {
      marginLeft:"8px", 
    }
  },
  marginR: {
    marginRight: 0,
  },
  chip: {
    padding: theme.spacing(0),
    margin: theme.spacing(0.5),
    fontFamily: "Open Sans",
    fontSize: "12px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: " normal",
    lineHeight: "1.2",
    letterSpacing: "0.1px",
    color: "#217cbd",
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9,
  },
  rating: {
    width: 200,
    display: "flex",
    alignItems: "center",
  },
  input: {
    padding: 7,
    fontFamily: "Open Sans",
    fontSize: "12px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: " normal",
    lineHeight: "1.2",
    letterSpacing: "0.1px",
    color: "darkgray",
    alignItems: "left",
    justifyContent: "left",
    textAlign: "left",
    maxWidth: "129px"
  },
}));


const ShopContainer = ({
  orderBy,
  view,
  productList,
  chipData,
  page,
  rowsPerPage,
  product,
  toggleSidenav,
  toggleView,
  handleDelete,
  handleChange,
  handleChangePage,
  setRowsPerPage,
  startDate,
  endDate
}) => {
  const classes = useStyles();

  let count =0;

  return (
    <Grid container spacing={0}>

      <Grid item sm={12} >
        <div className="spaced__item">
          <div className={classes.desktop}>
          <Paper elevation="0" component="ul" className={classes.root}>
            {chipData.map((data) => {
              let icon;

              return (
                <li key={data.key}>
                  <Chip
                    icon={icon}
                    label={data.label}
                    onDelete={data.label === 'Reset' ? handleDelete : undefined }
                    className={classes.chip}
                  />
                </li>
              );
            })}
          </Paper>
          </div>
        </div>
      </Grid>

      <Grid item sm={12} style={{ marginTop: "0px"}}
        >
        <Grid container spacing={4}>
          
          {
            view === "grid" ? (
            _.orderBy(productList, orderBy !== "false" ? "price" : "", orderBy)
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((product,key) => (
                <>
                {Math.floor(Math.random() * (rowsPerPage - 0) + 0) === key? (
                  <>
                  <Grid item key={product.toolId} lg={3} md={3} sm={12} xs={12}>

                    <Paper className={classes.ads} elevation={0} >
                      <img alt="Ads" src={ads} style={{
                                                        display: "block",
                                                        marginLeft: "auto",
                                                        marginRight: "auto",
                                                        padding: "0px",
                                                        borderRadius: "4px",
                                                        width: "auto",
                                                        height: "auto",
                                                    }}></img>
                    </Paper>

                    {/* <AdSense.Google
                        client='Your Publisher Id'
                        slot='Ad slot Id'
                        style={{ width: 150, height: 200, float: 'left' }}
                        format=''
                      /> */}
                  </Grid>
                  <Grid item key={product.toolId} lg={3} md={3} sm={12} xs={12}>
                    <GridProductCard 
                      product={product}
                      endDate= {endDate}
                      startDate={startDate}
                    ></GridProductCard>
                  </Grid>
                  </>
                ): (
                  <Grid item key={product.toolId} lg={3} md={3} sm={12} xs={12}>
                    <GridProductCard 
                      product={product}
                      endDate= {endDate}
                      startDate={startDate}
                    ></GridProductCard>
                  </Grid>
                )}
                </>
              ))
          ) : (
            <Grid item key={"mpa"} lg={12} md={12} sm={12} xs={12}>
              <ToolsGoogleMap productList={productList} endDate= {endDate} startDate={startDate}></ToolsGoogleMap>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item sm={12} style={{padding:"0 20"}}>
        <TablePagination
          rowsPerPageOptions={[6, 12, 24]}
          component="div"
          count={productList.length}
          rowsPerPage={rowsPerPage}
          page={page}
          labelRowsPerPage="Rows"
          backIconButtonProps={{
            "aria-label": "Previous Page",
          }}
          nextIconButtonProps={{
            "aria-label": "Next Page",
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={setRowsPerPage}
        />
      </Grid>
    </Grid>
  );
};

export default ShopContainer;
