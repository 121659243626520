import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";

import backgroundImage1 from "../../../images/Frame131.png";
import backgroundImage2 from "../../../images/Frame132.png";
import backgroundImage3 from "../../../images/Frame133.png";
import backgroundImage4 from "../../../images/Frame134.png";
import backgroundImage5 from "../../../images/Frame135.png";

const styles = (theme) => ({
  root: {
    display: "flex",
    backgroundColor: theme.palette.white,
    overflow: "hidden",
    
  },
  container: {
    marginTop: theme.spacing(38),
    marginBottom: theme.spacing(2),
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "left",
    padding: "4",

    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(0),
    }
  },
  item: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    minWidth:"206px",
    
    [theme.breakpoints.down("sm")]: {
      flexDirection: "row",
      minWidth:"300px",
    }
  },
  h5: {
    marginBottom: theme.spacing(2.5),
    // marginTop: theme.spacing(22),
    marginLeft:"0px",
    marginRight:"0px",
    padding: theme.spacing(0),
    textAlign:"center",
    margin:"0 auto",
    alignItems: "center",
    fontSize: 48,
    fontFamily: "Open Sans",
    fontWeight: 600,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.17,
    letterSpacing: "normal",
    color: "rgba(0, 0, 0, 0.87)",

    [theme.breakpoints.down("sm")]: {
      fontSize: 34,
      lineHeight: 1.06,
    }
  },

  products:{
    display: "flex",  
    justifyContent: "space-between",
    alignItems: "center",
    transition: "background 300ms ease",
    padding: "0px",
    [theme.breakpoints.down("sm")]: {
      display: "inline",  
    }
  },
  number: {
    fontFamily: "Open Sans",
    fontSize: 18,
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.2,
    letterSpacing: "0.10",
    color: "rgba(0, 0, 0, 0.87)",
    marginTop: theme.spacing(2),
    
  },
  image: {
    marginTop: theme.spacing(2),
    maxWidth:"150px",
    maxHeight:"150px",

    [theme.breakpoints.down("sm")]: {
      maxWidth:"85px",
      maxHeight:"85px",
      marginRight: theme.spacing(3),
    }

  },
  curvyLines: {
    pointerEvents: "none",
    position: "absolute",
    top: -180,
    opacity: 0.7,
  },
  button: {
    marginTop: theme.spacing(8),
  },
});

function ProductValues(props) {
  const { classes } = props;

  return (
    <section className={classes.root}>
      <Container className={classes.container}>
        <Typography
          color="inherit"
          align="left"
          variant="h4"
          marked="left"
          className={classes.h5}
        >
          Popular Categories
        </Typography>
        <div className={classes.products}>
              <div className={classes.item}>
                <img
                  className={classes.image}
                  src={backgroundImage1}
                  alt="suitcase"
                />
                <div className={classes.number}>Lawn & Garden</div>
              </div>
              <div className={classes.item}>
                <img
                  className={classes.image}
                  src={backgroundImage2}
                  alt="suitcase"
                />
                <div className={classes.number}>Power Tools</div>
              </div>
              <div className={classes.item}>
                <img
                  className={classes.image}
                  src={backgroundImage3}
                  alt="suitcase"
                />
                <div className={classes.number}>Ladders & Scaffolding</div>
              </div>
              <div className={classes.item}>
                <img
                  className={classes.image}
                  src={backgroundImage4}
                  alt="suitcase"
                />
                <div className={classes.number}>Cordless Tools</div>
              </div>
              <div className={classes.item}>
                <img
                  className={classes.image}
                  src={backgroundImage5}
                  alt="suitcase"
                />
                <div className={classes.number}>Heavy Equipment</div>
              </div>
        </div>
      </Container>
    </section>
  );
}

ProductValues.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProductValues);
