import React, { Component } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import clsx from "clsx";

// MUI Stuff
import Button from "@material-ui/core/Button";
import { Card, CardContent, Grid, TextField } from "@material-ui/core";

import Autocomplete from "@material-ui/lab/Autocomplete";

import LocationOnIcon from "@material-ui/icons/LocationOn";
import SearchIcon from "@material-ui/icons/Search";

import DateFnsUtils from "@date-io/date-fns"; // import

import { compareAsc, format, addDays, formatDistance, formatRelative, subDays } from 'date-fns'


import MomentUtils from "@date-io/moment";
import DateUtils from "@date-io/moment";



import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  TimePicker,
  DatePicker
} from "@material-ui/pickers";

//Redux
import { connect } from "react-redux";

import {
  getProductNameList,
  getCategoryList,
  getBrandList,
} from "../../redux/actions/dataActions";

class SearchTool extends Component {
  constructor() {
    super();
    this.state = {
      errors: {},
      productNameList: [],
    };
  }
  componentDidMount() {
    this.props.getProductNameList();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.UI.errors) {
      this.setState({ errors: nextProps.UI.errors });
    }
  }

  handleSubmit = (event) => {
    event.preventDefault();
    let name = this.state.productName ? this.state.productName : "";
    let zip = this.state.zip ? this.state.zip : "";
    let start = this.state.from ? this.state.from : new Date();
    let end = this.state.to ? this.state.to : addDays(new Date(), 1) ;

    let from = format(start, 'MM/dd/yyyy')
    let to = format(end, 'MM/dd/yyyy')

    window.location.href = `/search?name=${name}&startDate=${start}&endDate=${end}&zip=${zip}`;
  };

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleChangeDateFrom = (date) => {
    this.setState({
      from: date,
    });
  };

  handleChangeDateTo = (date) => {
    this.setState({
      to: date,
    });
  };

  setName = (value) => {
    this.setState({ value: value });
    this.setState({ productName: value.name });
  };

  render() {
    const {
      classes,
      UI: { loading },
      productNameList,
    } = this.props;
    const { errors, value } = this.state;

    return (
      <form noValidate onSubmit={this.handleSubmit} spacing={5}>
        <Card className={classes.card} elevation={0}>
          <CardContent className={classes.cardcontent}>
            <Grid container spacing={1} alignItems="flex-end">
              <Grid item lg={3} md={3} sm={12} xs={12} className={classes.item}>
                <Grid container spacing={0} alignItems="flex-end">
                  {/* <Grid item>
                    <SearchIcon
                      style={{ fill: "gray" }}
                      className={classes.marginIcon}
                    />
                  </Grid> */}
                  <Grid item>
                    <Autocomplete
                      name="product"
                      fullWidth
                      value={value}
                      onChange={(event, newValue) => {
                        this.setName(newValue);
                      }}
                      id="controllable-states-demo"
                      disableUnderline={true}
                      options={productNameList}
                      className={classes.textField}
                      helperText={errors.name}
                      error={errors.name ? true : false}
                      getOptionSelected={(option, value) =>
                        option.name === value.name
                      }
                      getOptionLabel={(option) => option.name}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="product"
                          className={classes.margin}
                          label="Product name"
                          disableUnderline={true}
                          // variant="outlined"
                          size="small"
                          fullWidth
                          InputLabelProps={{
                            classes: {
                              root: classes.cssLabel,
                              focused: classes.cssFocused,
                            },
                          }}
                          InputProps={{
                            ...params.InputProps,
                            classes: {
                              root: classes.cssOutlinedInput,
                              focused: classes.cssFocused,
                              notchedOutline: classes.notchedOutline,
                              input: classes.resizeLocation,
                            },
                          }}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item lg={2} md={2} sm={3} xs={6} className={clsx(classes.itemDate, classes.seperator)}
              >
                <MuiPickersUtilsProvider
                  utils={DateFnsUtils}
                  // utils={DateUtils}
                  className={classes.margin}
                >
                  <KeyboardDatePicker
                    disableToolbar={true}
                    name="from"
                    variant="inline"
                    // inputVariant="outlined"
                    autoOk
                    format="MM/dd/yyyy"
                    id="date-picker-inline"
                    label="Pick-up"
                    value={this.state.from ? this.state.from : null}
                    className={classes.margin}
                    onChange={this.handleChangeDateFrom}
                    size="small"
                    minDate={new Date()}
                    InputLabelProps={{
                      classes: {
                        root: classes.cssLabel,
                        focused: classes.cssFocused,
                      },
                    }}
                    InputProps={{
                      classes: {
                        root: classes.cssOutlinedInput,
                        focused: classes.cssFocused,
                        notchedOutline: classes.notchedOutline,
                        input: classes.resizeDate,
                      },
                      inputMode: "numeric",
                    }}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item lg={2} md={2} sm={3}  xs={6} className={clsx(classes.item, classes.seperator)}
              >
                <MuiPickersUtilsProvider
                  utils={DateFnsUtils}
                  className={classes.margin}
                >
                  <KeyboardDatePicker
                    disableToolbar={true}
                    name="to"
                    variant="inline"
                    // inputVariant="outlined"
                    autoOk
                    format="MM/dd/yyyy"
                    id="date-picker-inline"
                    label="Drop-off"
                    value={this.state.to ? this.state.to : null}
                    className={classes.margin}
                    onChange={this.handleChangeDateTo}
                    minDate={this.state.from}
                    size="small"
                    InputLabelProps={{
                      classes: {
                        root: classes.cssLabel,
                        focused: classes.cssFocused,
                      },
                    }}
                    InputProps={{
                      classes: {
                        root: classes.cssOutlinedInput,
                        focused: classes.cssFocused,
                        notchedOutline: classes.notchedOutline,
                        input: classes.resizeDate,
                      },
                      inputMode: "numeric",
                    }}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item lg={2} md={2} sm={12} xs={12} className={clsx(classes.item, classes.seperator)}
              >
                <Grid container spacing={0} >
                  <Grid item lg={1} md={1} sm={1} xs={1}>
                    <LocationOnIcon
                      style={{ fill: "gray" , marginTop:"13px", paddingTop:"4px", fontSize: 24}}
                    />
                  </Grid>
                  <Grid item lg={11} md={11} sm={11} xs={11}>
                    <TextField
                      className={classes.margin}
                      id="outlined-helperText"
                      // type="number"
                      fullWidth
                      label="Zip Code"
                      // variant="outlined"
                      style={{marginLeft:"8px", marginTop:"3px"}}
                      name="zip"
                      onChange={this.handleChange}
                      size="small"
                      InputLabelProps={{
                        classes: {
                          root: classes.cssLabel,
                          focused: classes.cssFocused,
                        },
                      }}
                      InputProps={{
                        classes: {
                          root: classes.cssOutlinedInput,
                          focused: classes.cssFocused,
                          notchedOutline: classes.notchedOutline,
                          input: classes.resizeLocation,
                        },
                        inputMode: "numeric",
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item lg={3} md={3} sm={12} xs={12}>
                <Button
                  color="secondary"
                  className={classes.button}
                  variant="contained"
                  type="submit"
                  fullWidth
                  startIcon={<SearchIcon />}
                >
                  Find tools
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </form>
    );
  }
}

SearchTool.propTypes = {
  classes: PropTypes.object.isRequired,
  UI: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  UI: state.UI,
  productNameList: state.data.productNameList,
  getProductNameList: PropTypes.func.isRequired,
});

const mapActionsToProps = {
  getProductNameList,
};

const styles = (theme) => ({
  '@global': {
    '.MuiOutlinedInput-root': {
      backgroundColor: "fff",
    },
  },
  button: {
    borderRadius: 5,
    //maxWidth: 200,
    height: "60px",
    fontSize: 12,
    //margin: theme.spacing(0),
    [theme.breakpoints.down("sm")]: {
      height: "42px",
    }
  },
  seperator: {
    borderLeft: "solid 1px",
    [theme.breakpoints.down("sm")]: {
      borderLeft: "solid 0px",
    }
  },
  item: {
    padding: theme.spacing(0),
    //marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1.6),
    borderColor: "rgba(0, 0, 0, 0.12)",
  },
  itemDate: {
    padding: theme.spacing(0),
    //marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1.6),
    borderColor: "rgba(0, 0, 0, 0.12)",
  },
  textField:{
    minWidth: 250,
    [theme.breakpoints.down("sm")]: {
       minWidth: "310px",
    }

  },
  card: {
    //paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(0),
    //paddingBottom: theme.spacing(0.6),
    // maxHeight: "60px",
    boxShadow: "0 4px 32px 0 rgba(0, 0, 0, 0.12)",

    [theme.breakpoints.down("sm")]: {
      // maxHeight: "600px",

    }
  },
  cardcontent: {
    padding: 0,
    "&:last-child": {
      paddingBottom: 0,

      [theme.breakpoints.down("sm")]: {
        paddingBottom: 16,
      }
    },
    [theme.breakpoints.down("sm")]: {
      padding: 16,
    }
  },
  margin: {
    margin: theme.spacing(0),
    backgroundColor: "fff",
    paddingRight: theme.spacing(0),
    paddingLeft: theme.spacing(0),
  },
  marginTop: {
    marginTop: theme.spacing(1),
    paddingRight: theme.spacing(0),
    paddingLeft: theme.spacing(0),
  },
  marginIcon: {
    margin: theme.spacing(0),
    paddingRight: theme.spacing(0),
    paddingLeft: theme.spacing(2),
  },

  resizeDate: {
    fontSize: 14,
  },

  resizeLocation: {
    fontSize: 14,
  },

  cssLabel: { fontSize: 14 },

  cssOutlinedInput: {
    "&$cssFocused $notchedOutline": {
      borderColor: `transparent !important`,
      backgroundColor:"fff"
    },
    backgroundColor:"fff"
  },

  cssFocused: {
    backgroundColor: "fff"
  },

  notchedOutline: {
    borderWidth: "0px",
    borderColor: "fff !important",
    backgroundColor:"fff",
  },
});

export default connect(
  mapStateToProps,
  mapActionsToProps
)(withStyles(styles)(SearchTool));
