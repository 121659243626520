import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
// MUI Stuff
import Link from "@material-ui/core/Link";

// Redux stuff
import { connect } from "react-redux";
import {
  clearErrors,
} from "../../redux/actions/dataActions";

import AddTool from "./AddTool";

class PostTool extends Component {
  state = {
    open: false,
    active: true,
    errors: {},
    handle: "",
    authenticated: false,
  };

  static defaultProps = {
    classes: PropTypes.object.isRequired,
  };

  fileObj = [];
  fileArray = [];
  stepper = "";

  componentWillReceiveProps(nextProps) {
    if (nextProps.UI.errors) {
      console.log("Errors:", nextProps.UI.errors);
      this.setState({ errors: nextProps.UI.errors });
    }

    if (nextProps.user.authenticated) {
      this.setState({ authenticated: nextProps.user.authenticated});
    }
  }

  handleOpen = () => {
    ! this.state.authenticated ? (window.location.href = '/login'): (
    this.setState({ open: true }))
  };
  handleClose = () => {
    this.setState({ open: false });
  };

  // Handle fields change
  handleChange = (input) => (e) => {
    this.setState({ [input]: e.target.value });
  };

  render() {
    const {
      open,
      active,
      errors,
      handle,
      toolId,
    } = this.state;
    const values = {
      active,
      open,
      handle,
      errors,
      toolId,
    };
    const { classes } = this.props;

    this.stepper = (
      <AddTool 
        close={this.handleClose} 
        values={values} />
    );

    return (
      <Fragment>
        <Link
          tip="TOOL"
          className={classes.button}
          onClick={this.handleOpen}
          color="inherit"
        >
          Tool
        </Link>

        {this.stepper}
      </Fragment>
    );
  }
}

const styles = (theme) => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  button: {
    fontFamily: "Open Sans",
    fontSize: 14,
    fontWeight: 600,
    fontStretch: "normal",
    fontStyle: "normal",
    letterSpacing: "1.25px",
    marginRight: 22,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textTransform: "uppercase",
    cursor: "pointer",
    textDecoration: "none",
    "&:hover": {
      textDecoration: "none",
    },
  },
});

PostTool.propTypes = {
  clearErrors: PropTypes.func.isRequired,
  UI: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  UI: state.UI,
  user: state.user,
  authenticated: state.user.authenticated,
});
const mapActionsToProps = { clearErrors };

export default connect(
  mapStateToProps,
  mapActionsToProps
)(withStyles(styles)(PostTool));
