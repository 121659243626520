import React, { Component } from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import {
  Card,
  Grid,
  CardActionArea,
  CardMedia,
} from "@material-ui/core";
import AddPhoto from "../../images/AddPhoto.png";


import { connect } from "react-redux";
import { uploadImage } from "../../redux/actions/dataActions";



class MultipleImageUploadComponent extends Component {

    fileObj = [];
    fileArray = [];

    constructor(props) {
        super(props)
        this.state = {
            imageUrl: "",
            file: [null]
        }
        this.uploadMultipleFiles = this.uploadMultipleFiles.bind(this)
        this.uploadFiles = this.uploadFiles.bind(this)
    }

    componentWillReceiveProps(nextProps) {
        console.log(nextProps.data);
        if (nextProps.UI.errors) {
          this.setState({ errors: nextProps.UI.errors });
        }
        if (nextProps.data.toolImage) {
          console.log("Set tool Image");
          this.setState({ imageUrl: nextProps.data.toolImage });
        }
      }

    uploadMultipleFiles(e) {
      const formData = new FormData();

        this.fileObj.push(e.target.files)
        for (let i = 0; i < this.fileObj[0].length; i++) {
            this.fileArray.push(URL.createObjectURL(this.fileObj[0][i]))

            console.log(this.fileObj[0][i].name);

            formData.append("image" + i, this.fileObj[0][i], this.fileObj[0][i].name);

        }
        this.setState({ file: this.fileArray })

        const image = e.target.files[0];
        //const formData = new FormData();
        //formData.append("image", image, image.name);

        console.log(formData);

        this.props.uploadImage(formData);
    }

    uploadFiles(e) {
        e.preventDefault()
        console.log(this.state.file)
    }

    render() {
        const {
            classes,
            data:{
              toolImageList
            }
          } = this.props;

        return (
            <form>
                <div className="form-group multi-preview">
                    {(this.fileArray || []).map(image => (
                        <img src={`${image}`} alt="..." />
                    ))}
                    <label className={classes.label} htmlFor="icon-button-file">
                        <img src={AddPhoto} alt="..." />
                    </label>
                </div>

                <input 
                    accept="image/*" 
                    className={classes.input} 
                    id="icon-button-file" 
                    type="file" 
                    onChange={this.uploadMultipleFiles}
                    multiple
                />
            </form >
        )
    }
}

MultipleImageUploadComponent.propTypes = {
    classes: PropTypes.object.isRequired,
    uploadImage: PropTypes.func.isRequired,
    data: PropTypes.object.isRequired,
    toolImage: PropTypes.object.isRequired,
  };

  const mapActionsToProps = {
    uploadImage,
  };

const mapStateToProps = (state) => ({
    UI: state.UI,
    data: state.data,
    toolImageList: state.data.toolImageList,
  });

  const styles = (theme) => ({
    media: {
      height: 0,
      paddingTop: "56.25%", // 16:9,
      backgroundSize: "contain" // or cover
    },
    root: {
        '& > *': {
          margin: theme.spacing(1),
        },
      },
      input: {
        display: 'none',
      },
      label:{
          margin:'0px',
          padding:'0px'
      },
      button: {
        height: '100px',
        marginTop:'0px',
        padding: '5px',
      },
  });

  export default connect(mapStateToProps,mapActionsToProps)(
    withStyles(styles)(MultipleImageUploadComponent)
  );