import React, { Component } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import PropTypes from "prop-types";
import DeleteTool from "./DeleteTool";
// MUI Stuff
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardMedia from "@material-ui/core/CardMedia";

// Redux
import { connect } from "react-redux";
import { deleteTool } from "../../redux/actions/dataActions";

const styles = {
  root: {
    maxWidth: 345,
    padding: "0px",
  },
  card: {
    maxHeight: 230,
    padding: "0px",
  },
  media: {
    backgroundSize: "contain" // or cover
  },
};

class ToolImage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      tool: this.props.tool,
    };
  }

  deleteTool = () => {
    this.props.deleteTool(this.props.tool.toolId);
    this.setState({ open: false });
  };

  render() {
    dayjs.extend(relativeTime);
    const {
      classes,
    } = this.props;

    const {
      tool
    } = this.state

    console.log(tool);
    return (
      <Grid item sm={3}>
        {/* <Card >
          <Link
          className="rental-detail-link"
          href={`/users/${tool.userHandle}/tool/${tool.toolId}`}
          >
          <CardActionArea>
              <CardMedia
                // className={classes.media}
                image={tool.toolImageList[0]}
                title={tool.title}
              />
              <div className="image-box__overlay">
                <DeleteTool toolId={tool.toolId} />
              </div>
          </CardActionArea>
          </Link>
        </Card> */}



      <Card className={classes.card}>
      <Link
        className="rental-detail-link"
        href={`/users/auth/tool/${tool.toolId}`}
      >
        <div className="product__image-box flex justify-center items-center position-relative">
          <img className={classes.media} src={tool.toolImageList[0]} alt={tool.tittle} />
          <div className="image-box__overlay">
            <DeleteTool toolId={tool.toolId} />
          </div>
        </div>
      </Link>
      </Card>
      </Grid>
    );
  }
}

ToolImage.propTypes = {
  tool: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  deleteTool: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
});

export default connect(mapStateToProps, { deleteTool })(
  withStyles(styles)(ToolImage)
);
