import React, { Fragment , useMemo } from "react";
import { useState } from "react";

import { makeStyles } from "@material-ui/core/styles";

import PropTypes from "prop-types";
// import { connect } from "react-redux";
import { connect } from "react-redux";
import {
  getTool,
  addProductToCart,
  productsCart,
  deleteProductFromCart,
  // stripeTokenHandler
} from "../redux/actions/dataActions";

import formatDistance from 'date-fns/formatDistance'
import differenceInDays from 'date-fns/differenceInDays'

import Navbar from "../components/layout/Navbar";

import CheckoutForm from "./CheckoutForm";


import withStyles from "@material-ui/core/styles/withStyles";

// MUI Stuff
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {
  Box,
  TextField,
  IconButton,
} from "@material-ui/core";

import DeleteForeverOutlinedIcon from "@material-ui/icons/DeleteForeverOutlined";

import Rating from "@material-ui/lab/Rating";

import PlaceSearch from "../components/search/PlaceSearch";


import axios from "axios";

import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";

// import axios from "axios";

 import CheckoutError from "./CheckoutError";

// MUI Stuff
// import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


const useOptions = () => {
  const options = useMemo(
    () => ({
      style: {
        base: {
          fontSize: "20px",
          color: "#424770",
          letterSpacing: "0.010em",
          fontWeight: "100",
          fontStretch: "normal",
          fontStyle: "normal",
          // lineHeight: "1.5",
          fontFamily: "Source Code Pro, monospace",
          "::placeholder": {
            color: "#aab7c4"
          }
        },
        invalid: {
          color: "#9e2146"
        }
      }
    }),
  );

  return options;
};


const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,
  },
  rating: {
    width: 200,
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {      
      display: "none",
    }
  },
  desktop:{
    display: "block",
    [theme.breakpoints.down("sm")]: {      
      display: "none",
    },
  },

  mobile:{
    display: "none",
    [theme.breakpoints.down("sm")]: {      
      display: "block",
    },
  },
  chip: {
    padding: theme.spacing(0),
    margin: theme.spacing(0),
    fontFamily: "Open Sans",
    fontSize: "12px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: " normal",
    lineHeight: "1.2",
    letterSpacing: "0.1px",
    color: "#217cbd",
    maxHeight:"22px"
  },
  media: {
    height: 0,
    paddingTop: "100%",//"56.25%", // 16:9,
    backgroundSize: "cover",
    backgroundPosition: "center",
    borderRadius:"12px"
    // transform: "scaleY(1.5)"
  },
  ratingText: {
    fontFamily: "Open Sans",
    fontSize: "12px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.33,
    letterSpacing: "0.4px",
    color: "rgba(0, 0, 0, 0.6)",
  },
  productName: {
    fontFamily: "Open Sans",
    fontSize: "16px",
    fontWeight: 600,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.5,
    letterSpacing: "0.15px",
    color: "#000000",
  },
  flex: {
    display: "flex",
  },
  details: {
    display: "flex",
    flexDirection: "column",

  },
  large: {
    width: "40px",
    height: "40px",
    margin: "8px 8px 8px 0px",
  },
  button: {
    borderRadius: 5,
    height: "40px",
    fontSize: 14,
    margin: theme.spacing(0),
  },
  main: { 
    display: "flex" 
  },
  title: {
    maxWidth: "270px",
    fontFamily: "Open Sans",
    fontSize: "24px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1,
    letterSpacing: " 0.18px",
    color: "#000000",
  },
  subtitle: {
    fontFamily: "Open Sans",
    fontSize: "16px",
    fontWeight: "600",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.5",
    letterSpacing: "0.15px",
    color: "#000000",
    marginRight: "5px",
  },
  description: {
    fontFamily: "Open Sans",
    fontSize: "16px",
    fontWeight: "600",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.5",
    letterSpacing: "0.15px",
  },
  text: {
    fontFamily: "Open Sans",
    fontSize: "16px",
    fontWeight: "600",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.5",
    letterSpacing: "0.15px",
  },
  gridList: {
    flexWrap: "nowrap",
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: "translateZ(0)",
  },
  titleBar: {
    background:
      "linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
  },
  left: {
    position: "absolute",
    right: 180,
  },
}));

let cartListLoaded = false;
let orderLoaded = false;

const CardForm = (props) => {

  const {
    cartList = [],
    productsCart,
    deleteProductFromCart,
  } = props;

  const stripe = useStripe();
  const elements = useElements();
  const options = useOptions();
  const classes = useStyles();


  const [isProcessing, setProcessingTo] = useState(false);
  const [checkoutError, setCheckoutError] = useState();
  const [cardName, setCardName] = useState();
  // const [receipt_url, setReceipt] = useState();
  const [payment, setPayment] = useState();
  // const [cartList, setCartList] = useState([]);

  const [email, setEmail] = React.useState();
  const [address, setAddress] = React.useState();

  const isValidate = (cardName && email && address)  ? true : false

  const [open, setOpen] = React.useState(false);



  // const [load, setLoad] = React.useState(false);


  if (!cartListLoaded) {
    productsCart();
    cartListLoaded = true;
  }



  // React.useEffect(() => {
  //   let active = true;
  //   let data ;

  //   // console.log("Log: ", loading )

  //   if (load) {
  //     return undefined;
  //   }

  //   (async () => {
  //     // if (active) {
  //        axios.get(`/cart`).then(res => {
  //         console.log("Data: ", res.data)
  //         setCartList(res.data)
  //         setLoad(true)

  //       });
        
  //       // setLoading(true)
  //       // active = false;

  //     // }
  //   })();

  //   return () => {
  //     setLoad(true)
  //     // setCartList(data.data)
  //     // active = false;
  //   };
  // }, load);


  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    // onSuccessfulCheckout(payment);

    window.location.href = "/search";


  };

  const handleSetCardName = ev => {
    setCardName(ev.target.value)
  }

  const handleSetEmail = ev => {
    setEmail(ev.target.value)
  }


  const getTotalCost = () => {
    // let { cartList = [] } = this.props;
    let totalCost = 0;
    cartList.forEach(product => {
      totalCost += product.amount * product.price * 
      differenceInDays(new Date(product.endDate), new Date(product.startDate));    });
    return totalCost;
  };

  // const deleteProductFromCart = (id) => {

  //   axios
  //   .delete(`/cart/${id}`)
  //   .then(res => {

  //     setCartList(res.data)

  //     // dispatch({
  //     //   // type: DELETE_PRODUCT_FROM_CART,
  //     //   // payload: res.data
  //     // });

  //     console.log( res.data);
  //     // dispatch(productsCart())
  //   });

  // }

  

  const handleSubmit = async event => {
    event.preventDefault();

    setProcessingTo(true);

    const billingDetails = {
      name: cardName,
      email: email,
      address: {
        city: address.locality,
        line1: address.route,
        state: address.administrative_area_level_1,
        postal_code: address.postal_code
      }
    };


    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
      billing_details: billingDetails
    });

    if (!error) {
      const { id } = paymentMethod;

      console.log("Total", getTotalCost())

      try {
        const { data } = await axios.post("/charge", { id, amount: getTotalCost() });
        setPayment(data.confirm.id)

        handleOpen();

        setProcessingTo(false);
        elements.getElement(CardElement).clear();
      } catch (error) {
        console.log(error);
        setCheckoutError(error.message);
        setProcessingTo(false);
        return;
      }
    }
    else{
      setCheckoutError(error.message);
      setProcessingTo(false);
      return;
    }
  };


  return (


    <Fragment>
    <Navbar />
    <Grid container style={{ marginTop: "50px" }} spacing={5}>
    <Grid item md={8} sm={12} style={{ width: "100%" }}>
      <Grid container spacing={3}>
        <Grid item md={12} sm={12} style={{ width: "100%" }}>
          <Typography className={classes.title}>Shipping</Typography>
        </Grid>
        <Grid item sm={12} style={{ width: "100%" }}>
          <PlaceSearch
            setAddress={setAddress}
          ></PlaceSearch>
        </Grid>
        <Grid item md={12} sm={12} style={{ width: "100%" }} >
                      <TextField
                        id="email"
                        name="email"
                        type="email"
                        label="Email"
                        className={classes.textField}
                        // value={email}
                        onChange={handleSetEmail}
                        variant="outlined"
                        size="small"
                        fullWidth
                        InputLabelProps={{
                          classes: {
                            root: classes.cssLabel,
                            focused: classes.cssFocused,
                          },
                        }}
                        InputProps={{
                          classes: {
                            root: classes.cssOutlinedInput,
                            focused: classes.cssFocused,
                            notchedOutline: classes.notchedOutline,
                          },
                          inputMode: "numeric",
                        }}
                      />
                    </Grid>
        <Grid item md={12} sm={12} style={{ width: "100%", marginTop: "15px" }}>
          <Typography className={classes.title}>Payment</Typography>
        </Grid>
        <Grid item md={12} sm={12} style={{ width: "100%"}}>








    <Grid container spacing={3}>
      <Grid item md={10} sm={12} style={{ width: "100%"}}>
        <TextField
          id="name"
          name="name"
          type="text"
          label="Card Name"
          className="textField"
          // helperText={errors.address}
          // error={errors.address ? true : false}
          //value={this.state.name}
          onChange={handleSetCardName}
          size="small"
          variant="outlined"
          fullWidth
        />
      </Grid>
      <Grid item md={10} sm={12} style={{ width: "100%"}}>
        <CardElement options={options} className="card-element" />
      </Grid>
      

    </Grid>







    </Grid>
            <Grid item md={12} sm={12} style={{ width: "100%", marginTop: "15px" }} >
              <Typography className={classes.title}>Items</Typography>
            </Grid>
            <Grid item md={12} sm={12} style={{ width: "100%"}}>
          <Grid container spacing={2}>
            <Grid item md={12} sm={12} style={{ width: "100%"}}>
            <div className={classes.root}>
                <Grid container spacing={3}>
                  <Grid item md={12} sm={12} style={{ width: "100%"}}>
                    { cartList.map((product) => (
                      <Grid container spacing={2}>
                        <Grid item md={2} sm={2} style={{}}>
                          <div style={{ padding: "0px", borderRadius: "8px", width: "90px", height: "90px"}}>
                            <img style={{display: "block", marginLeft: "auto",  marginRight: "auto", padding: "0px", borderRadius: "8px", width: "90px", height: "90px"}} 
                                  src={product.image} alt="..." />
                          </div>
                        </Grid>

                        <Grid item md={10} sm={10} style={{paddingLeft: "0px" }}>
                          <div style={{alignItems: "baseline" }} key={product.id} className="cart__item">
                            <div style={{ marginRight: "0px", textAlign: "left", lignItems: "baseline", minWidth:"130px" }}>
                              <h6
                                className={classes.subtitle}
                                style={{ marginBottom: "2px" }}
                              >
                                {product.name}
                              </h6>
                              <div className={classes.rating}>
                                <Rating
                                  name="simple-controlled"
                                  value={`${product.rating}`}
                                  readOnly
                                  precision={0.5}
                                />
                                <Box ml={2}>({`${product.noc}`})</Box>
                              </div>
                              <small className="text-muted">
                              {formatDistance(new Date(product.startDate), new Date(product.endDate))} rental
                              </small>

                                <div  className={classes.mobile} style={{ position:"relative", right: "-10px",   float: "right", marginRight: "0px", textAlign: "right", lignItems: "baseline" }}>
                                <div style={{display:"flex", justifyContent: "space-between"}}>
                                  <div>
                                    <h6 style={{ marginBottom: "2px", width: "50px"  }}>
                                      Price
                                    </h6>
                                  </div>
                                  <div>
                                    <h6 style={{ marginLeft:"10px", marginBottom: "2px" }}>
                                      -
                                    </h6>
                                  </div>
                                  <div>
                                    <h6 style={{ marginBottom: "2px", width: "70px" }}>
                                      ${parseFloat(product.price).toFixed(2)}
                                    </h6>
                                  </div>
                                </div>
                                <div style={{display:"flex", justifyContent: "space-between"}}>
                                  <div>
                                    <h6 style={{ marginBottom: "2px", width: "50px"  }}>
                                      Tax
                                    </h6>
                                  </div>
                                  <div>
                                    <h6 style={{ marginLeft:"10px", marginBottom: "2px" }}>
                                      -
                                    </h6>
                                  </div>
                                  <div>
                                    <h6 style={{ marginBottom: "2px", width: "70px"  }}>
                                      $0.00
                                    </h6>
                                  </div>
                                </div>
                                  <IconButton
                                    size="small"
                                    onClick={() =>
                                      deleteProductFromCart(
                                        product.productId
                                      )
                                    }
                                  >
                                    <DeleteForeverOutlinedIcon fontSize="small" />
                                  </IconButton>
                                </div>
                            </div>

                            <div  className={classes.desktop} style={{ marginRight: "0px", textAlign: "right", lignItems: "baseline" }}>
                            <div style={{display:"flex", justifyContent: "space-between"}}>
                                  <div>
                                    <h6 style={{ marginBottom: "2px", width: "50px"  }}>
                                      Price
                                    </h6>
                                  </div>
                                  <div>
                                    <h6 style={{ marginLeft:"10px", marginBottom: "2px" }}>
                                      -
                                    </h6>
                                  </div>
                                  <div>
                                    <h6 style={{ marginBottom: "2px", width: "70px" }}>
                                      ${parseFloat(product.price).toFixed(2)}
                                    </h6>
                                  </div>
                                </div>
                                <div style={{display:"flex", justifyContent: "space-between"}}>
                                  <div>
                                    <h6 style={{ marginBottom: "2px", width: "50px"  }}>
                                      Tax
                                    </h6>
                                  </div>
                                  <div>
                                    <h6 style={{ marginLeft:"10px", marginBottom: "2px" }}>
                                      -
                                    </h6>
                                  </div>
                                  <div>
                                    <h6 style={{ marginBottom: "2px", width: "70px"  }}>
                                      $0.00
                                    </h6>
                                  </div>
                                </div>
                              <IconButton
                                size="small"
                                onClick={() =>
                                  deleteProductFromCart(
                                    product.productId
                                  )
                                }
                              >
                                <DeleteForeverOutlinedIcon fontSize="small" />
                              </IconButton>
                            </div>
                          </div>
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>             
                </Grid>
              </div>
            </Grid>
          </Grid>
        </Grid>
          </Grid>
        </Grid>
        <Grid item md={4} sm={12} xs={12} style={{ width: "100%" }}>
          <Grid container spacing={2}>
            <Grid item md={12} sm={12} xs={12} style={{ width: "100%" }} className={classes.desktop}>
              <Typography className={classes.title}>Order Summary</Typography>
            </Grid>
            <Grid item md={12} sm={12} xs={12} style={{ width: "100%" }}>
                  <Grid container spacing={2}>
                        <Grid item md={12} sm={12} xs={12} style={{ width: "100%" }}>
                          <div className="order_summary">
                            <div style={{ marginRight: "2px", textAlign: "left" }}>
                              <h6>
                                Subtotal
                              </h6>
                            </div>
                            <div style={{ marginRight: "2px", textAlign: "right" }}>
                              <h6>
                                ${getTotalCost().toFixed(2)}
                              </h6>
                            </div>
                          </div>
                        </Grid>
                        <Grid item md={12} sm={12} xs={12} style={{ width: "100%" }}>
                          <div className="order_summary">
                            <div style={{ marginRight: "2px", textAlign: "left" }}>
                              <h6>
                                Tax
                              </h6>
                            </div>
                            <div style={{ marginRight: "2px", textAlign: "right" }}>
                              <h6>
                                $0.00
                              </h6>
                            </div>
                          </div>
                        </Grid>
                        <Grid item md={12} sm={12} xs={12} style={{ width: "100%" }}>
                          <div className="order_summary">
                            <div style={{ marginRight: "2px", textAlign: "left" }}>
                              <h6>
                                Delivery
                              </h6>
                            </div>
                            <div style={{ marginRight: "2px", textAlign: "right" }}>
                              <h6>
                                $0.00
                              </h6>
                            </div>
                          </div>
                        </Grid>
                        <Grid item md={12} sm={12} xs={12} style={{ width: "100%" }}>
                          <div className="order_summary">
                            <div
                              style={{ marginRight: "2px", textAlign: "left" }}
                            >
                              <h6
                                className={classes.subtitle}
                                style={{ marginTop: "8px" }}
                              >
                                Total
                              </h6>
                            </div>
                            <div
                              style={{ marginRight: "2px", textAlign: "right" }}
                            >
                              <h6 className={classes.subtitle}
                                style={{ marginTop: "8px" }}
                                >
                                ${getTotalCost().toFixed(2)}
                              </h6>
                            </div>
                          </div>
                        </Grid>
                      </Grid>
                  </Grid>    
                  <Grid item md={12} sm={12} style={{ width: "100%"}}>
        <Button
          type="submit"
          variant="contained"
          color="secondary"
          fullWidth
          onClick={handleSubmit}
          disabled={!isValidate || isProcessing || !stripe}
        >
          Pay
          {isProcessing || !stripe && (
                              <CircularProgress
                                size={30}
                                // className={classes.progress}
                              />
                            )}
        </Button>
        <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{"Congrats!"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Compra exitosa !!!
            {/* See  <Link href={receipt_url} color="inherit" target="_blank" rel="noreferrer"> receipt </Link> */}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary" variant="contained">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
      </Grid>
      <Grid item md={12} sm={12} style={{ width: "100%"}}>
        {checkoutError && <CheckoutError>{checkoutError}</CheckoutError>}
        </Grid>

          </Grid>
        </Grid>
      </Grid>
      </Fragment>
  );
};


CardForm.propTypes = {
  // settings: PropTypes.object.isRequired,
  cartList: PropTypes.array.isRequired,
  // payment: PropTypes.string.isRequired
};

const mapStateToProps = (state) => ({
  // settings: state.layout.settings,
  productsCart: PropTypes.func.isRequired,
  deleteProductFromCart: PropTypes.func.isRequired,
  // updateCartAmount: PropTypes.func.isRequired,
  cartList: state.data.cartList,
  // user: state.user,
});

export default (
  connect(mapStateToProps, {
    productsCart,
    deleteProductFromCart,
  })(CardForm)
);
// export default CardForm;
