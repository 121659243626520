import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Card,
  Box,
  Grid,
  CardActionArea,
  Link,
  CardMedia,
  CardContent,
  Typography,
  IconButton
} from "@material-ui/core";

import Rating from "@material-ui/lab/Rating";
import ShoppingCartOutlinedIcon from "@material-ui/icons/ShoppingCartOutlined";

import { connect } from "react-redux";
import PropTypes from "prop-types";
import { addProductToCart } from "../redux/actions/dataActions";

const useStyles = makeStyles((theme) => ({
  root: {
    // maxWidth: 345,
    paddingTop: "10px",
    paddingLeft: "10px",
    paddingRight: "10px",
    paddingBottom: "10px",
    boxShadow: "0 7px 64px 0 rgba(0, 0, 0, 0.07)",
    [theme.breakpoints.down("sm")]: {
       minWidth: 315,
    }

  },
  media: {
    height: 0,
    paddingTop: "75%", //4:3    //"56.25%", 16:9,
    backgroundSize: "cover", // or cover
    borderRadius: "4px"
  },
  rating: {
    // width: 200,
    display: "flex",
    alignItems: "center",
  },
  ratingText: {
    fontFamily: "Open Sans",
    fontSize: "12px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.33,
    letterSpacing: "0.4px",
    color: "rgba(0, 0, 0, 0.6)",
  },
  productName: {
    marginTop: "5px",
    fontFamily: "Open Sans",
    fontSize: "16px",
    fontWeight: 600,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.5,
    letterSpacing: "0.15px",
    color: "#000000",
  }
}));

const GridProductCard = ({ product, addProductToCart, user, endDate, startDate }) => {
  const classes = useStyles();

  const handleAddToCart = (event) => {
    const productData = {
      productId: product.toolId,
      price: product.price,
      imageURL: product.toolImageList[0],
      name: product.name,
      rating: product.rating,
      noc: product.noc,
      startDate: startDate,
      endDate: endDate,
      owner: product.userHandle,
      address: product.address
    };
    addProductToCart(productData);
  };

  const handleClickDetail = () => {
    window.location.href = `/detail/${product.toolId}?startDate=${startDate}&endDate=${endDate}`;
  }

  return (
      <Card className={classes.root} elevation={0}>
        <CardActionArea style={{padding:0}}>
          <Link
            className="rental-detail-link"
            onClick={() => {
              handleClickDetail()
            }}
          >
            <CardMedia
              m={50}
              className={classes.media}
              image={(product.toolImageList[0])?product.toolImageList[0]:null}
              title={product.name}
            />
          </Link>
        </CardActionArea>
          <CardContent style={{padding:0}}>
            <Typography variant="h5" component="h2" className={classes.productName}>
              {product.name}
            </Typography>
            <div className={classes.rating}>
              <Rating
                name="simple-controlled"
                value={`${product.rating}`}
                readOnly
                size="small"
                precision={0.5}
                style={{maxHeight:"s"}}
              />
              <Box className={classes.ratingText}>({`${product.noc}`})</Box>
            </div>
            <div className="spaced__item">
              <div>
                <Typography gutterBottom variant="body2" component="h2" style={{ marginTop: 5}}>
                  {`$${product.price}/Day`}
                </Typography>
              </div>
              <div>
                <IconButton
                  style={{ margin: 0, padding: 4, borderRadius: 5 }}
                  onClick={() => handleAddToCart()}
                >
                  <ShoppingCartOutlinedIcon
                    style={{ fontSize: 22, margin: 0 }}
                   // color={view === "list" ? "secondary" : "inherit"}
                  />
                </IconButton>
              </div>
            </div>
          </CardContent>
      </Card>
  );
};

const mapStateToProps = (state) => ({
  addProductToCart: PropTypes.func.isRequired,
  user: state.user,
});

export default connect(mapStateToProps, { addProductToCart })(GridProductCard);
