import React, { Fragment } from "react";
import {
  IconButton,
  Icon,
  Divider,
  Fab,
  TextField,
  MenuItem,
} from "@material-ui/core";
// import { MatxMenu } from "matx";
import Scrollbar from "react-perfect-scrollbar";
import EmptyMessage from "./EmptyMessage";
import ChatAvatar from "./ChatAvatar";
import { getTimeDifference } from "../../util/utils";
import shortid from "shortid";

import 'react-perfect-scrollbar/dist/css/styles.css';

import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";

import SendOutlinedIcon from '@material-ui/icons/SendOutlined';

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: "900px",
    backgroundColor: theme.palette.background.paper,
    height: 450,
    MaxHeight: 450,
    overflow: "auto"
  },
  list: {
    // width: '100%',
    minWidth: 500,
    backgroundColor: theme.palette.background.paper,
    // position: 'relative',
    overflow: "auto",
    height: 450,
  },
  item: {
    maxWidth: 500,
  }
}));


const ChatContainer = ({
  id: currentUserId,
  toggleSidenav,
  currentChatRoom,
  opponentUser,
  messageList = [],
  setBottomRef,
  handleMessageSend,
}) => {
  let [message, setMessage] = React.useState("");
  const sendMessageOnEnter = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      message = message.trim();
      if (message !== "") handleMessageSend(message);
      setMessage("");
    }
  };
  const classes = useStyles();

  return (
    <div className="chat-container flex-column position-relative" style={{paddingTop: "50px"}}>
      <div className="chat-container__topbar flex items-center justify-between p-1 bg-primary">
        <div className="flex items-center">
          <div className="show-on-mobile">
            <IconButton onClick={toggleSidenav}>
              <Icon className="text-white">short_text</Icon>
            </IconButton>
          </div>

          <div className="hide-on-mobile">
            <div className="pl-3"></div>
          </div>

          {opponentUser && (
            <Fragment>
              <ChatAvatar
                src={opponentUser.avatar}
                status={opponentUser.status}
              />
              <h5 className="ml-4 whitespace-pre mb-0 font-medium text-18 text-white">
                {opponentUser.name}
              </h5>
            </Fragment>
          )}
        </div>
      </div>
      <Scrollbar
        containerRef={ref => {
          setBottomRef(ref);
        }}
        className="chat-message-list flex-grow position-relative"
      >
        {messageList.map((message, index) => (
          <div key={shortid.generate()}
                className={`flex px-4 py-10 ${
                  currentUserId === message.contactId
                    ? "items-end flex-end text-align-right"
                    : " "
                }`}
          >
          {(currentUserId !== message.contactId) ? (
            <div className="flex">
            <ChatAvatar src={message.avatar} status={message.status} />
            <div className="ml-10">
              <div
                className={`px-8 py-8 mt-2 list__message ${
                  currentUserId === message.contactId
                    ? "bg-light-gray"
                    : "bg-light-gray"
                }`}
              >
                <span className="whitespace-pre-wrap">{message.text}</span>
              </div>
            </div>
            </div>

          ):(<div className="flex">
            <div className="mr-10">
              <div
                className={`px-8 py-8 mt-2 mr-4 list__message ${
                  currentUserId === message.contactId
                    ? "bg-light-gray"
                    : "bg-light-gray"
                }`}
              >
                <span className="whitespace-pre-wrap">{message.text}</span>
              </div>
            </div>
            <ChatAvatar src={message.avatar} status={message.status} />
            </div>)}


          </div>
        ))}
        {/* <div ref={ref => setBottomRef(ref)} /> */}
      </Scrollbar>

      <Divider />

      
        <div className="flex items-center px-4 py-4 mt-6">
          <TextField
            label="Type your message here*"
            value={message}
            onChange={e => setMessage(e.target.value)}
            onKeyDown={sendMessageOnEnter}
            fullWidth
            multiline={true}
            rows={1}
            size="small"
            variant="outlined"
          />
          <div>
            <SendOutlinedIcon
              onClick={() => {
                if (message.trim() !== "") handleMessageSend(message);
                setMessage("");
              }}
              color="secondary"
              className="mt-10 ml-6 px-4 py-2"
            >
            </SendOutlinedIcon>
          </div>
        </div>
    </div>
  );
};

export default ChatContainer;
