import React, { Component } from "react";
import PropTypes from "prop-types";
import StaticReview from "./StaticReview";
import Grid from "@material-ui/core/Grid";

import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/core/styles/withStyles";

import { connect } from "react-redux";
import { getCommentsOfUser } from "../../redux/actions/userActions";

class Review extends Component {
  state = {
    profile: null,
    toolIdParam: null,
  };

  componentWillMount() {
    const userTool = this.props.userTool;

    this.props.getCommentsOfUser(userTool);
  }

  render() {
    const {
      classes,
      user: {
        commentsOfUser,
      },
    } = this.props;

    return (
      <Grid container spacing={1}>
        <Grid item sm={12}>
          <div className={classes.main}>
            <div className={classes.a}>
              <Typography
                color="inherit"
                align="left"
                variant="h4"
                marked="left"
                className={classes.h5}
              >
                Reviews
              </Typography>
            </div>
          </div>
            {
              commentsOfUser.comments?
              commentsOfUser.comments.map((comment) =>
                <StaticReview comment={comment}  />
              ) :
              null
              }

        </Grid>
      </Grid>
    );
  }
}

Review.propTypes = {
  user: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  getCommentsOfUser: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapActionsToProps = {
  getCommentsOfUser,
};

const styles = (theme) => ({
  grow: {
    flexGrow: 1,
  },
  heading: {
    display: "flex",
    marginBottom: theme.spacing(0),
    padding: theme.spacing(0),
    fontFamily: "Open Sans",
    fontSize: "20px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.2,
    letterSpacing: "0.15px",
    color: "rgba(0, 0, 0)",
    alignItems: "center",
    justifyContent: "left",
    textAlign: "left",
  },
  card: {
    display: "flex",
    marginLeft: "auto",
    marginRight: "auto",
    maxWidth: "375px",
    marginTop: theme.spacing(10),
    // paddingRight: theme.spacing(2),
    // paddingTop: theme.spacing(0.6),
    // paddingBottom: theme.spacing(0.6),
  },
  cardcontent: {
    //margin: '4px',
    padding: "30px",
    "&:last-child": {
      paddingBottom: "30px",
    },
  },
  button: {
    borderRadius: 5,
    height: "40px",
    fontSize: 14,
    margin: theme.spacing(0),
  },
  title: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  main: { display: "flex" },
  c: { position: "absolute", right: 170 },
  h5: {
    display: "flex",
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(2),
    padding: theme.spacing(0),
    fontFamily: "Open Sans",
    fontSize: "24px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1,
    letterSpacing: "0.18px",
    color: "rgba(0, 0, 0, 0.87)",
    alignItems: "center",
    justifyContent: "left",
    textAlign: "left",
  },
  more: {
    display: "flex",
    fontFamily: "Open Sans",
    fontSize: "12px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: " normal",
    lineHeight: "1.33",
    letterSpacing: "0.4px",
    color: "#000000",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
  },
  background: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 62,
    bottom: 0,
    minHeight: 990,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    zIndex: -10,
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
    root: {
      backgroundColor: "#ffffff",
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: "center",
      color: theme.palette.text.secondary,
    },
  },
});

export default connect(mapStateToProps, mapActionsToProps)(
  withStyles(styles)(Review)
);
