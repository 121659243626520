import React from "react";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import Container from "@material-ui/core/Container";

import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

import FacebookIcon from "@material-ui/icons/Facebook";
import TwitterIcon from "@material-ui/icons/Twitter";
import InstagramIcon from "@material-ui/icons/Instagram";

import Icon from "../../../images/black_logo.png";

function Copyright() {
  return (
    <React.Fragment>
      {"© "}
      <Link color="inherit" href="https://www.toowlz.com">
        Toowlz
      </Link>{" "}
      {new Date().getFullYear()}
    </React.Fragment>
  );
}

const styles = (theme) => ({
  root: {
    //display: "flex",
    backgroundColor: "#f4722d",
    overflow: "hidden",
    height: "60px",
    // width:"calc(100% + 300px)",
    marginTop:"30px",
    // marginLeft:"-150px",
    left:"0px"
  },
  container: {
    position:"absolute",
    //left:"150px",
   // width:"calc(100% - 150px)",

   // marginLeft: "150px",
    //width:"1440px",
    // alignItems: "center",
  },
  item: {
    // display: "flex",
    // flexDirection: "column",
    // alignItems: "center",
    padding: theme.spacing(0, 1),
  },
  image: {
    height: 35,
    marginTop: theme.spacing(2),
  },
});

function AppFooter(props) {
  const { classes } = props;

  return (
    <div>
    <div className={classes.root}>
      
    </div>
    <Container className={classes.container}>
          <Grid container spacing={1}>
            <Grid item sm={4} lg={4} md={4}>
                <FacebookIcon
                  style={{ fill: "#000" }}
                  alt="suitcase"
                  fontSize="large"
                  className={classes.image}
                />
            </Grid>
            <Grid item sm={4} lg={4} md={4}>
                <TwitterIcon
                  style={{ fill: "#000" }}
                  alt="graph"
                  fontSize="large"
                  className={classes.image}
                />
            </Grid>
            <Grid item sm={4} lg={4} md={4}>
                <InstagramIcon
                  style={{ fill: "#000" }}
                  alt="clock"
                  fontSize="large"
                  className={classes.image}
                />
            </Grid>
          </Grid>
      </Container>
      </div>
  );
}

AppFooter.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AppFooter);
