import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

import * as _ from "lodash";

import PostCard from "./PostCard"

const styles = (theme) => ({
  root: {
    display: "flex",
    backgroundColor: theme.palette.white,
    overflow: "hidden",
    
  },
  container: {
    marginTop: theme.spacing(38),
    marginBottom: theme.spacing(2),
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "left",
    padding: "4",

    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(0),
    }
  },
  item: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    minWidth:"206px",
    
    [theme.breakpoints.down("sm")]: {
      flexDirection: "row",
      minWidth:"300px",
    }
  },
  h5: {
    marginBottom: theme.spacing(2.5),
    // marginTop: theme.spacing(22),
    marginLeft:"0px",
    marginRight:"0px",
    padding: theme.spacing(0),
    textAlign:"center",
    margin:"0 auto",
    alignItems: "center",
    fontSize: 48,
    fontFamily: "Open Sans",
    fontWeight: 600,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.17,
    letterSpacing: "normal",
    color: "rgba(0, 0, 0, 0.87)",

    [theme.breakpoints.down("sm")]: {
      fontSize: 34,
      lineHeight: 1.06,
    }
  },

  products:{
    display: "flex",  
    justifyContent: "space-between",
    alignItems: "center",
    transition: "background 300ms ease",
    padding: "0px",
    [theme.breakpoints.down("sm")]: {
      display: "inline",  
    }
  },
});

function PostValues(props) {
  const { classes, postList=[] } = props;

  return (
            <Grid container spacing={2}>
              {
                postList.map((post) => (
                    <Grid item lg={4} md={4} sm={12} xs={12}>
                    <PostCard 
                      media={true}
                      // width={280}
                      post={post}
                      >
                    </PostCard>

                    </Grid>
                  ))
              }
            </Grid>
  );
}

PostValues.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(PostValues);
