import React, { Component, Fragment } from "react";

import { connect } from "react-redux";

// --- Post bootstrap -----
import Header from "../components/blog/Header";
import PostValues from "../components/blog/PostValues";
import Navbar from "../components/layout/Navbar";
import BlogNavBar from "../components/blog/BlogNavbar";

import {
  getPostId,
  addNewPost,
  getAllPosts,
  getPosts
} from "./BlogService";


class Blog extends Component {
  state = {
    postList: [],
    query:""
  };

  componentWillMount() {
    var query = ""
    query = this.props.match.params.query;
    console.log(query)
    var res = query? query.toLowerCase():"";

    switch (res) {
      case "mostliked":
        getAllPosts("likes").then(data =>
          this.setState({ postList: [...data.data] })
        );
        break;
      case "mostshared":
        getAllPosts("shares").then(data =>
          this.setState({ postList: [...data.data] })
        );
        break;
      case "latest":
        getAllPosts("date").then(data =>
          this.setState({ postList: [...data.data] })
        );
        break;
      case "videos":
        getPosts("videos").then(data =>
          this.setState({ postList: [...data.data] })
        );
      break;
      case "podcast":
        getPosts("podcast").then(data =>
          this.setState({ postList: [...data.data] })
        );
        break;
      case "articles":
        getPosts("articles").then(data =>
          this.setState({ postList: [...data.data] })
        );
        break; 
    
      default:
        getAllPosts("title").then(data =>
          this.setState({ postList: [...data.data] })
        );
        break;
    }
    
  }

  mostLiked = () => {
    const { postList } = this.state;
    postList.sort(({ likes: previousID }, { likes: currentID }) => previousID - currentID)
    this.setState({ postList })
  }

  mostShared = () => {
    const { postList } = this.state;
    postList.sort(({ share: previousID }, { share: currentID }) => previousID - currentID)
    this.setState({ postList })
  }

  render() {
    let {
      postList,
      query
    } = this.state;

    return (
      <Fragment>
        <Navbar isTransparent={false}/>
        <BlogNavBar isTransparent={false} />
        <Header post={postList[0]} />
        <PostValues postList={postList} query={query}  />
      </Fragment>
    );
  }
}

Blog.propTypes = {};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(Blog);
