import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Avatar from "@material-ui/core/Avatar";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";

import ShareIcon from "@material-ui/icons/Share";
import ThumbUpOutlinedIcon from "@material-ui/icons/ThumbUpOutlined";

import * as _ from "lodash";

import PostCard from "./PostCard";

import { format } from "date-fns";


import ReactHtmlParser from "react-html-parser";
import {
  FacebookShareCount,
  OKShareCount,
  PinterestShareCount,
  RedditShareCount,
  TumblrShareCount,
  VKShareCount
} from "react-share";

import {
  EmailShareButton,
  FacebookShareButton,
  InstapaperShareButton,
  LineShareButton,
  LinkedinShareButton,
  LivejournalShareButton,
  MailruShareButton,
  OKShareButton,
  PinterestShareButton,
  PocketShareButton,
  RedditShareButton,
  TelegramShareButton,
  TumblrShareButton,
  TwitterShareButton,
  ViberShareButton,
  VKShareButton,
  WhatsappShareButton,
  WorkplaceShareButton
} from "react-share";

const styles = (theme) => ({
  root: {
    padding: "0px 240px", 
    marginTop: "620px",
    [theme.breakpoints.down("sm")]: {
      padding: "0px 10px", 
      marginTop: "395px"
    },
  },
  container: {
    marginTop: theme.spacing(38),
    marginBottom: theme.spacing(2),
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "left",
    padding: "4",

    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(0),
    },
  },
  item: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    minWidth: "206px",

    [theme.breakpoints.down("sm")]: {
      flexDirection: "row",
      minWidth: "300px",
    },
  },
  h5: {
    marginBottom: theme.spacing(2.5),
    // marginTop: theme.spacing(22),
    marginLeft: "0px",
    marginRight: "0px",
    padding: theme.spacing(0),
    textAlign: "center",
    margin: "0 auto",
    alignItems: "center",
    fontSize: 48,
    fontFamily: "Open Sans",
    fontWeight: 600,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.17,
    letterSpacing: "normal",
    color: "rgba(0, 0, 0, 0.87)",

    [theme.breakpoints.down("sm")]: {
      fontSize: 34,
      lineHeight: 1.06,
    },
  },

  products: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    transition: "background 300ms ease",
    padding: "0px",
    [theme.breakpoints.down("sm")]: {
      display: "inline",
    },
  },
  grow: {
    flexGrow: 1,
  },
  chip: {
    padding: theme.spacing(0),
    margin: theme.spacing(0),
    fontFamily: "Open Sans",
    fontSize: "12px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: " normal",
    lineHeight: "1.2",
    letterSpacing: "0.1px",
    color: "#217cbd",
    maxHeight: "22px",
  },
  ratingText: {
    fontFamily: "Open Sans",
    fontSize: "12px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.33,
    letterSpacing: "0.4px",
    color: "rgba(0, 0, 0, 0.6)",
  },
  productName: {
    fontFamily: "Open Sans",
    fontSize: "16px",
    fontWeight: 600,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.5,
    letterSpacing: "0.15px",
    color: "#000000",
  },
  flex: {
    display: "flex",
  },
  details: {
    display: "flex",
    flexDirection: "column",
  },
  large: {
    width: "40px",
    height: "40px",
    margin: "8px 8px 8px 0px",
  },
});

function PostContent(props) {
  const { classes, post, postList = [] } = props;

  let content = "";
  content = post.content;

  let shareUrl= window.location.href;
  let title="Post Toowlz";

  //   let postList= [{name:"Titulo1", imagen:"Imagen 2"},
  //   {name:"Titulo1", imagen:"Imagen 2"},
  // ]
  return (
    <Grid
      container
      spacing={2}
      className={classes.root}
    >
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Typography
          style={{
            fontFamily: "Open Sans",
            fontSize: "10px",
            fontWeight: "600",
            fontStretch: "normal",
            fontStyle: "normal",
            lineHeight: "1.6",
            letterSpacing: "1.5px",
            color: "rgba(0, 0, 0, 0.87)",
            margin: "0 0px 12px 0",
          }}
        >
          {" "}
          {post.time} MINUTES READ{" "}
        </Typography>

        <div
          className="spaced__item"
          style={{
            margin: "0 0px 14px 0",
          }}
        >
          <div>
            <Chip
              label={post.category}
              style={{
                marginTop: 3,
                fontFamily: "Open Sans",
                fontSize: "14px",
                fontWeight: "600",
                fontStretch: "normal",
                fontStyle: "normal",
                lineHeight: "1.6",
                letterSpacing: "1.5px",
                color: "#cb2f30",
                borderRadius: "4px",
                backgroundColor: "rgba(203, 47, 48, 0.2)",
              }}
            />
          </div>
          <div style={{ display: "flex", padding: "0 6px 0 0px" }}>
            <EmailShareButton
            url={shareUrl}
            subject={title}
            // body="body"
            >
            <ShareIcon
              style={{ color: "gray", fontSize: 16, margin: "4 0 0 2" }}
            />
            </EmailShareButton>
            <div
              style={{
                margin: "2px 10px 0 4px",
                fontFamily: "Open Sans",
                fontSize: "14px",
                fontWeight: "normal",
                fontStretch: "normal",
                fontStyle: "normal",
                lineHeight: "1.43",
                letterSpacing: "0.25px",
                color: "rgba(0, 0, 0, 0.6)",
              }}
            >
              {post.shares}
            </div>

            <ThumbUpOutlinedIcon
              style={{ color: "gray", fontSize: 16, margin: "4 0 0 2" }}
            />
            <div
              style={{
                margin: "2px 10px 0 4px",
                fontFamily: "Open Sans",
                fontSize: "14px",
                fontWeight: "normal",
                fontStretch: "normal",
                fontStyle: "normal",
                lineHeight: "1.43",
                letterSpacing: "0.25px",
                color: "rgba(0, 0, 0, 0.6)",
              }}
            >
              {post.likes}
            </div>
          </div>
        </div>

        <Card className={classes.flex} elevation={0}>
          <Avatar
            alt="Remy Sharp"
            src="https://firebasestorage.googleapis.com/v0/b/toowlz.appspot.com/o/no-user.png?alt=media&token=4c4ef840-fada-4119-8fbc-7f853a004687"
            className={classes.large}
          />
          <div className={classes.details}>
            <CardContent style={{ padding: "7px 0 0 0" }}>
              <div className={classes.main}>
                <Typography
                  variant="body2"
                  style={{
                    fontFamily: "Open Sans",
                    fontSize: "16px",
                    fontWeight: "bold",
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: 1.5,
                    letterSpacing: "0.15px",
                    color: "rgba(0, 0, 0, 0.87)",
                  }}
                >
                  {post.autor}
                </Typography>
              </div>
              <div
                style={{
                  fontFamily: "Open Sans",
                  fontSize: "14px",
                  fontWeight: "normal",
                  fontStretch: "normal",
                  fontStyle: "normal",
                  lineHeight: "1.43",
                  letterSpacing: "0.25px",
                  color: "rgba(0, 0, 0, 0.38)",
                }}
              >
              {console.log("post.date", post.date)}
              { post.date ? format(
                                    new Date(post.date).getTime(),
                                    "MMMM dd yyyy"
                                  ): null}
              </div>
            </CardContent>
          </div>
        </Card>

        <div
          style={{
            fontFamily: "Open Sans",
            fontSize: "24px",
            fontWeight: "bold",
            fontStretch: "normal",
            fontStyle: "normal",
            lineHeight: 1.25,
            letterSpacing: "0.18px",
            color: "rgba(0, 0, 0, 0.87)",
            margin: "14px 0px 32px 0",
          }}
        >
          {post.title}
        </div>

        <Typography
          style={{
            marginTop: 5,
            fontFamily: "Open Sans",
            fontSize: "14px",
            fontWeight: "normal",
            fontStretch: "normal",
            fontStyle: "normal",
            lineHeight: 1.43,
            letterSpacing: "0.25px",
            color: "rgba(0, 0, 0, 0.6)",
            margin: "0 0px 14px 0",
          }}
        >
          {ReactHtmlParser(content)}
        </Typography>
      </Grid>

      <Grid item lg={12} md={12} sm={12} xs={12}>
        <div
          style={{
            fontFamily: "Open Sans",
            fontSize: "24px",
            fontWeight: "bold",
            fontStretch: "normal",
            fontStyle: "normal",
            lineHeight: 1.25,
            letterSpacing: "0.18px",
            color: "rgba(0, 0, 0, 0.87)",
            margin: "14px 0px 32px 0",
          }}
        >
          {/* {post.tittle} */}
          MORE STORIES
        </div>
      </Grid>

      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Grid container spacing={2}>
          {postList.slice(0, 2).map((post) => (
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <PostCard
                media={true}
                post={post}
                // width={280}
              ></PostCard>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
}

PostContent.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(PostContent);
