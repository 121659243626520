import React from "react";
import ChatAvatar from "./ChatAvatar";
import Scrollbar from "react-perfect-scrollbar";
import { format } from "date-fns";

const ChatSidenav = ({
  // currentUser,
  contactList = [],
  recentContactList = [],
  handleContactClick,
}) => {
  return (
    <div className="chat-sidenav bg-default" style={{padding: "50px 10px 0px 0px"}}>
      <Scrollbar className="chat-contact-list position-relative h-400">
        {contactList.map((contact, index) => (
          <div
            onClick={() => handleContactClick(contact)}
            key={index}
            className="cart__item cursor-pointer  gray-on-hover"
            style={{height: "50px"}}
          >
            <div className="flex flex-middle">
                <ChatAvatar className="pt-6" src={contact.avatar} status={contact.status} />
                <p style={{fontSize: "16px",
  fontWeight: "600",
  fontStretch: "normal",
  fontStyle: "normal",
  lineHeight: "1.25",
  letterSpacing: "0.5px",}} className="pl-4 pt-6">{contact.chatContact}</p>
            </div>
            <div className="pl-4 flex flex-middle items-end text-align-right font-size-10" style={{ textAlign: "right" }}>
              <p className="pl-4" style={{ textAlign: "right", paddingTop:"7px" }}> 
                {format(
                  new Date(contact.lastChatTime).getTime(),
                  "MMMM dd"
                )}
              </p>
            </div>
          </div>
        ))}
      </Scrollbar>
    </div>
  );
};

export default ChatSidenav;
