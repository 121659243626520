import {
  SET_USER,
  SET_COMMENTS,
  SET_AUTHENTICATED,
  SET_UNAUTHENTICATED,
  LOADING_USER,
  LIKE_TOOL,
  UNLIKE_TOOL,
  MARK_NOTIFICATIONS_READ,
} from "../types";

const initialState = {
  authenticated: false,
  userHandle: "",
  loading: false,
  credentials: {},
  likes: [],
  notifications: [],
  tools: [],
  commentsOfUser: {}
};

export default function (state = initialState, action) {

  switch (action.type) {
    case SET_AUTHENTICATED:
      return {
        ...state,
        authenticated: true,
      };
    case SET_UNAUTHENTICATED:
      return initialState;
    case SET_USER:
      return {
        ...state,
        loading: false,
        userHandle: action.payload.credentials.handle,
        credentials: action.payload.credentials,
        ...action.payload,
      };
    case SET_COMMENTS:
      return {
        ...state,
        loading: false,
        commentsOfUser: action.payload,
      };
    case LOADING_USER:
      return {
        ...state,
        loading: true,
      };
    case LIKE_TOOL:
      return {
        ...state,
        likes: [
          ...state.likes,
          {
            userHandle: state.credentials.handle,
            toolId: action.payload.toolId,
          },
        ],
      };
    case UNLIKE_TOOL:
      return {
        ...state,
        likes: state.likes.filter(
          (like) => like.toolId !== action.payload.toolId
        ),
      };
    case MARK_NOTIFICATIONS_READ:
      state.notifications.forEach((not) => (not.read = true));
      return {
        ...state,
      };
    default:
      return state;
  }
}
