import Tab from "@material-ui/core/Tab";
import { withStyles } from "@material-ui/core/styles";

const MyTab = withStyles(theme => ({
  root: {
    borderRight: "2px solid lightgray",
    textAlign: "left",
    alignItems:"normal",
    minWidth: "200px",
    fontFamily: "Open Sans",
    fontSize: "16px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: " normal",
    // lineHeight: "1.24",
    letterSpacing: "0.15px",
    color: "rgba(0, 0, 0, 0.6)",
    textTransform: "initial",
    // marginLeft:"12vw"
  },
  wrapper:{
    alignItems:"normal"
  },
  selected: {
    color: "#f4722d",
    borderRight: "3px solid #f4722d",
    textAlign: "left",
    fontFamily: "Open Sans",
    fontSize: "16px",
    fontWeight: "bold",
    fontStretch: "bold",
    fontStyle: " bold",
    // lineHeight: "1.24",
    letterSpacing: "0.15px",
    textTransform: "initial",
    // color: "rgba(0, 0, 0, 0.6)",
  },
  label: {
    fontFamily: "Open Sans",
    fontSize: "16px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: " normal",
    minWidth: "200px",
    // lineHeight: "1.24",
    letterSpacing: "0.15px",
    color: "rgba(0, 0, 0, 0.6)",
    textTransform: "initial",
  }
}))(Tab);
export default MyTab;