import axios from "axios";

export const getContactById = id => {
  return axios.get("/chat/contacts", { data: id });
};
export const getRecentContact = id => {
  return axios.get("/chat/contacts", { data: id });
};
export const getAllContact = currentUserId => {
  return axios.get("/chat/contacts", { data: currentUserId });
};
export const getChatRoomByContactId = (currentUser, contact) => {
  return axios.post("/chat/chat-room", { contact });
};
export const deleteMessage = message => {
  return axios.post("/chat/delete", message);
};
export const sendNewMessage = message => {
  return axios.post("/chat/add", message);
};
export const updateMessage = message => {
  return axios.post("/chat/update", message);
};
