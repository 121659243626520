import React from "react";
import { Icon, Badge, IconButton, Drawer, Card } from "@material-ui/core";
import { ThemeProvider, withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { getTimeDifference } from "../../util/utils.js";


import {
  getNotification,
  deleteNotification,
  // deleteAllNotification
} from "../../redux/actions/dataActions";

import ShopSidenav from "../../pages/ShopSidenav";

import NotificationsOutlinedIcon from "@material-ui/icons/NotificationsOutlined";
import DeleteForeverOutlinedIcon from "@material-ui/icons/DeleteForeverOutlined";
import CloseIcon from '@material-ui/icons/Close';
import TuneIcon from '@material-ui/icons/Tune';

import formatDistance from "date-fns/formatDistance";
import differenceInDays from "date-fns/differenceInDays";

import { Button, Grid } from "@material-ui/core";

let cartListLoaded = false;

function Filters(props) {
  const {
    container,
    theme,
    settings,
    notifications: notifcationList = [],
    getNotification,
    // deleteAllNotification,
    deleteNotification,
    user,
    color,
    query,
  from,
  to,
  categories,
  brands,
  multilevel,
  categoryList,
  brandList,
  productNameList,
  ratingList,
  shipping,
  delivery,
  sliderRange,
  productName,
  toggleSidenav,
  handleSearch,
  handleMultilevelChange,
  handleChangeZipCode,
  handleChangeDateFrom,
  handleChangeDateTo,
  handleSliderChange,
  handleCategoryChange,
  handleBrandChange,
  handleProductNameChange,
  handleRatingClick,
  handleFreeShippingClick,
  handleFreeDeliveryClick,
  handleClearAllFilter,
  } = props;

  const [panelOpen, setPanelOpen] = React.useState(false);

  if (!cartListLoaded) {
    console.log(user);
    getNotification();
    cartListLoaded = true;
  }

  function handleDrawerToggle() {
    setPanelOpen(!panelOpen);
  }

  return (
    <ThemeProvider>
      {/* <IconButton onClick={handleDrawerToggle} className={color}>
          <TuneIcon color="secondary" />
      </IconButton> */}

      <Button
             variant="contained"  
             color="secondary"            
              style={{ margin: 2, padding: 0, borderRadius: 4, maxWidth:"38px", minWidth: "38px" }}
              onClick={ handleDrawerToggle}
            >
              <TuneIcon color="primary" style={{ fontSize: 36, margin: 0, padding: 0 }}/>
            </Button>

      <Drawer
        width={"100px"}
        container={container}
        variant="temporary"
        anchor={"left"}
        open={panelOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true
        }}
      >
        <div className="mini-cart">
          <div className="cart__topbar">
            <h5 style={{ minWidth: "200px", marginTop: "5px" }}>
              Filter
            </h5>
            <IconButton onClick={handleDrawerToggle}>
              <CloseIcon color="primary" style={{ maxWidth: "30px" }}/>
            </IconButton>
          </div>
          
          <ShopSidenav
          query={query}
              categories={categories}
              brands={brands}
              multilevel={multilevel}
              categoryList={categoryList}
              brandList={brandList}
              productNameList={productNameList}
              ratingList={ratingList}
              shipping={shipping}
              delivery={delivery}
              from={from}
              to={to}
              productName={productName}
              sliderRange={sliderRange}
              toggleSidenav={toggleSidenav}
              handleSearch={handleSearch}
              handleChangeDateFrom={handleChangeDateFrom}
              handleChangeDateTo={handleChangeDateTo}
              handleMultilevelChange={handleMultilevelChange}
              handleSliderChange={handleSliderChange}
              // handleDateChange={this.handleDateChange}
              handleCategoryChange={handleCategoryChange}
              handleBrandChange={handleBrandChange}
              handleProductNameChange={handleProductNameChange}
              handleRatingClick={handleRatingClick}
              handleFreeShippingClick={handleFreeShippingClick}
              handleFreeDeliveryClick={handleFreeDeliveryClick}
              handleClearAllFilter={handleClearAllFilter}
              ></ShopSidenav>
        </div>
      </Drawer>
    </ThemeProvider>
  );
}

Filters.propTypes = {
  // settings: PropTypes.object.isRequired,
  notifications: PropTypes.array.isRequired
};

const mapStateToProps = (state) => ({
  user: state.user,
  getNotification: PropTypes.func.isRequired,
  deleteNotification: PropTypes.func.isRequired,
  deleteAllNotification: PropTypes.func.isRequired,
  notifications: state.data.notificationList,
});

export default withStyles(
  {},
  { withTheme: true }
)(
  connect(mapStateToProps, {
    getNotification,
    deleteNotification,
    // deleteAllNotification
  })(Filters)
);
