import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import Avatar from "@material-ui/core/Avatar";
import Rating from "@material-ui/lab/Rating";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  details: {
    display: "flex",
    flexDirection: "column",
  },
  title:{
    width: '86px',
    height: '24px',
    fontFamily: 'Open Sans',
    fontSize: '14px',
    fontWeight: '600',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.71',
    letterSpacing: '0.1px',
    color: '#000000',
  },
  cover: {
    width: "100%",
    objectFit: "scale-down",
    borderRadius: "50%",
  },
  large: {
    width: "60px",
    height: "60px",
    margin: theme.spacing(1),
  },
  controls: {
    display: "flex",
    alignItems: "center",
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  button: {
    borderRadius: 5,
    height: "40px",
    fontSize: 14,
    margin: theme.spacing(0),
  },
  main: { 
    display: "flex" 
  },
  c: { 
    position: "absolute", 
    right: 170 
  },
  rating: {
    width: 200,
    display: "flex",
    alignItems: "center",
  },
}));

export default function MediaControlCard(comment) {
  const classes = useStyles();

  console.log(comment)
  return (
    <Card className={classes.root} elevation={0}>
      <Avatar
        alt=""
        src="https://firebasestorage.googleapis.com/v0/b/toowlz.appspot.com/o/no-user.png?alt=media&token=4c4ef840-fada-4119-8fbc-7f853a004687"
        className={classes.large}
      />
      <div className={classes.details}>
        <CardContent className={classes.content}>
          <div className={classes.main}>
            <div className={classes.a}>
              <Typography className={classes.title}>
                {comment.comment.user}
              </Typography>
            </div>
          </div>
          <div className={classes.rating}>
            <Rating
              name="simple-controlled"
              value={comment.comment.reviewRating}
              readOnly
              precision={0.2}
            />
          </div>

          <Typography variant="body2" color="textSecondary">
              {comment.comment.description}
          </Typography>
        </CardContent>
      </div>
    </Card>
  );
}
