import React from "react";
import { Icon, Badge, IconButton, Drawer } from "@material-ui/core";
import { ThemeProvider, withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  productsCart,
  deleteProductFromCart,
} from "../../redux/actions/dataActions";
import ShoppingCartOutlinedIcon from "@material-ui/icons/ShoppingCartOutlined";
import DeleteForeverOutlinedIcon from "@material-ui/icons/DeleteForeverOutlined";

import formatDistance from "date-fns/formatDistance";
import differenceInDays from "date-fns/differenceInDays";

import { Button, Grid } from "@material-ui/core";

let cartListLoaded = false;
let orderLoaded = false;


function ShoppingCart(props) {
  const {
    container,
    theme,
    payment,
    cartList = [],
    productsCart,
    order,
    orderLoad,
    deleteProductFromCart,
    user,
    color,
  } = props;

  const [panelOpen, setPanelOpen] = React.useState(false);
  // const [orderLoad, setOrderLoad] = React.useState(false);


  if (!cartListLoaded) {
    productsCart();
    cartListLoaded = true;
  }


  console.log(payment)

  // if(typeof payment !== 'undefined'){
  //     order();
  // }

  function handleDrawerToggle() {
    setPanelOpen(!panelOpen);
  }

  function getTotalCost() {
    let totalCost = 0;
    cartList.forEach((product) => {
      totalCost +=
        product.amount *
        product.price *
        differenceInDays(
          new Date(product.endDate),
          new Date(product.startDate)
        );
    });
    return totalCost;
  }

  return (
    <ThemeProvider>
      <IconButton onClick={handleDrawerToggle} className={color}>
        <Badge color="secondary" badgeContent={cartList.length}>
          <ShoppingCartOutlinedIcon />
        </Badge>
      </IconButton>

      <Drawer
        container={container}
        variant="temporary"
        anchor={"right"}
        open={panelOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true,
        }}
      >
        <div className="mini-cart">
          <div className="cart__topbar">
            <ShoppingCartOutlinedIcon style={{ maxWidth: "30px" }} />
            <h5 style={{ minWidth: "200px", marginTop: "5px" }}>
              Shopping Cart
            </h5>  
            <h5 style={{ minWidth: "50px", marginTop: "5px" }}>
            </h5>           
          </div>
          <Grid container spacing={0}>
            <Grid item md={12}>
              <Grid container spacing={0}>
                <Grid item md={12}>
                  {cartList.map((product) => (
                    <Grid container spacing={0} style={{ margin:"8px 0px 8px 0px"}}>
                      <Grid item xs={3} sm={3} md={3}>
                        <div
                          style={{ padding: "8px 0px 0px 8px", borderRadius: "8px", width: "80px", height: "80px"}}
                        >
                          <img style={{display: "block", marginLeft: "auto",  marginRight: "auto", padding: "0px", borderRadius: "8px", width: "80px", height: "80px"}}
                          src={product.image} alt="..." />
                        </div>
                      </Grid>

                      <Grid item xs={9} sm={9} md={9}>
                        <div key={product.id} style={{ padding: "0px 8px 0px 8px"}}className="mini-cart__item">
                          <div style={{ width: "180px", textAlign: "left" }}>
                            <h6 style={{ marginBottom: "2px" }}>
                              {product.name}
                            </h6>
                            <small className="text-muted">
                              {formatDistance(
                                new Date(product.startDate),
                                new Date(product.endDate)
                              )}{" "}
                              rental
                            </small>
                          </div>
                          <div style={{ textAlign: "right" }}>
                            <IconButton
                              size="small"
                              onClick={() =>
                                deleteProductFromCart(product.productId)
                              }
                            >
                              <DeleteForeverOutlinedIcon fontSize="small" />
                            </IconButton>
                          </div>
                          <div style={{ width: "60px", textAlign: "right" }}>
                            <h6 style={{ marginBottom: "2px" }}>
                              $
                              {parseFloat(product.price).toFixed(2) *
                                differenceInDays(
                                  new Date(product.endDate),
                                  new Date(product.startDate)
                                )}
                            </h6>
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
                <Grid item md={12} sm={12} xs={12} style={{ marginTop: "10px", padding:"0px 8px 0px 8px",width: "100%"}}>
                {cartList.length !== 0 ? (
                  <div className="cart__item">
                    <div style={{ textAlign: "left" }}>
                      <h6 style={{ marginBottom: "2px" }}>Subtotal</h6>
                    </div>
                    <div style={{ textAlign: "right" }}>
                      <h6 style={{ marginBottom: "2px" }}>
                        ${getTotalCost().toFixed(2)}
                      </h6>
                    </div>
                  </div>):(null)}
                </Grid>
                <Grid item md={12} sm={12} xs={12} style={{ width: "100%", padding: "10px"}}>

                {cartList.length !== 0 ? (
                  <Button
                    fullWidth
                    variant="contained"
                    color="secondary"
                    href="/checkout"
                  >
                    Checkout
                  </Button>
                ):(
                  null
                )
                }
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {/* {cartList.map(product => (
            <div
              key={product.id}
              className="mini-cart__item"
            >
              <div  style={{ marginRight: '2px' }}>
                <img src={product.image} alt="..." />
              </div>
              <div style={{ marginLeft: '3px' , textAlign: 'left' }} >
                <h6 style={{ marginBottom: '2px' }}>{product.name}</h6>
                <small className="text-muted">
                  price: {product.price}
                </small>
              </div>

              <IconButton
                size="small"
                onClick={() => deleteProductFromCart(product.productId)}
              >
                <DeleteForeverOutlinedIcon fontSize="small"/>
              </IconButton>
            </div>
          ))} */}
        </div>
      </Drawer>
    </ThemeProvider>
  );
}

ShoppingCart.propTypes = {
  // settings: PropTypes.object.isRequired,
  cartList: PropTypes.array.isRequired,
  payment: PropTypes.string.isRequired
};

const mapStateToProps = (state) => ({
  // settings: state.layout.settings,
  productsCart: PropTypes.func.isRequired,
  deleteProductFromCart: PropTypes.func.isRequired,
  // updateCartAmount: PropTypes.func.isRequired,
  cartList: state.data.cartList,
  user: state.user,
});

export default withStyles(
  {},
  { withTheme: true }
)(
  connect(mapStateToProps, {
    productsCart,
    deleteProductFromCart,
  })(ShoppingCart)
);
