import React, { Fragment } from "react";
import {
  TextField,
  Switch,
  Grid,
  Typography,
  FormControlLabel,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Slider,
  Checkbox,
  Fab,
  Button,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  FormControl,
  FormGroup,
  Hidden,
} from "@material-ui/core";

import Autocomplete from '@material-ui/lab/Autocomplete';
import Rating from "@material-ui/lab/Rating";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import SearchIcon from "@material-ui/icons/Search";

import InputMask from "react-input-mask";

import DateFnsUtils from "@date-io/date-fns"; // import
import { makeStyles, withStyles } from "@material-ui/core/styles";

import CircularProgress from '@material-ui/core/CircularProgress';


// import Checkbox from '@material-ui/core/Checkbox';
// import TextField from '@material-ui/core/TextField';
// import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  list: {
    // width: '100%',
    minWidth: 200,
    backgroundColor: theme.palette.background.paper,
    // position: 'relative',
    overflow: 'auto',
    maxHeight: 100,
  },
  summary:{
    // padding: theme.spacing(0),
    // minHeight: "24px",
    // maxHeight: "36px"

  },
  heading: {
    display: "flex",
    marginBottom: theme.spacing(0),
    padding: theme.spacing(0),
    fontFamily: "Open Sans",
    fontSize: "20px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.2,
    letterSpacing: "0.15px",
    color: "rgba(0, 0, 0)",
    alignItems: "center",
    justifyContent: "left",
    textAlign: "left",
  },
  card: {
    display: "flex",
    marginLeft: "auto",
    marginRight: "auto",
    maxWidth: "280px",
    marginTop: theme.spacing(10),
    // paddingRight: theme.spacing(2),
    // paddingTop: theme.spacing(0.6),
    // paddingBottom: theme.spacing(0.6),
  },
  cardcontent: {
    //margin: '4px',
    padding: "30px",
    "&:last-child": {
      paddingBottom: "30px",
    },
  },
  button: {
    borderRadius: 5,
    height: "48px",
    fontSize: 14,
    margin: theme.spacing(0),
  },
  title: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  h5: {
    display: "flex",
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(1),
    padding: theme.spacing(0),
    fontFamily: "Open Sans",
    fontSize: "24px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1,
    letterSpacing: "0.18px",
    color: "rgba(0, 0, 0, 0.87)",
    alignItems: "center",
    justifyContent: "left",
    textAlign: "left",

    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  more: {
    display: "flex",
    fontFamily: "Open Sans",
    fontSize: "12px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: " normal",
    lineHeight: "1.33",
    letterSpacing: "0.4px",
    color: "#000000",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
  },
  background: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 62,
    bottom: 0,
    minHeight: 990,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    zIndex: -10,
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
  padding:{
    padding: 0
  },
  input:{
    padding: 1,
    fontFamily: "Open Sans",
    fontSize: "14px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: " normal",
    lineHeight: "1.2",
    letterSpacing: "0.1px",
    color: "darkgray",
    alignItems: "left",
    justifyContent: "left",
    textAlign: "left",
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  root: {
    borderRight: "solid 1px lightgray",
    [theme.breakpoints.down("sm")]: {
      borderRight: "solid 0px white",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: "center",
      color: theme.palette.text.secondary,
    },
  },
}));

function sleep(delay = 0) {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
}


const ShopSidenav = ({
  query,
  from,
  to,
  zip,
  categories,
  brands,
  multilevel,
  categoryList,
  brandList,
  productNameList,
  ratingList,
  shipping,
  delivery,
  sliderRange,
  productName,
  toggleSidenav,
  handleSearch,
  handleMultilevelChange,
  handleChangeZip,
  handleChangeDateFrom,
  handleChangeDateTo,
  handleSliderChange,
  handleCategoryChange,
  handleBrandChange,
  handleProductNameChange,
  handleRatingClick,
  handleFreeShippingClick,
  handleFreeDeliveryClick,
  handleClearAllFilter,
}) => {
  const classes = useStyles();


  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const loading = open && options.length === 0;

  React.useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      if (active) {
        setOptions(productNameList);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);


  return (
    <Grid container className={classes.root} style={{maxWidth:"300px", padding: "20px 20px 50px 20px", minHeight:"880px"}} spacing={5}>
      <Grid item sm={12} xs={12}>
        <Grid container spacing={2}>
          <Grid item sm={12} xs={12}>
            <Typography
              color="inherit"
              align="left"
              variant="h4"
              marked="left"
              className={classes.h5}
            >
              Filter by
            </Typography>
          </Grid>
          <Grid item sm={12} xs={12} style={{padding:'0'}}>
            <Grid container spacing={3}>
              <Grid item sm={12} xs={12} >
                <ExpansionPanel name="panel1" elevation={0} defaultExpanded={true}>
                  <ExpansionPanelSummary
                    style={{minHeight: '36px', maxHeight:'36px', padding:'0'}}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography className={classes.heading}>Date</Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails
                    style={{padding:'0'}}
                  >
                    <Grid item sm={6}>
                    <MuiPickersUtilsProvider
                  utils={DateFnsUtils}
                  className={classes.margin}
                >
                  <KeyboardDatePicker
                    disableToolbar={true}
                    name="from"
                    // variant="inline"
                    variant="filled"
                    inputVariant="outlined"
                    autoOk
                    format="MM/dd/yyyy"
                    id="date-picker-inline"
                    placeholder="From"
                    value={from? from :null }
                    className={classes.margin}
                    onChange={handleChangeDateFrom}
                    size="small"
                    minDate={new Date()}
                    style={{marginRight:"5px", borderWidth: "0px"}}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                      className: classes.padding
                    }}
                    InputProps={{ className: classes.input, borderWidth: "0px", }}
                    FieldSetProps={{ className: classes.input }}
                  />
                </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item sm={6}>
                    <MuiPickersUtilsProvider
                  utils={DateFnsUtils}
                  className={classes.margin}
                >
                  <KeyboardDatePicker
                    disableToolbar={true}
                    name="to"
                    variant="inline"
                    inputVariant="outlined"
                    autoOk
                    format="MM/dd/yyyy"
                    id="date-picker-inline"
                    placeholder="To"
                    minDate={from}
                    value={to? to :null}
                    className={classes.margin}
                    onChange={handleChangeDateTo}
                    size="small"
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                      className: classes.padding
                    }}
                    InputProps={{ className: classes.input }}
                  />
                </MuiPickersUtilsProvider>
                    </Grid>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              </Grid>
              <Grid item sm={12} xs={12} >
                <ExpansionPanel name="panel1" elevation={0} defaultExpanded={true}>
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    className={classes.summary}
                    style={{minHeight: '36px', maxHeight:'36px', padding:'0'}}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography className={classes.heading}>Zip Code</Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails
                                      style={{padding:'0'}}>
                  
                  
                  <TextField
                      className={classes.margin}
                      id="outlined-helperText"
                      fullWidth
                      placeholder="Zip Code"
                      variant="outlined"
                      name="zip"
                      label={zip}
                      InputLabelProps={{shrink: false}}
                      onChange={handleChangeZip}
                      size="small"
                      InputLabelProps={{
                        classes: {
                          root: classes.cssLabel,
                          focused: classes.cssFocused,
                        },
                      }}
                      InputProps={{
                        classes: {
                          root: classes.cssOutlinedInput,
                          focused: classes.cssFocused,
                          notchedOutline: classes.notchedOutline,
                          input: classes.resizeLocation,
                        },
                        inputMode: "numeric",
                      }}
                    />



                  </ExpansionPanelDetails>
                </ExpansionPanel>
              </Grid>
              <Grid item sm={12} xs={12} >
                <ExpansionPanel name="panel1" elevation={0} defaultExpanded={true}>
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    className={classes.summary}
                    style={{minHeight: '36px', maxHeight:'36px', padding:'0'}}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography className={classes.heading}>Product</Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails
                                      style={{padding:'0'}}>
                  
                  
                  <Autocomplete
                      id="asynchronous-demo"
                      style={{ width: 290 }}
                      open={open}
                      onOpen={() => {
                        setOpen(true);
                      }}
                      onClose={() => {
                        setOpen(false);
                      }}
                      onChange={(event, value) => handleProductNameChange(event, value)}
                      getOptionSelected={(option, value) => option.name === value.name}
                      getOptionLabel={(option) => option.name}
                      options={options}
                      loading={loading}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={productName}
                          variant="outlined"
                          InputProps={{
                            ...params.InputProps,
                            className: classes.padding,
                            placeholder:"Name",
                            endAdornment: (
                              <React.Fragment>
                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                              </React.Fragment>
                            ),
                          }}
                        />
                      )}
                    />



                  </ExpansionPanelDetails>
                </ExpansionPanel>
              </Grid>
              <Grid item sm={12} xs={12}>
                <ExpansionPanel name="panel1" elevation={0} defaultExpanded={true}>
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    className={classes.summary}
                    style={{minHeight: '36px', maxHeight:'36px', padding:'0'}}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography className={classes.heading}>
                      Delivery
                    </Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails
                                      style={{padding:'0'}}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={delivery}
                          onChange={handleFreeDeliveryClick}
                          name="checkedB"
                          color={delivery ? "secondary" : "inherit"}
                        />
                      }
                      // label="Delivery"
                    />
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              </Grid>
              <Grid item sm={12} xs={12}>
                <ExpansionPanel name="panel1" elevation={0}>
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    className={classes.summary}
                    style={{minHeight: '36px', maxHeight:'36px', padding:'0'}}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography className={classes.heading}>Price</Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails
                                      style={{padding:'0'}}>
                    <Slider
                      value={sliderRange}
                      onChange={handleSliderChange}
                      valueLabelDisplay="auto"
                      aria-labelledby="range-slider"
                      color="secondary"
                      valueLabelFormat={(x) => x * 10}
                    />
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              </Grid>
              <Grid item sm={12} xs={12}>
                <ExpansionPanel name="panel1" elevation={0}>
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    className={classes.summary}
                    style={{minHeight: '36px', maxHeight:'36px', padding:'0'}}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography className={classes.heading}>
                      Category
                    </Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails className={classes.padding}
                                       style={{padding:'0'}}>

                    <Autocomplete
                      id="asynchronous-category"
                      style={{ width: 290 }}
                      multiple
                      size="small"

                      // open={open}
                      // onOpen={() => {
                      //   setOpen(true);
                      // }}
                      // onClose={() => {
                      //   setOpen(false);
                      // }}
                      onChange={(event, newValue) => handleCategoryChange(event, newValue)}
                      getOptionSelected={(option, value) => option.title === value.title}
                      getOptionLabel={(option) => option.title}
                      options={categoryList}
                      // loading={loading}
                      renderInput={(paramsCat) => (
        <TextField {...paramsCat} variant="outlined" placeholder="Category" />
      )}
                    />



                    {/* <FormControl
                      component="fieldset"
                      className={classes.padding}
                    >
                      <FormGroup className={classes.padding}>
                      <List dense className={classes.list}>

                        {categoryList.map((category) => (
                          
                          <ListItem key={category.productId} button>
            <ListItemIcon>
              <Checkbox
                name={category.title}
                edge="end"
                onChange={handleCategoryChange}
                checked={categories.includes(category.title)}
              />
            </ListItemIcon>
            <ListItemText primary={category.title} />
          </ListItem>

                        ))}
                        </List>

                      </FormGroup>
                    </FormControl> */}

                  </ExpansionPanelDetails>
                </ExpansionPanel>
              </Grid>
              <Grid item sm={12} xs={12} >
                <ExpansionPanel name="panel1" elevation={0}>
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    className={classes.summary}
                    style={{minHeight: '36px', maxHeight:'36px', padding:'0'}}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography className={classes.heading}>Brands</Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails
                                      style={{padding:'0'}}>



<Autocomplete
                      id="asynchronous-brands"
                      style={{ width: 290 }}
                      multiple
                      size="small"
                      // open={open}
                      // onOpen={() => {
                      //   setOpen(true);
                      // }}
                      // onClose={() => {
                      //   setOpen(false);
                      // }}
                      onChange={(event, newValue) => handleBrandChange(event, newValue)}
                      getOptionSelected={(option, value) => option.title === value.title}
                      getOptionLabel={(option) => option.title}
                      options={brandList}
                      // loading={loading}
                      renderInput={(paramsCat) => (
        <TextField {...paramsCat} variant="outlined" placeholder="Brand" />
      )}
                    />
                    {/* <FormControl
                      component="fieldset"
                      className={classes.formControl}
                    >
                      <FormGroup>
                        {brandList.map((brand) => (
                          <div
                            key={brand.title}
                            className="flex items-center justify-between"
                          >
                            <FormControlLabel
                              className="flex-grow"
                              name={brand.title}
                              onChange={handleBrandChange}
                              control={
                                <Checkbox
                                  checked={brands.includes(brand.title)}
                                />
                              }
                              label={brand.title}
                            />
                            <small className="badge bg-light-primary text-primary">
                              {brand.product}
                            </small>
                          </div>
                        ))}
                      </FormGroup>
                    </FormControl> */}
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              </Grid>
              <Grid item sm={12} xs={12}>
                <ExpansionPanel name="panel1" elevation={0}>
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    className={classes.summary}
                    style={{minHeight: '36px', maxHeight:'36px', padding:'0'}}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography className={classes.heading}>Rating</Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails
                                      style={{padding:'0'}}>
                    <FormControl
                      component="fieldset"
                      className={classes.formControl}
                    >
                      <FormGroup>
                        {ratingList.map((rating) => (
                          <div
                            key={rating.rate}
                            value={rating.rate}
                            className="flex items-center justify-between cursor-pointer pb-4"
                            onClick={() => handleRatingClick(rating.rate)}
                          >
                            <Rating
                              size="small"
                              name="half-rating"
                              value={rating.rate}
                              precision={0.5}
                              readOnly={true}
                            />
                            <small className="badge bg-light-primary text-primary">
                              {rating.product}
                            </small>
                          </div>
                        ))}
                      </FormGroup>
                    </FormControl>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              </Grid>
              <Grid item sm={12}>
                {/* <Button
                  className="w-full"
                  variant="contained"
                  color="primsecondaryary"
                  onClick={handleClearAllFilter}
                >
                  Clear All Filteres
                </Button> */}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ShopSidenav;
