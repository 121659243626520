import AppBar from "@material-ui/core/AppBar";
import IconButton from "@material-ui/core/IconButton";
import { withStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";

import { connect } from "react-redux";
import PropTypes from "prop-types";

import { Link } from "react-router-dom";

import MenuItem from "@material-ui/core/MenuItem";
import Icon from '@material-ui/core/Icon';
import Menu from "@material-ui/core/Menu";
import AccountCircleOutlinedIcon from "@material-ui/icons/AccountCircleOutlined";
import MailOutlinedIcon from "@material-ui/icons/MailOutlined";
import ShoppingCartOutlinedIcon from "@material-ui/icons/ShoppingCartOutlined";

import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import Badge from '@material-ui/core/Badge';
import SearchIcon from '@material-ui/icons/Search';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MailIcon from '@material-ui/icons/Mail';
import NotificationsIcon from '@material-ui/icons/Notifications';
import MoreIcon from '@material-ui/icons/MoreVert';


import React, { Component } from "react";
import { logoutUser, getUserData } from "../../redux/actions/userActions";
import { productsCart } from "../../redux/actions/dataActions";


import AdSense from 'react-adsense-ad';

import * as _ from "lodash";

import ads from "../../images/google-ads-logo.svg";



class BlogNavBar extends Component {

    // Set some state
    state = {
      setAnchorEl: "",
      mobileMoreAnchorEl: "",
      setMobileMoreAnchorEl: "",
      isMenuOpen: false,
      isMobileMenuOpen: false,
      open: false,
      auth: true,
      user: {
        authenticated: false,
        userhandle: "",
      },
      anchorEl: null,
      openMenu: true,
    };

  componentWillMount() {
    //console.log( this.props);

    this.props.getUserData();

    //this.props.productsCart("Ariel");
  }

  componentWillReceiveProps(nextProps) {
    console.log( nextProps);

    if (nextProps.user) {
      this.setState({ user: nextProps.user });
    }
  }

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleCloseMenu = () => {
    this.setState({ anchorEl: null });
  };

  handleLogout = () => {
    this.props.logoutUser();
    this.setState({ anchorEl: null });
    window.location.href = "/login";
  };

  openNewTool = (event) => {
    event.preventDefault();
    this.setState({
      open: true,
    });
  };

  handleClose = () => {
    this.setState({
      open: false,
    });
  };

  render() {
    const { classes, handle, isTransparent, mostLiked, mostShared } = this.props;

    const { open, anchorEl, user } = this.state;

    console.log(user)
    console.log("Is Transparent", isTransparent)

    let backgroundColor = isTransparent ? "transparent" : "primary";

    let color = isTransparent ? classes.WButton : classes.BButton;


    let MenuItemSession = !user.authenticated ? (
      <MenuItem onClick={this.handleLogout}>Login</MenuItem>
    ) : (
      <MenuItem onClick={this.handleLogout}>Logout</MenuItem>
    );

    return (
      <>
        <div className={classes.ads}>

                    {/* <AdSense.Google
                        client='Your Publisher Id'
                        slot='Ad slot Id'
                        style={{ width: 150, height: 200, float: 'left' }}
                        format=''
                      /> */}
          </div>
      <div className={classes.root} style={{paddingTop: "30px"}}>
        <AppBar style= {{ alignItems: "center"}} color={backgroundColor} align="center" elevation={0} position="static">
          <Toolbar  align="center" className={classes.toolBar}>           
            <div className={classes.sectionDesktop}>              
              <Link
                className={`${color} ${classes.marginR}`}
                tip="ALL"
                onClick={() => {
                  window.location.href = "/blog";
                }}
                color="inherit"
              >
                ALL
              </Link>
              <Link
                className={`${color} ${classes.marginR}`}
                tip="LATEST"
                onClick={() => {
                  window.location.href = "/blog/latest";
                }}
                color="inherit"
              >
                LATEST
              </Link>

              {/* <PostTool /> */}

              <Link
                className={`${color} ${classes.marginR}`}
                tip="Most Liked"
                onClick={() => {
                  window.location.href = "/blog/mostliked";
                }}
                color="inherit"
              >
                MOST LIKED
              </Link>

              <Link
                className={`${color} ${classes.marginR}`}
                tip="MOST SHARED"
                onClick={() => {
                  window.location.href = "/blog/mostshared";
                }}
                color="inherit"
              >
                MOST SHARED
              </Link>

              <Link
                className={`${color} ${classes.marginR}`}
                tip="VIDEOS"
                onClick={() => {
                  window.location.href = "/blog/videos";
                }}
                color="inherit"
              >
                VIDEOS
              </Link>

              <Link
                className={`${color} ${classes.marginR}`}
                tip="PODCAST"
                onClick={() => {
                  window.location.href = "/blog/podcast";
                }}
                color="inherit"
              >
                PODCAST
              </Link>

              <Link
                className={`${color} ${classes.marginR}`}
                tip="ARTICLES"
                onClick={() => {
                  window.location.href = "/blog/articles";
                }}
                color="inherit"
              >
                ARTICLES
              </Link>

             
            </div>
          </Toolbar>
        </AppBar>
      </div>
      </>
    );
  }
}

const styles = (theme) => ({
  root: {
    marginTop: 110,
    marginBottom: 40,    
    spacing: 22,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  ads: { 
  border: "0px",
  verticalAlign: "bottom",
  width: "980px",
  height: "120px",
  position: "absolute",
  left: "50%",
  marginLeft: "-490px",
  top: "20%",
  marginTop: "-45px",
  backgroundColor:"gray",
  [theme.breakpoints.down("sm")]: {
    display: "none",
  },
},
  fakeAds: {
    backgroundImage: `url(${ads})`,
  },
  grow: {
    flexGrow: 1,
  },
  menu: {
    top: "80px",
  },
  toolBar: {
    minHeight: 62,
    alignItems:"center",
    //backgroundColor: "transparent",
  },
  marginR: {
    marginRight: 22,
  },
  marginL: {
    marginLeft: 22,
  },
  WButton: {
    fontFamily: "Open Sans",
    fontSize: 14,
    color: "white",
    fontWeight: 600,
    fontStretch: "normal",
    fontStyle: "normal",
    letterSpacing: "1.25px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textTransform: "uppercase",
  },
  BButton: {
    fontFamily: "Open Sans",
    fontSize: 14,
    color: "black",
    fontWeight: 600,
    fontStretch: "normal",
    fontStyle: "normal",
    letterSpacing: "1.25px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textTransform: "uppercase",
  },
  sectionDesktop: {
    display: "none",
    alignItems: "center",
    
    spacing: 22,
    [theme.breakpoints.up("md")]: {
      display: "flex",
      alignItems: "center",
    },
  },
  sectionMobile: {
      display: 'flex',
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
  }
});

const mapStateToProps = (state) => ({
  user: state.user,
  cartList: state.data.cartList
});

BlogNavBar.propTypes = {
  classes: PropTypes.object.isRequired,
  getUserData: PropTypes.func.isRequired,
  productsCart: PropTypes.func.isRequired,
  logoutUser: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired
};

export default connect(mapStateToProps, { logoutUser, getUserData, productsCart })(
  withStyles(styles)(BlogNavBar)
);