import React from 'react';
import PropTypes from 'prop-types';
import DetailProfile from './DetailProfile'
// MUI
import Grid from '@material-ui/core/Grid';


const StaticProfile = (props) => {
  const {
    user,
  } = props;

  return (
    <Grid container spacing={5}>
      <Grid item sm={12}>
        <DetailProfile user={user} />
      </Grid>
    </Grid>
  );
};

StaticProfile.propTypes = {
  user: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired
};


export default (StaticProfile);
