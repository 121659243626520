import React, { Fragment } from "react";
import {
  Grid,
  Typography,
  TextField,
  InputLabel,
  InputAdornment,
  FormControl,
  Select,
  MenuItem,
  IconButton,
  Button,
  TablePagination,
} from "@material-ui/core";


import Chip from '@material-ui/core/Chip';
import Paper from '@material-ui/core/Paper';
import TagFacesIcon from '@material-ui/icons/TagFaces';

import ListAltOutlined from "@material-ui/icons/ListAltOutlined";
import MapOutlinedIcon from "@material-ui/icons/MapOutlined";
import ListIcon from '@material-ui/icons/List';

import { makeStyles, withStyles } from "@material-ui/core/styles";

import PropTypes from 'prop-types';


import * as _ from "lodash";

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'left',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0),
    margin: 1,
  },
  chip: {
    padding: theme.spacing(0),
    margin: theme.spacing(0.5),
    fontFamily: "Open Sans",
    fontSize: "12px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: " normal",
    lineHeight: "1.2",
    letterSpacing: "0.1px",
    color: "#217cbd",
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9,
  },
  rating: {
    width: 200,
    display: "flex",
    alignItems: "center",
  },
  input: {
    padding: 7,
    fontFamily: "Open Sans",
    fontSize: "12px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: " normal",
    lineHeight: "1.2",
    letterSpacing: "0.1px",
    color: "darkgray",
    alignItems: "left",
    justifyContent: "left",
    textAlign: "left",
    maxWidth: "129px"
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      {value === index && (
        <div >
          {children}
        </div>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

export default TabPanel;
