import React, {Fragment} from "react";
//import { ToolDetailInfo } from './ToolDetailInfo';
import PropTypes from "prop-types";
import { RentalMap } from "../components/rental/RentalMap";
import { connect } from "react-redux";
import {
  getTool,
  productsCart,
  deleteProductFromCart,
} from "../redux/actions/dataActions";

import formatDistance from 'date-fns/formatDistance'

import withStyles from "@material-ui/core/styles/withStyles";

import Navbar from "../components/layout/Navbar";
import OrdersGoogleMap from "../components/rental/OrdersGoogleMap";


// MUI Stuff
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import {
  Box,
  IconButton,
} from "@material-ui/core";

import DeleteForeverOutlinedIcon from "@material-ui/icons/DeleteForeverOutlined";

import Rating from "@material-ui/lab/Rating";

class Checkout extends React.Component {
  constructor() {
    super();
    this.state = {
      user: {},
      email: "",
      password: "",
      profile: false,
      errors: {},
      image: "",
      toolId: "",
      price: 0,
    };
  }

  componentWillMount() {
    this.props.productsCart();
  }

  getTotalCost = () => {
    let { cartList = [] } = this.props;
    let totalCost = 0;
    cartList.forEach(product => {
      totalCost += product.amount * product.price;
    });
    return totalCost;
  };

  render() {

    let { classes, tool, cartList } = this.props;
    console.log("CartList Check: ", cartList)

    // if (tool.toolId) {
    return (
      <Fragment>
      <Navbar />
      <Grid container style={{ marginTop: "50px" }} spacing={3}>
        <Grid item md={12} sm={12} style={{ width: "100%"}}>
          <Typography className={classes.title}>Order Sumary</Typography>
        </Grid>
        <Grid item md={12} sm={12} style={{ width: "100%"}}>
          <Typography
            variant="body2"
            color="textSecondary"
            className={classes.description}
          >
            {cartList.length ? cartList.length : null} tools in cart
          </Typography>
        </Grid>
        <Grid item md={8} sm={12} style={{ width: "100%"}}>
          <Grid container spacing={2}>
            <Grid item md={12} sm={12} style={{ width: "100%"}}>
              <div className={classes.root}>
                <Grid container spacing={3}>
                  <Grid item md={12} sm={12} style={{ width: "100%"}}>
                    {cartList.map((product) => (
                      <Grid container spacing={2}>
                        <Grid item md={2} sm={2} style={{}}>
                          <div style={{ padding: "0px", borderRadius: "8px", width: "90px", height: "90px"}}>
                            <img style={{display: "block", marginLeft: "auto",  marginRight: "auto", padding: "0px", borderRadius: "8px", width: "90px", height: "90px"}} 
                                  src={product.image} alt="..." />
                          </div>
                        </Grid>

                        <Grid item md={10} sm={10} style={{paddingLeft: "0px" }}>
                          <div style={{alignItems: "baseline" }} key={product.id} className="cart__item">
                            <div style={{ marginRight: "0px", textAlign: "left", lignItems: "baseline", minWidth:"130px" }}>
                              <h6
                                className={classes.subtitle}
                                style={{ marginBottom: "2px" }}
                              >
                                {product.name}
                              </h6>
                              <div className={classes.rating}>
                                <Rating
                                  name="simple-controlled"
                                  value={`${product.rating}`}
                                  readOnly
                                  precision={0.5}
                                />
                                <Box ml={2}>({`${product.noc}`})</Box>
                              </div>
                              <small className="text-muted">
                              {formatDistance(new Date(product.startDate), new Date(product.endDate))} rental
                              </small>

                                <div  className={classes.mobile} style={{ position:"relative", right: "-10px",   float: "right", marginRight: "0px", textAlign: "right", lignItems: "baseline" }}>
                                  <div style={{display:"flex", justifyContent: "space-between"}}>
                                  <div>
                                    <h6 style={{ marginBottom: "2px", width: "50px"  }}>
                                      Price
                                    </h6>
                                  </div>
                                  <div>
                                    <h6 style={{ marginLeft:"10px", marginBottom: "2px" }}>
                                      -
                                    </h6>
                                  </div>
                                  <div>
                                    <h6 style={{ marginBottom: "2px", width: "70px" }}>
                                      ${parseFloat(product.price).toFixed(2)}
                                    </h6>
                                  </div>
                                </div>
                                <div style={{display:"flex", justifyContent: "space-between"}}>
                                  <div>
                                    <h6 style={{ marginBottom: "2px", width: "50px"  }}>
                                      Tax
                                    </h6>
                                  </div>
                                  <div>
                                    <h6 style={{ marginLeft:"10px", marginBottom: "2px" }}>
                                      -
                                    </h6>
                                  </div>
                                  <div>
                                    <h6 style={{ marginBottom: "2px", width: "70px"  }}>
                                      $0.00
                                    </h6>
                                  </div>
                                </div>
                                  <IconButton
                                    size="small"
                                    onClick={() =>
                                      this.props.deleteProductFromCart(
                                        product.productId
                                      )
                                    }
                                  >
                                    <DeleteForeverOutlinedIcon fontSize="small" />
                                  </IconButton>
                                </div>
                            </div>

                            <div  className={classes.desktop} style={{ marginRight: "0px", textAlign: "right", lignItems: "baseline" }}>
                              <div style={{display:"flex", justifyContent: "space-between"}}>
                                <div>
                                  <h6 style={{ marginBottom: "2px", width: "50px"  }}>
                                    Price
                                  </h6>
                                </div>
                                <div>
                                  <h6 style={{ marginLeft:"10px", marginBottom: "2px" }}>
                                    -
                                  </h6>
                                </div>
                                <div>
                                  <h6 style={{ marginBottom: "2px", width: "70px" }}>
                                    ${parseFloat(product.price).toFixed(2)}
                                  </h6>
                                </div>
                              </div>
                              <div style={{display:"flex", justifyContent: "space-between"}}>
                                <div>
                                  <h6 style={{ marginBottom: "2px", width: "50px"  }}>
                                    Tax
                                  </h6>
                                </div>
                                <div>
                                  <h6 style={{ marginLeft:"10px", marginBottom: "2px" }}>
                                    -
                                  </h6>
                                </div>
                                <div>
                                  <h6 style={{ marginBottom: "2px", width: "70px"  }}>
                                    $0.00
                                  </h6>
                                </div>
                              </div>
                              <IconButton
                                size="small"
                                onClick={() =>
                                  this.props.deleteProductFromCart(
                                    product.productId
                                  )
                                }
                              >
                                <DeleteForeverOutlinedIcon fontSize="small" />
                              </IconButton>
                            </div>
                          </div>
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                  <Grid item md={12}  sm={12} style={{ width: "95%"}}>
                  <Grid container spacing={2}>
                        <Grid item md={12}  sm={12} style={{ width: "100%"}}>
                          <div className="cart__item" style={{ width: "100%"}}>
                            <div
                              style={{ marginRight: "2px", textAlign: "left" }}
                            >
                              <h6
                                className={classes.subtitle}
                                style={{ marginBottom: "2px" }}
                              >
                                Subtotal
                              </h6>
                            </div>
                            <div
                              style={{ marginRight: "2px", textAlign: "right" }}
                            >
                              <h6 style={{ marginBottom: "2px" }}>
                                ${this.getTotalCost().toFixed(2)}
                              </h6>
                            </div>
                          </div>
                        </Grid>
                      </Grid>
                  </Grid>              
                </Grid>
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={4} sm={12} style={{ width: "100%"}}>
          <Grid container spacing={2}>
            <Grid item md={12} sm={12} style={{ width: "100%"}}>
                                  {
                                    cartList.length !== 0 ? <OrdersGoogleMap productList={cartList}></OrdersGoogleMap> : null
                                  }
            </Grid>
            <Grid item sm={12} style={{ width: "100%", marginBottom:"20px"}}>
              <Button
                type="submit"
                variant="contained"
                color="secondary"
                fullWidth
                href="/order"
              >
                Checkout
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      </Fragment>
    );
    // } else {
    //   return (
    //     <Backdrop>
    //       <CircularProgress color="secondary" />
    //     </Backdrop>
    //   );
    // }
  }
}

function mapStateToProps(state) {
  return {
    tool: state.data.tool,
    cartList: state.data.cartList,
  };
}
const mapActionsToProps = {
  getTool,
  productsCart,
  deleteProductFromCart,
};

Checkout.propTypes = {
  tool: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

const styles = (theme) => ({
  card: {
    padding: "30px 67px",
  },
  grow: {
    flexGrow: 1,
  },
  media: {
    height: 0,
    paddingTop: "480px",
    backgroundSize: "contain", // or cover
  },
  rating: {
    width: 200,
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {      
      display: "none",
    }
  },
  desktop:{
    display: "block",
    [theme.breakpoints.down("sm")]: {      
      display: "none",
    },
  },

  mobile:{
    display: "none",
    [theme.breakpoints.down("sm")]: {      
      display: "block",
    },
  },
  title: {
    maxWidth: "270px",
    fontFamily: "Open Sans",
    fontSize: "24px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1,
    letterSpacing: " 0.18px",
    color: "#000000",
  },
  subtitle: {
    fontFamily: "Open Sans",
    fontSize: "16px",
    fontWeight: "600",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.5",
    letterSpacing: "0.15px",
    color: "#000000",
    marginRight: "5px",
  },
  description: {
    fontFamily: "Open Sans",
    fontSize: "16px",
    fontWeight: "600",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.5",
    letterSpacing: "0.15px",
  },
  text: {
    fontFamily: "Open Sans",
    fontSize: "16px",
    fontWeight: "600",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.5",
    letterSpacing: "0.15px",
  },
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    flexWrap: "nowrap",
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: "translateZ(0)",
  },
  titleBar: {
    background:
      "linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
  },
  main: {
    display: "flex",
  },
  left: {
    position: "absolute",
    right: 180,
  },
});

export default connect(
  mapStateToProps,
  mapActionsToProps
)(withStyles(styles)(Checkout));
