import React, { Component, Fragment } from 'react';
import Navbar from "../components/layout/Navbar";


import withStyles from "@material-ui/core/styles/withStyles";

// MUI Stuff
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  ExpansionPanel,
} from "@material-ui/core";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";


class Faq extends React.Component {
  constructor() {
    super();
    this.state = {
      user: {},
      email: "",
      password: "",
      profile: false,
      errors: {},
      image:"",
      toolId:"",
      price:0,
      rating:0,
      noc:0
    };
  }

  render() {

    let { classes, } = this.props;
      return (
        <Fragment>
        <Navbar />
        <Grid container style={{ marginTop: "50px" }} spacing={1}>
          <Grid item sm={12}>
            <Grid container spacing={2}>
              <Grid item sm={12}>
                <Grid container spacing={2}>
                  <Grid item sm={12}>
                    <ExpansionPanel name="panel1" elevation={0}>
                      <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        >
                            <Typography
                                color="inherit"
                                align="left"
                                variant="h4"
                                marked="left"
                                className={classes.h5}
                              >
                              ABOUT TOOLWLS
                            </Typography>
                        </ExpansionPanelSummary>
                      <ExpansionPanelDetails>
                        <Grid container>
                          <Grid item sm={12}>
                            <Typography className={classes.title}>What is Toowlz?</Typography>
                          </Grid>
                          <Grid item sm={12}>
                            <Typography variant="body2" className={classes.subtitle}>
                                Toowlz is a marketplace that connects people that own tools and equipment
                                to people who want to rent those tools. It’s that simple! Your next project has never been
                                closer or cheaper to complete.
                            </Typography>
                          </Grid>
                          <Grid item sm={12}>
                            <Typography className={classes.title}>Do I need to register to use Toowlz?</Typography>
                          </Grid>
                          <Grid item sm={12}>
                            <Typography variant="body2" className={classes.subtitle}>
                              Anybody can search for tools without registering for an
                              account. To protect everyone and ensure a secure transaction you must register in order to
                              post tools for rent or if you are renting tools.
                            </Typography>
                          </Grid>
                          <Grid item sm={12}>
                            <Typography className={classes.title}>How does it work? </Typography>
                            </Grid>
                          <Grid item sm={12}>
                            <Typography variant="body2" className={classes.subtitle}>
                              If you have tools and equipment you would like to rent (ladders, trailers,
                              lawnmowers, saws, pressure washers, skid steers, etc.) all you need to do is create an account
                              and start adding your equipment. It is highly recommended you include quality photographs
                              and a detailed description for each item. You can even include a video that shows how to
                              operate the equipment. <br/>
                              If you need a tool, simply search for the product you need. You can search by equipment type,
                              brand, distance from your home, and many other options to find exactly what you need for
                              your project.
                            </Typography>
                          </Grid>
                        </Grid>
                      </ExpansionPanelDetails>
                    </ExpansionPanel>
                  </Grid>
                  <Grid item sm={12}>
                  <ExpansionPanel name="panel1" elevation={0}>
                      <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        >
                          <Typography
                            color="inherit"
                            align="left"
                            variant="h4"
                            marked="left"
                            className={classes.h5}
                          >
                            EQUIPMENT SUPPORT
                          </Typography>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails>
                      <Grid container>
                        <Grid item sm={12}>
                          <Typography className={classes.title}>What if I have questions about the equipment? </Typography>
                        </Grid>
                        <Grid item ={12}>
                          <Typography variant="body2" className={classes.subtitle}>
                            You can communicate confidentially
                            through the Toowlz communication channel to ask any questions you may have before
                            submitting the rental request.
                          </Typography>
                        </Grid>
                        <Grid item sm={12}>
                        <Typography className={classes.title}>What if I need delivery?</Typography>
                        </Grid>
                        <Grid item sm={12}>
                          <Typography variant="body2" className={classes.subtitle}>
                            When owners post equipment they will indicate whether they can
                            provide delivery/pick-up and what the additional charge will be for the service. You can
                            adjust your search to only include tools and equipment that have delivery available.
                          </Typography>
                        </Grid>
                      </Grid>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                  </Grid>
                  <Grid item sm={12}>
                    <ExpansionPanel name="panel1" elevation={0}>
                      <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        >
                        <Typography
                      color="inherit"
                      align="left"
                      variant="h4"
                      marked="left"
                      className={classes.h5}
                    >
                      PAYMENT PROCESS
                    </Typography>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails>
                        <Grid container>
                        <Grid item sm={12}>
                          <Typography className={classes.title}>How much does it cost?</Typography>
                        </Grid>
                        <Grid item sm={12}>
                          <Typography variant="body2" className={classes.subtitle}>
                            There is no cost to list your tools on the site. A service charge of
                            12.5% is applied to all confirmed rentals. The cost to rent a tool is set by the owner of the
                            equipment and varies based on type of tool and duration you are renting.
                          </Typography>
                        </Grid>
                        <Grid item sm={12}>
                          <Typography className={classes.title}>Can I pay in cash when I get the tool? </Typography>
                        </Grid>
                        <Grid item sm={12}>
                        <Typography variant="body2" className={classes.subtitle}>
                          Only electronic forms of payment are accepted to
                          ensure the integrity and security of the transaction.
                        </Typography>
                      </Grid>
                      <Grid item sm={12}>
                        <Typography className={classes.title}>What is the security deposit?</Typography>
                        </Grid>
                        <Grid item sm={12}>
                          <Typography variant="body2" className={classes.subtitle}>
                            TheA small security deposit is he ld in the event there are any
                            damages or issues with the equipment when returned. Assuming there are no damages, the
                            security deposit is refunded when the tool is returned.
                          </Typography>
                        </Grid>
                        <Grid item sm={12}>
                          <Typography className={classes.title}>What if I don’t use the tool?</Typography>
                        </Grid>
                        <Grid item sm={12}>
                          <Typography variant="body2" className={classes.subtitle}>
                            If the tool(s) is received the owner has fulfilled their obligation
                            and the rental charge will be applied.
                          </Typography>
                        </Grid>
                      </Grid>
                    </ExpansionPanelDetails>
                    </ExpansionPanel>
                  </Grid>
                  <Grid item sm={12}>
                  <ExpansionPanel name="panel1" elevation={0}>
                      <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        >
                    <Typography
                      color="inherit"
                      align="left"
                      variant="h4"
                      marked="left"
                      className={classes.h5}
                    >
                      GENERAL
                    </Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      <Grid container>
                        <Grid item sm={12}>
                          <Typography className={classes.title}>Can I provide ratings? </Typography>
                        </Grid>
                        <Grid item sm={12}>
                          <Typography variant="body2" className={classes.subtitle}>
                            Yes! The community depends on everyone providing great equipment
                            and service. All owners can provide ratings for renters, and renters can rate their experience
                            with the equipment owner.
                          </Typography>
                        </Grid>
                        <Grid item sm={12}>
                          <Typography className={classes.title}>What if the tool gets damaged?</Typography>
                        </Grid>
                        <Grid item sm={12}>  
                          <Typography variant="body2" className={classes.subtitle}>
                            Though it’s unlikely, things happen. Sometimes a tool may
                            simply break (e.g., a belt gives out) or there is accidental damage (e.g., someone drops a tool).
                            In these instances it is best to first try to find a resolution directly. If this does not work,
                            Toowlz also has a dispute resolution process &lt;include embedded link&gt; to resolve the issue. In
                            addition, all rentals on Toowlz have the option to add insurance, which we recommend.
                          </Typography>
                        </Grid>
                      <Grid item sm={12}>
                        <Typography className={classes.title}>What if I don’t use the tool?</Typography>
                        </Grid>
                        <Grid item sm={12}>
                          <Typography variant="body2" className={classes.subtitle}>If the tool(s) is received the owner has fulfilled their obligation and the rental charge will be applied. </Typography>
                        </Grid>
                        <Grid item sm={12}>
                        <Typography className={classes.title}>How long can I rent a tool for? </Typography>
                        </Grid>
                          <Grid item sm={12}>
                          <Typography variant="body2" className={classes.subtitle}>
                            Most rentals are for a day or more, but the duration the
                            tool(s) is needed for can be set by the renter, with a minimum rental period of half a day.
                          </Typography>
                          </Grid>
                      <Grid item sm={12}>
                        <Typography className={classes.title}>Can I reject a rental request? </Typography>
                      </Grid>
                          <Grid item sm={12}>
                        <Typography variant="body2" className={classes.subtitle}> 
                          Yes, all rental requests must be approved by the owner in
                          order to be confirmed.</Typography>
                          </Grid>
                          </Grid>
                          </ExpansionPanelDetails>
                    </ExpansionPanel>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        </Fragment>
      );
  }
}

const styles = (theme) => ({
  card: {
    padding: "30px 67px",
  },
  media: {
    height: 0,
    paddingTop: "480px",
    backgroundSize: "contain" // or cover
  },
  rating: {
    width: 200,
    display: "flex",
    alignItems: "center",
  },
  h5:{
    fontFamily: "Open Sans",
    fontSize: "18px",
    fontWeight: "600",
    fontStretch: "normal",
    fontStyle: "bold",
    lineHeight: 1.5,
    letterSpacing: " 0.15px",
    color: "rgba(0, 0, 0, 0.87)",
    marginTop:"10px"
  },
  title: {
    fontFamily: "Open Sans",
    fontSize: "16px",
    fontWeight: "600",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.5,
    letterSpacing: " 0.15px",
    color: "rgba(0, 0, 0, 0.87)",
  },
  subtitle: {
    fontFamily: "Open Sans",
    fontSize: "14px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.43",
    letterSpacing: "0.25px",
    color: "rgba(0, 0, 0, 0.6)",
  },
  description: {
    height: "96px",
    fontFamily: "Open Sans",
    fontSize: "16px",
    fontWeight: "600",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.5",
    letterSpacing: "0.15px",
  },
  text: {
    fontFamily: "Open Sans",
    fontSize: "16px",
    fontWeight: "600",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.5",
    letterSpacing: "0.15px",
  },
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    flexWrap: "nowrap",
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: "translateZ(0)",
  },
  titleBar: {
    background:
      "linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
  },
  main: {
    display: "flex",
  },
  left: {
    position: "absolute",
    right: 180,
  },
});

export default (withStyles(styles)(Faq));
