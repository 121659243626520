import React, { Fragment } from 'react';
//import { ToolDetailInfo } from './ToolDetailInfo';
import PropTypes from "prop-types";
import { RentalMap } from "../components/rental/RentalMap";
import Review from "../components/tool/Review";
import { connect } from "react-redux";
import { getTool, addProductToCart } from "../redux/actions/dataActions";
import Navbar from "../components/layout/Navbar";


// const Button1 = require('@material-ui/core/Button').default;

import * as qs from "query-string";
import {
  addDays
} from "date-fns";

import withStyles from "@material-ui/core/styles/withStyles";

// MUI Stuff
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  Card,
  CardMedia,
  CardActionArea,
  Box,
  Chip,
  Backdrop,
} from "@material-ui/core";

import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';
import Rating from "@material-ui/lab/Rating";

class ToolDetail extends React.Component {
  constructor() {
    super();
    this.state = {
      user: {},
      email: "",
      password: "",
      profile: false,
      errors: {},
      image:"",
      toolId:"",
      price:0,
      rating:0,
      noc:0,
      address:[]
    };
  }

  componentWillMount() {
    const profileId = this.props.match.params.profileId;
    let toolId = null;

    if (profileId) {
      this.setState({ profile: true });
      toolId = this.props.match.params.toolId;
    } else {
      this.setState({ profile: false });
      toolId = this.props.match.params.id;
    }
    this.setState({toolId: toolId});
    this.props.getTool(toolId);

    const parsed = qs.parse(this.props.location.toolId);
    let startDate = parsed.startDate ? new Date(parsed.startDate) : new Date();
    let endDate = parsed.endDate ? new Date(parsed.endDate) : addDays(new Date(), 1);

    this.setState({ startDate: startDate, endDate: endDate });
  }

  
  componentWillReceiveProps(nextProps) {
    if (nextProps.tool.toolId) {
      this.setState({ image: nextProps.tool.toolImageList[0] });
      this.setState({ name: nextProps.tool.name });
      this.setState({ price: nextProps.tool.price });
      this.setState({ rating: nextProps.tool.rating });
      this.setState({ noc: nextProps.tool.noc });
      this.setState({ userHandle: nextProps.tool.userHandle });
      this.setState({ address: nextProps.tool.address });
    }
  
    if (nextProps.user) {
        this.setState({ user: nextProps.user });
    }
  }

  handleSubmit = (event) => {
    event.preventDefault();
    const toolData = {
      price: this.state.price,
    };
    this.props.history.push("/checkout", toolData);
  };

  handleAddToCart = (event) => {
    event.preventDefault();
    console.log(this.state.toolId);

      const productData = {
        productId: this.state.toolId,
        price: this.state.price,
        imageURL: this.state.image,
        name: this.state.name,
        rating: this.state.rating,
        noc: this.state.noc,
        startDate: this.state.startDate,
        endDate: this.state.endDate,
        owner: this.state.userHandle,
        address: this.state.address
      };
    this.props.addProductToCart(productData)
  }

  changeImage = (event) => {
    event.preventDefault();
    this.setState({image: event.target.src})
  }

  render() {
    let height = "205px";

    let { classes, tool } = this.props;
    let { profile, userHandle, user } = this.state;

    if (tool.toolId) {
      if(!profile )
      {
      return (
        <Fragment>
        <Navbar />
        <Grid container style={{ marginTop: "50px" }} spacing={3}>
          <Grid item md={8} className={classes.desktop}>
            <Grid container spacing={2}>
              <Grid item md={12}>
                <Card className={classes.card}>
                  <CardActionArea>
                      <CardMedia
                        className={classes.media}
                        image={this.state.image}
                        title={tool.name}
                      />
                  </CardActionArea>
                </Card>
              </Grid>
              <Grid item md={12}>
                <div className={classes.root}>
                  <Grid container spacing={2}>
                    {tool.toolImageList.map((tile) => (
                      <Grid item md={3}>
                        <img src={tile} alt={tool.name} onClick={this.changeImage} />
                      </Grid>
                    ))}
                  </Grid>
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={4} sm={12}>
            <Grid container spacing={2}>
              <Grid item style={{ width: "100%" }} md={12} sm={12}>
                <Typography className={classes.title}>
                  {tool.name}
                </Typography>
              </Grid>
              <Grid item style={{ width: "100%" }} md={12} sm={12}>
                  <div className={classes.rating}>
                    <Rating
                      name="simple-controlled"
                      value={`${tool.rating}`}
                      readOnly
                      precision={0.5}
                    />
                    <Box ml={2} className={classes.subtitle}>
                      ({`${tool.noc}`})
                    </Box>
                  </div>
              </Grid>
              <Grid item style={{ width: "100%" }} md={12} sm={12}>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  className={classes.description}
                >
                  {tool.description}
                </Typography>
              </Grid>
              <Grid item style={{ width: "100%", marginBottom:"50px" }} md={12} sm={12} className={classes.mobile}>
                      <AwesomeSlider
                        cssModule={{backgroundColor: '#fff',}}
                        >
                        {tool.toolImageList.map((tile) => (
                          <div> 
                            <img alt="" src={tile} />
                          </div>
                          ))
                        }
                      </AwesomeSlider>
              </Grid>
              <Grid item style={{ width: "100%" }} md={12} sm={12}>
                <div className={classes.rating}>
                  <Typography className={classes.subtitle}>
                    Daily Rate:{" "}
                  </Typography>
                  <Typography className={classes.text}>
                    {" "}
                    {tool.price}
                  </Typography>
                </div>
              </Grid>
              <Grid item style={{ width: "100%" }} md={12} sm={12}>
                <div className={classes.rating}>
                  <Typography className={classes.subtitle}>
                    Category:{" "}
                  </Typography>
                  <Chip label={tool.category} />
                </div>
              </Grid>
              <Grid item style={{ width: "100%" }} md={12} sm={12}>
                  <RentalMap height={height} location={tool.address} />
              </Grid>
              <Grid item style={{ width: "100%" }} md={12} sm={12}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="secondary"
                    fullWidth
                    onClick={this.handleAddToCart}
                  >
                    Add to cart
                  </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={8} sm={12}>
                <Grid item md={12}>
                  <Review userTool={tool.userHandle}></Review>
                </Grid>
          </Grid>
        </Grid>
        </Fragment>
      );
      }
      else {
        if (userHandle === user.userHandle) {
          return (
            <Fragment>
            <Navbar />
            <Grid container style={{ marginTop: "50px" }} spacing={3}>
              <Grid item md={8} className={classes.desktop}>
                <Grid container spacing={2}>
                  <Grid item md={12}>
                    <Card className={classes.card}>
                      <CardActionArea>
                          <CardMedia
                            className={classes.media}
                            image={this.state.image}
                            title={tool.name}
                          />
                      </CardActionArea>
                    </Card>
                  </Grid>
                  <Grid item md={12}>
                    <div className={classes.root}>
                      <Grid container spacing={2}>
                        {tool.toolImageList.map((tile) => (
                          <Grid item md={3}>
                            <img src={tile} alt={tool.name} onClick={this.changeImage} />
                          </Grid>
                        ))}
                      </Grid>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={4} sm={12}>
                <Grid container spacing={2}>
                  <Grid item style={{ width: "100%", marginBottom:"50px" }} md={12} sm={12} className={classes.mobile}>
                        <AwesomeSlider
                          cssModule={{backgroundColor: '#fff',}}
                        >
                          {tool.toolImageList.map((tile) => (
                            <div> 
                              <img alt="" src={tile} />
                            </div>
                            ))
                          }
                        </AwesomeSlider>
                  </Grid>
                  <Grid item style={{ width: "100%" }} md={12} sm={12}>
                    <div className={classes.main}>
                      <div>
                        <Typography className={classes.title}>
                          {tool.name}
                        </Typography>
                      </div>
                      <div className={classes.left}>
                          <Button
                            color="secondary"
                            className={classes.button}
                            variant="contained"
                            size="small"
                            fullWidth
                          >
                            Edit
                          </Button>
                        </div>
                    </div>
                  </Grid>
                  <Grid item style={{ width: "100%" }} md={12} sm={12}>
                    <div className={classes.rating}>
                      <Rating
                        name="simple-controlled"
                        value={`${tool.rating}`}
                        readOnly
                        precision={0.5}
                      />
                      <Box ml={2} className={classes.subtitle}>
                        ({`${tool.noc}`})
                      </Box>
                    </div>
                  </Grid>
                  <Grid item style={{ width: "100%" }} md={12} sm={12}>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      className={classes.description}
                    >
                      {tool.description}
                    </Typography>
                  </Grid>
                  <Grid item style={{ width: "100%" }} md={12} sm={12}>
                    <div className={classes.rating}>
                      <Typography className={classes.subtitle}>
                        Daily Rate:{" $"}
                      </Typography>
                      <Typography className={classes.text}>
                        {tool.price}
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item style={{ width: "100%" }} md={12} sm={12}>
                    <div className={classes.rating}>
                      <Typography className={classes.subtitle}>
                        Category:{" "}
                      </Typography>
                      <Chip label={tool.category} />
                    </div>
                  </Grid>
                  <Grid item style={{ width: "100%" }} md={12} sm={12}>
                      <Typography
                        className={classes.subtitle}
                      >{`${tool.address.route}, ${tool.address.postal_code}, ${tool.address.administrative_area_level_1}`}</Typography>
                    </Grid>
                </Grid>
              </Grid>
              <Grid item md={8} sm={12}>
                <Grid item md={12}>
                  <Review userTool={tool.userHandle}></Review>
                </Grid>
              </Grid>
            </Grid>
            </Fragment>
          );
      }
      else{
        return (
          <Backdrop>
            <CircularProgress color="secondary" />
          </Backdrop>
        );
      }}}
      else {
      return (
        <Backdrop>
          <CircularProgress color="secondary" />
        </Backdrop>
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    tool: state.data.tool,
    user: state.user,
  };
}
const mapActionsToProps = {
  getTool,
  addProductToCart
};

ToolDetail.propTypes = {
  tool: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  profile: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired
};

const styles = (theme) => ({
  card: {
    padding: "30px 67px",
  },
  media: {
    height: 0,
    paddingTop: "480px",
    backgroundSize: "contain" // or cover
  },
  rating: {
    width: 200,
    display: "flex",
    alignItems: "center",
  },
  title: {
    maxWidth:'270px',
    fontFamily: "Open Sans",
    fontSize: "24px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1,
    letterSpacing: " 0.18px",
    color: "#000000",
  },
  subtitle: {
    fontFamily: "Open Sans",
    fontSize: "16px",
    fontWeight: "600",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.5",
    letterSpacing: "0.15px",
    color: "#000000",
    marginRight: "5px",
  },
  description: {
    minHeight: "50px",
    fontFamily: "Open Sans",
    fontSize: "16px",
    fontWeight: "600",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.5",
    letterSpacing: "0.15px",
  },
  text: {
    fontFamily: "Open Sans",
    fontSize: "16px",
    fontWeight: "600",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.5",
    letterSpacing: "0.15px",
  },
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,
  },
  desktop:{
    display:"flex",
    [theme.breakpoints.down("sm")]: {
      display:"none", 
    }
  },
  mobile:{
    display:"none",
    [theme.breakpoints.down("sm")]: {
      display:"flex",
    }
  },
  gridList: {
    flexWrap: "nowrap",
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: "translateZ(0)",
  },
  titleBar: {
    background:
      "linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
  },
  main: {
    display: "flex",
  },
  left: {
    position: "absolute",
    right: 180,
    [theme.breakpoints.down("sm")]: {
      right: 12,
    }
  },
});

export default connect(
  mapStateToProps,
  mapActionsToProps
)(withStyles(styles)(ToolDetail));
