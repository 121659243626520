import React, { Component, Fragment } from "react";
import {
  InputLabel,
  FormControl,
  Select,
  Grid,
  Button,
  Typography,
  MenuItem,
} from "@material-ui/core";

import MapOutlinedIcon from "@material-ui/icons/MapOutlined";
import ListIcon from '@material-ui/icons/List';

import withStyles from "@material-ui/core/styles/withStyles";


import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  getProductNameList,
  getProductList,
  getAllProducts,
  getCategoryList,
  getRatingList,
  getBrandList,
} from "../redux/actions/dataActions";

import * as qs from "query-string";

import ShopSidenav from "./ShopSidenav";
import ShopContainer from "./ShopContainer";
import Navbar from "../components/layout/Navbar";
import Filters from "../components/layout/Filters";

import {
  addDays
} from "date-fns";

import { getTime } from "date-fns";

class Shop extends Component {
  debounceTimer;
  categories = [];
  brands = [];

  state = {
    open: true,
    view: "grid",
    page: 0,
    rowsPerPage: 6,
    orderBy: "false",
    propsReceived: false,
    sliderRange: [0, 100],
    query: "",
    multilevel: "all",
    shipping: false,
    delivery: false,
    categories: [],
    brands: [],
    productsName: [],
    from: null,
    to: null,
    name: "",
    zip:"",
    chipCategoryData:[],
    chipBrandData:[],
    chipDeliveryData:[],
    chipData:[ { key: 0, label: "Reset"}]
  };

  toggleSidenav = () => {
    this.setState({ open: !this.state.open });
  };

  handleSliderChange = (event, newValue) => {
    this.setState({ sliderRange: newValue });
    this.filterProductOnPriceRange(newValue[0] * 10, newValue[1] * 10);
  };

  handleDateChange = (event, newValue) => {
    this.setState({ dateRange: newValue });
    this.filterProductOnDateRange(newValue[0], newValue[1]);
  };

  setRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value });
  };

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  toggleView = (view) => this.setState({ view });

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleSearch = (query) => {
    let { productList = [] } = this.props;

    this.setState({ query });

    if (this.debounceTimer) {
      clearTimeout(this.debounceTimer);
    }

    this.debounceTimer = setTimeout(() => {
      this.setState({
        productList: productList.filter((product) =>
          product.title.toLowerCase().match(this.state.query.toLowerCase())
        ),
      });
    }, 250);
  };

  handleChangeDateTo = (dateInput) => {
    var date = getTime(dateInput);
    this.setState({
      to: dateInput,
    });
    
    this.handleClearAllFilter();
    this.props.getProductList(this.state.name, this.state.zip, getTime(this.state.from), date );
  };

  handleChangeDateFrom = (dateInput) => {

    var date = getTime(dateInput);
    var dateEnd = getTime(this.state.to);

    this.setState({
      from: dateInput,
    });

    this.handleClearAllFilter();
    this.props.getProductList(this.state.name, this.state.zip, date, getTime(this.state.to));
  };

  handleMultilevelChange = (event) => {
    let eventValue = event.target.value;
    let range = event.target.value.split(",");
    let { productList = [] } = this.props;

    if (eventValue === "all") {
      this.setState({ multilevel: eventValue, productList });
      return;
    }

    this.setState({ multilevel: eventValue });

    range = range.map((value) => parseInt(value));

    if (range.length === 2) {
      this.filterProductOnPriceRange(range[0], range[1]);
    } else {
      this.setState({
        productList: productList.filter((product) => product.price >= range[0]),
      });
    }
  };

  handleCategoryChange = (event, newValue) => {
    let tempCategories=[];
    let tempChipData=[];
    if (newValue) {
      newValue.map((category) => (
        tempCategories.push(category.title),
        tempChipData.push({ key: category.title, label: category.title})
      ))

      this.setState({
        chipCategoryData: tempChipData,        
        categories: tempCategories,
        productList: this.filterProductOnProperty("category", tempCategories),
      });
    } 
  };

  handleProductNameChange = (event, value) => {
    this.handleClearAllFilter();
    if (value){
      this.setState({name: value.name})
      this.props.getProductList(value.name, this.state.zip, getTime(this.state.from), getTime(this.state.to));
    } else {
      this.setState({name: " "})
      this.props.getProductList(null, this.state.zip, getTime(this.state.from), getTime(this.state.to));
    }
  };

  handleZipChange = (event) => {
    this.handleClearAllFilter();
    if (event.target.value) {
      this.setState({zip: event.target.value})
      this.props.getProductList(this.state.name, event.target.value, getTime(this.state.from), getTime(this.state.to) );
    }
    else{
      this.setState({zip: null})
      this.props.getProductList(this.state.name, null, getTime(this.state.from), getTime(this.state.to) );
    }
  };

  handleBrandChange = (event, newValue) => {
    let tempBrands=[];
    let tempChipData=[];
    if (newValue) {
      newValue.map((brand) => (
        tempBrands.push(brand.title),
        tempChipData.push({ key: brand.title, label: brand.title})
      ))

      this.setState({
        chipBrandData: tempChipData,        
        brands: tempBrands,
        productList: this.filterProductOnProperty("brand", tempBrands),
      });
    } 
  };

  handleRatingClick = (rate) => {
    this.setState({
      productList: this.filterProductOnProperty("rating", [rate]),
    });
  };

  handleFreeShippingClick = () => {
    let shippingStatus = !this.state.shipping;
    let { chipData } = this.state;
    let tempChipData;    
    tempChipData = [{ key: shippingStatus, label: shippingStatus}, ...chipData]
      
    this.setState({
      chipData: tempChipData,
      shipping: shippingStatus,
      productList: this.filterProductOnProperty("freeShipping", [
        shippingStatus,
      ]),
    });
  };

  handleFreeDeliveryClick = () => {
    let deliveryStatus = !this.state.delivery;
    let { chipDeliveryData } = this.state;
    let tempChipData;    

    console.log(deliveryStatus)

    if (deliveryStatus) {
      tempChipData = [ { key: "delivery", label: "Delivery"}]
    }
    else{
      tempChipData = chipDeliveryData.filter((item) => item.key !== "delivery" )
    }
      
    this.setState({
      chipDeliveryData: tempChipData,      
      delivery: deliveryStatus,
      productList: this.filterProductOnProperty("delivery", [deliveryStatus]),
    });
  };

  handleDelete = () => {
    this.setState({
      sliderRange: [0, 100],
      query: "",
      multilevel: "all",
      shipping: false,
      delivery: false,
      categories: [],
      brands: [],
      productList: this.props.productList,
      chipData:[ { key: 0, label: "Reset"}],
      chipDeliveryData: [],
      chipBrandData: [],
      chipCategoryData: []
    });
  };

  filterProductOnProperty = (property, value = []) => {
    let { productList = [] } = this.props;
    if (value.length === 0) {
      return productList;
    }
    return productList.filter((product) => value.includes(product[property]));
  };

  filterProductOnPriceRange = (lowestPrice, highestPrice) => {
    let { productList = [] } = this.props;
    this.setState({
      productList: productList.filter(
        (product) =>
          product.price >= lowestPrice && product.price <= highestPrice
      ),
    });
  };

  filterProductOnDateRange = (fromDate, toDate) => {
    let { productList = [] } = this.props;
    this.setState({
      productList: productList.filter(
        (product) =>
          product.booking[0].startDate >= fromDate &&
          product.booking[0].endDate <= toDate
      ),
    });
  };

  handleClearAllFilter = () => {
    this.setState({
      sliderRange: [0, 100],
      query: "",
      multilevel: "all",
      shipping: false,
      delivery: false,
      categories: [],
      brands: [],
      productList: this.props.productList,
      chipData:[ { key: 0, label: "Reset"}],
      chipDeliveryData: [],
      chipBrandData: [],
      chipCategoryData: []
    });
  };

  componentWillMount() {
    const parsed = qs.parse(this.props.location.search);
    let startDate = parsed.startDate ? new Date(parsed.startDate) : new Date();
    let endDate = parsed.endDate ? new Date(parsed.endDate) : addDays(new Date(), 1);

    let zip = parsed.zip ? parsed.zip : null;
    this.setState({ zip: zip});

    let name = parsed.name ? parsed.name : null;
    this.setState({ name: name});

    this.props.getProductList(name, zip, getTime(startDate), getTime(endDate));

    this.setState({ from: startDate, to: endDate });
    this.setState({ fromTime: startDate, toTime: endDate });

    this.props.getCategoryList();
    this.props.getProductNameList();
    this.props.getRatingList();
    this.props.getBrandList();
  }

  componentWillUnmount() {
    clearTimeout(this.debounceTimer);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.productList) {
      this.setState({ productList: nextProps.productList });
    }
  }

  render() {
    let {
      productList = [],
      brands,
      categories,
      sliderRange,
      view,
      page,
      rowsPerPage,
      orderBy,
      query,
      multilevel,
      shipping,
      delivery,
      from,
      to,
      name,
      zip,
      chipData,
      chipCategoryData,
      chipBrandData,
      chipDeliveryData,
    } = this.state;

    let {
      classes,
      categoryList = [],
      ratingList = [],
      brandList = [],
      productNameList = [],
    } = this.props;



    return (
      <Fragment>
        <Navbar />
        <div style={{  boxSizing: "border-box" }}>
          <div style={{  boxSizing: "border-box", width: "25%", float: "left"}} className={classes.desktop}>
            <ShopSidenav
              query={query}
              categories={categories}
              brands={brands}
              multilevel={multilevel}
              categoryList={categoryList}
              brandList={brandList}
              productNameList={productNameList}
              ratingList={ratingList}
              shipping={shipping}
              delivery={delivery}
              from={from}
              to={to}
              productName={name}
              zip={zip}
              sliderRange={sliderRange}
              toggleSidenav={this.toggleSidenav}
              handleSearch={this.handleSearch}
              handleChangeDateFrom={this.handleChangeDateFrom}
              handleChangeDateTo={this.handleChangeDateTo}
              handleMultilevelChange={this.handleMultilevelChange}
              handleSliderChange={this.handleSliderChange}
              // handleDateChange={this.handleDateChange}
              handleCategoryChange={this.handleCategoryChange}
              handleBrandChange={this.handleBrandChange}
              handleProductNameChange={this.handleProductNameChange}
              handleChangeZip={this.handleZipChange}
              handleRatingClick={this.handleRatingClick}
              handleFreeShippingClick={this.handleFreeShippingClick}
              handleFreeDeliveryClick={this.handleFreeDeliveryClick}
              handleClearAllFilter={this.handleClearAllFilter}
            ></ShopSidenav>
          </div>
          <div className={classes.shopContainer} >
            <Grid container style={{paddingTop: "50px"}}> 
              <Grid item sm={12} style={{ width: "100%"}}>
                  <div style={{width:"99%"}} className={classes.container} >
                    <div className={classes.mobile}>
                      <Filters 
                          query={query}
                          categories={categories}
                          brands={brands}
                          multilevel={multilevel}
                          categoryList={categoryList}
                          brandList={brandList}
                          productNameList={productNameList}
                          ratingList={ratingList}
                          shipping={shipping}
                          delivery={delivery}
                          from={from}
                          to={to}
                          productName={name}
                          zip={zip}
                          sliderRange={sliderRange}
                          toggleSidenav={this.toggleSidenav}
                          handleSearch={this.handleSearch}
                          handleChangeDateFrom={this.handleChangeDateFrom}
                          handleChangeDateTo={this.handleChangeDateTo}
                          handleMultilevelChange={this.handleMultilevelChange}
                          handleSliderChange={this.handleSliderChange}
                          // handleDateChange={this.handleDateChange}
                          handleCategoryChange={this.handleCategoryChange}
                          handleBrandChange={this.handleBrandChange}
                          handleProductNameChange={this.handleProductNameChange}
                          handleChangeZip={this.handleZipChange}
                          handleRatingClick={this.handleRatingClick}
                          handleFreeShippingClick={this.handleFreeShippingClick}
                          handleFreeDeliveryClick={this.handleFreeDeliveryClick}
                          handleClearAllFilter={this.handleClearAllFilter}
                        ></Filters>
                    </div>
                    <div className={classes.grow} />
                    <div >
                        <Button
                          variant={view === "list" ? "outlined" : "contained"}
                          color= {view === "list" ? "inherit" : "secondary"}      
                          style={{ margin: 2, padding: 0, borderRadius: 5, maxWidth:"38px", minWidth: "38px" }}
                          onClick={() => this.toggleView("grid")}
                        >
                          <ListIcon
                            style={{ fontSize: 36, margin: 0, padding: 0 }}
                            color={view === "grid" ? "primary" : "inherit"}
                          />
                        </Button>
                        <Button
                          variant={view === "list" ? "contained" : "outlined"}  
                          color= {view === "list" ? "secondary" : "inherit"}               
                          style={{ margin: 2, padding: 0, borderRadius: 5, maxWidth:"38px", minWidth: "38px" }}
                          onClick={() => this.toggleView("list")}
                        >
                          <MapOutlinedIcon
                            variant="outlined"
                            style={{ fontSize: 36, margin: 0, borderColor:'gray' }}
                            color={view === "list" ? "primary" : "inherit"}
                          />
                        </Button>
                      </div>
                  </div>
                  <div style={{width:"99%"}} className={classes.container} >
                    <div style={{marginTop:"8px"}} >
                      <Typography gutterBottom variant="h5" component="h5" className="h5">
                          {productList.length ? `${productList.length} Results` : null}
                      </Typography>
                    </div>
                    <div className={classes.grow} />
                    <div style={{marginTop:"8px"}}>
                      <FormControl className={classes.marginR} style={{maxWidth: "129px",height:"36px"}}>
                        <InputLabel
                          id="orderBy-select-label"
                          className={classes.marginR}
                          style={{ maxWidth: "129px",height:"36px"}}
                        >
                        </InputLabel>
                        <Select
                          id="orderBy-select-label"
                          name="orderBy"
                          placeholder="Order By"
                          value={orderBy}
                          onChange={this.handleChange}
                          variant="outlined"
                          size="small"
                          fullWidth
                          style={{ maxWidth: "129px", height:"36px"}}
                          inputProps={{ className: classes.input }}
                        >
                          <MenuItem value="false">Sort By</MenuItem>
                          <MenuItem value="asc">Lowest Price</MenuItem>
                          <MenuItem value="desc">Highest Price</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                </Grid>
              <Grid item sm={12} style={{alignItems:"center"}}>
                <ShopContainer
                  orderBy={orderBy}
                  view={view}
                  productList={productList}
                  page={page}
                  product={name}
                  zip={zip}
                  rowsPerPage={rowsPerPage}
                  toggleView={this.toggleView}
                  toggleSidenav={this.toggleSidenav}
                  handleChange={this.handleChange}
                  handleChangePage={this.handleChangePage}
                  setRowsPerPage={this.setRowsPerPage}
                  startDate={from}
                  endDate={to}
                  chipData={[...chipCategoryData, ...chipBrandData, ...chipDeliveryData, ...chipData]}
                  handleDelete={this.handleDelete}
                ></ShopContainer>
            </Grid>
            </Grid>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  getProductList: PropTypes.func.isRequired,
  getAllProducts: PropTypes.func.isRequired,
  getRatingList: PropTypes.func.isRequired,
  getBrandList: PropTypes.func.isRequired,
  getProductNameList: PropTypes.func.isRequired,
  productList: state.data.toolList,
  categoryList: state.data.categoryList,
  ratingList: state.data.ratingList,
  brandList: state.data.brandList,
  productNameList: state.data.productNameList,
});

const styles = (theme) => ({
  grid:{
    paddingRight:"10px", 
    [theme.breakpoints.down("sm")]: {
      // marginLeft:"4px", 
    }
  },
  desktop:{
    display:"flex",
    [theme.breakpoints.down("sm")]: {
      display:"none", 
    }
  },
  shopContainer:
  {  
    boxSizing: "border-box", 
    width: "75%", 
    float: "left", 
    [theme.breakpoints.up("sm")]: {
      paddingLeft:"50px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%", 
      paddingLeft:"0px",

    }
  },
  mobile:{
    display:"flex",
    [theme.breakpoints.up("sm")]: {
      display:"none", 
    }
  },
  container:{
    alignItems: "center",
      flexGrow: 1, 
      display:"flex"
  },
  grow: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
  },
  marginR: {
    marginRight: 2,
  },
  input: {
    padding: 7,
    fontFamily: "Open Sans",
    fontSize: "12px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: " normal",
    lineHeight: "1.2",
    letterSpacing: "0.1px",
    color: "darkgray",
    alignItems: "left",
    justifyContent: "left",
    textAlign: "left",
    maxWidth: "129px"
  },
});

export default connect(mapStateToProps, {
  getProductList,
  getAllProducts,
  getCategoryList,
  getProductNameList,
  getRatingList,
  getBrandList,
})(withStyles(styles)(Shop));
