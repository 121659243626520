import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";

import SearchOutlinedIcon from "@material-ui/icons/SearchOutlined";
import PaymentOutlinedIcon from "@material-ui/icons/PaymentOutlined";
import BuildOutlinedIcon from "@material-ui/icons/BuildOutlined";

const styles = (theme) => ({
  root: {
    display: "flex",
    backgroundColor: theme.palette.white,
    overflow: "hidden",
    marginBottom: "100px",  
  },
  container: {
    marginTop: "300px",
    marginBottom: theme.spacing(2),
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding:"0px",
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(0),
    }
  },
  item: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    minWidth: "200px",

    [theme.breakpoints.down("sm")]: {
      flexDirection: "row",
      minWidth:"300px",
    }
  },
  title: {
    marginBottom: theme.spacing(2),
    fontFamily: "Open Sans",
    fontSize: "58",
    fontWeight: "500",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.17",
    letterSpacing: "normal",
    color: "#000000",
  },
  h5: {
    marginBottom: theme.spacing(2.5),
    padding: theme.spacing(0),
    //marginTop: theme.spacing(12),
    maxWidth: 480,
    fontSize: 48,
    fontFamily: "Open Sans",
    fontWeight: 600,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.2,
    letterSpacing: "-0.5px",
    color: theme.palette.black,
    textAlign:"center",
    [theme.breakpoints.down("sm")]: {
      marginTop: "0px",
      maxWidth: 350,
      fontSize: 34,
      marginBottom: "40px",
    },
  },
  number: {
    fontFamily: "Open Sans",
    fontSize: 24,
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1,
    letterSpacing: "0.18",
    color: "#000000",
    textAlign: "center",
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(1),
      textAlign: "left"
    },
  },
  image: {
    height: 80,
    marginTop: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      marginRight: theme.spacing(3),
      marginTop: theme.spacing(0),
    },
  },
  curvyLines: {
    pointerEvents: "none",
    position: "absolute",
    top: -180,
    opacity: 0.7,
  },
  button: {
    marginTop: theme.spacing(8),
  },
});

function ProductHowItWorks(props) {
  const { classes } = props;

  return (
    <section className={classes.root}>
      <Container className={classes.container}>
        <Typography
          color="inherit"
          align="left"
          variant="h4"
          marked="left"
          className={classes.h5}
        >
          How Toowlz Works
        </Typography>
        <div>
          <Grid container spacing={5}>
            <Grid item xs={12} md={4}>
              <div className={classes.item}>
                <SearchOutlinedIcon
                  alt="suitcase"
                  fontSize="large"
                  color="secondary"
                  className={classes.image}
                />
                <div >
                  <div className={classes.number}>Search</div>
                  <Typography variant="body2" align="justify">
                    Find the tools and equipment you need.
                  </Typography>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} md={4}>
              <div className={classes.item}>
                <PaymentOutlinedIcon
                  alt="graph"
                  fontSize="large"
                  color="secondary"
                  className={classes.image}
                />
                <div >
                <div className={classes.number}>Add to your cart</div>
                <Typography variant="body2" align="justify">
                  Pay through our secure site 
                </Typography>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} md={4}>
              <div className={classes.item}>
                <BuildOutlinedIcon
                  alt="clock"
                  fontSize="large"
                  color="secondary"
                  className={classes.image}
                />
                <div >
                <div className={classes.number}>Get to work!</div>
                <Typography variant="body2" align="justify">
                  Start your next project
                </Typography>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </Container>
    </section>
  );
}

ProductHowItWorks.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProductHowItWorks);
