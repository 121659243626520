import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  getTool,
  productsCart,
  deleteProductFromCart,
} from "../redux/actions/dataActions";

import formatDistance from 'date-fns/formatDistance'
import differenceInDays from 'date-fns/differenceInDays'

import Navbar from "../components/layout/Navbar";

import withStyles from "@material-ui/core/styles/withStyles";

// MUI Stuff
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import {
  IconButton
} from "@material-ui/core";

import DeleteForeverOutlinedIcon from "@material-ui/icons/DeleteForeverOutlined";
import axios from "axios";

class ConfirmOrder extends React.Component {
  constructor() {
    super();
    this.state = {
      user: {},
      email: "",
      password: "",
      profile: false,
      errors: {},
      image: "",
      toolId: "",
      price: 0,
      userHandle:"",
      confirm: false,
      payment:"",
      tools:[]
      // stripe: false
    };
  }

  handleSuccessCheckout(data){
    window.location.href = '/search'
  }

  confirmOrder = ( payment, amount) => {
    return axios.post("/confirmIntent", { payment, amount });
  }

  cancelOrder = (payment) => {
    return axios.post("/cancelIntent", { payment });
  }

  order = ( payment) => {
    return axios.post("/order", { payment });
  }



  componentWillMount() {
    const payment = this.props.match.params.payment;
    // let toolId = null;

    if (payment) {
      this.setState({ payment: payment });
      this.order(payment).then((data) =>
      this.setState({ tools: [...data.data] })
    );
    } 
  }

  getTotalCost = () => {
    let { tools = [] } = this.state;
    let totalCost = 0;
    tools.forEach(product => {
      totalCost += product.price * 
      differenceInDays(new Date(product.endDate), new Date(product.startDate));    });
    return totalCost;
  };

  handleSubmit = (event) => {
    event.preventDefault();

    this.confirmOrder(this.state.payment, this.getTotalCost())
    .then((data) =>
      window.location.href = '/search'
    );
  };

  handleCancel = (event) => {
    event.preventDefault();

    this.cancelOrder(this.state.payment)
    .then((data) =>
      window.location.href = '/search'
    );
  };

  changeImage = (event) => {
    event.preventDefault();
    this.setState({ image: event.target.src });
  };

  handleShipping = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  render() {

    let { classes } = this.props;
    let { zip, email, address, city, state, tools } = this.state;

    const shipping = {
      line1: address,
      email: email,
      zip: zip,
      city: city,
      state: state
    }; 

    let confirm = true
    if(tools === null) {
        confirm = false
    }
     else {

        tools.map((tool) => {
          if(tool.status === "Pending"){
            confirm = false;
          }
        })
     }

    return (
      <Fragment>
        <Navbar />
        <Grid container style={{ marginTop: "50px" }} spacing={10}>
        <Grid item md={8} sm={12}>
          <Grid container spacing={3}>
            <Grid item md={12} sm={12} style={{ width: "100%" }}>
              <Typography className={classes.title}>Items</Typography>
            </Grid>
            <Grid item md={12} sm={12} style={{ width: "100%" }}>
              <div className={classes.root}>
                <Grid container spacing={2}>
                  <Grid item md={12}>
                    {tools.map((product) => (
                      <Grid container spacing={2}>
                        <Grid item md={2}>
                          <div style={{ padding: "2px" }}>
                            <img src={product.image} alt="..." />
                          </div>
                        </Grid>

                        <Grid item md={10}>
                          <div key={product.id} className="cart__item">
                            <div
                              style={{ marginRight: "2px", textAlign: "left" }}
                            >
                              <h6
                                className={classes.subtitle}
                                style={{ marginBottom: "2px" }}
                              >
                                {product.name}
                              </h6>
                              <small className="text-muted">
                                {formatDistance(new Date(product.endDate), new Date(product.startDate))} rental
                              </small>
                              <div style={{ textAlign: "left" }}>
                  <small className="text-muted">
                    Status: {product.status}
                  </small>
                </div>
                <div style={{ textAlign: "left" }}>
                  <small className="text-muted">
                    Owner: {product.owner}
                  </small>
                </div>
                            </div>

                            <div
                              style={{ marginRight: "2px", textAlign: "right" }}
                            >
                              <h6 style={{ marginBottom: "2px" }}>
                                Price: ${parseFloat(product.price).toFixed(2) * differenceInDays(
  new Date(product.endDate),
  new Date(product.startDate)
)}
                              </h6>
                              <h6 style={{ marginBottom: "15px" }}>
                                {" "}
                              </h6>
                              <IconButton
                                size="small"
                                onClick={() =>
                                  this.props.deleteProductFromCart(
                                    product.productId
                                  )
                                }
                              >
                                <DeleteForeverOutlinedIcon fontSize="small" />
                              </IconButton>
                            </div>
                          </div>
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                  <Grid item md={12}>
                  <Grid container spacing={2}>
                        <Grid item md={12}>
                         
                        </Grid>
                      </Grid>
                  </Grid>              
                </Grid>
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={4} sm={12}>
          <Grid container spacing={2}>
            <Grid item md={12}>
              <Typography className={classes.title}>Order Summary</Typography>
            </Grid>
            <Grid item md={12}>
                  <Grid container spacing={2}>
                        <Grid item md={12}>
                          <div className="order_summary">
                            <div style={{ marginRight: "2px", textAlign: "left" }}>
                              <h6>
                                Subtotal
                              </h6>
                            </div>
                            <div style={{ marginRight: "2px", textAlign: "right" }}>
                              <h6>
                                ${this.getTotalCost().toFixed(2)}
                              </h6>
                            </div>
                          </div>
                        </Grid>
                        <Grid item md={12}>
                          <div className="order_summary">
                            <div style={{ marginRight: "2px", textAlign: "left" }}>
                              <h6>
                                Tax
                              </h6>
                            </div>
                            <div style={{ marginRight: "2px", textAlign: "right" }}>
                              <h6>
                                $0.00
                              </h6>
                            </div>
                          </div>
                        </Grid>
                        <Grid item md={12}>
                          <div className="order_summary">
                            <div style={{ marginRight: "2px", textAlign: "left" }}>
                              <h6>
                                Delivery
                              </h6>
                            </div>
                            <div style={{ marginRight: "2px", textAlign: "right" }}>
                              <h6>
                                $0.00
                              </h6>
                            </div>
                          </div>
                        </Grid>
                        <Grid item md={12}>
                          <div className="order_summary">
                            <div
                              style={{ marginRight: "2px", textAlign: "left" }}
                            >
                              <h6
                                className={classes.subtitle}
                                style={{ marginTop: "8px" }}
                              >
                                Total
                              </h6>
                            </div>
                            <div
                              style={{ marginRight: "2px", textAlign: "right" }}
                            >
                              <h6 className={classes.subtitle}
                                style={{ marginTop: "8px" }}
                                >
                                ${this.getTotalCost().toFixed(2)}
                              </h6>
                            </div>
                          </div>
                        </Grid>
                      </Grid>
                  </Grid>    
            <Grid item sm={12}>
              <Button
                type="submit"
                variant="contained"
                color="secondary"
                fullWidth
                onClick={this.handleSubmit}
                disabled={!confirm}
              >
                Confirm
              </Button>
            </Grid>
            <Grid item sm={12}>

            {!confirm && (
              <small className="text-muted" style={{color:"red"}}>
                               *Su compra esta en proceso de aprobacion
                              </small>
                          )
                }
            </Grid>
            <Grid item sm={12}>
              <Button
                type="submit"
                variant="outlined"
                color="secondary"
                fullWidth
                onClick={this.handleCancel}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    tool: state.data.tool,
    cartList: state.data.cartList,
  };
}
const mapActionsToProps = {
  getTool,
  productsCart,
  deleteProductFromCart
};

ConfirmOrder.propTypes = {
  tool: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  profile: PropTypes.bool.isRequired,
};

const styles = (theme) => ({
  card: {
    padding: "30px 67px",
  },
  media: {
    height: 0,
    paddingTop: "480px",
    backgroundSize: "contain", // or cover
  },
  rating: {
    width: 200,
    display: "flex",
    alignItems: "center",
  },
  title: {
    maxWidth: "270px",
    fontFamily: "Open Sans",
    fontSize: "24px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1,
    letterSpacing: " 0.18px",
    color: "#000000",
  },
  subtitle: {
    fontFamily: "Open Sans",
    fontSize: "16px",
    fontWeight: "600",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.5",
    letterSpacing: "0.15px",
    color: "#000000",
    marginRight: "5px",
  },
  description: {
    fontFamily: "Open Sans",
    fontSize: "16px",
    fontWeight: "600",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.5",
    letterSpacing: "0.15px",
  },
  text: {
    fontFamily: "Open Sans",
    fontSize: "16px",
    fontWeight: "600",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.5",
    letterSpacing: "0.15px",
  },
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    flexWrap: "nowrap",
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: "translateZ(0)",
  },
  titleBar: {
    background:
      "linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
  },
  main: {
    display: "flex",
  },
  left: {
    position: "absolute",
    right: 180,
  },
});

export default connect(
  mapStateToProps,
  mapActionsToProps
)(withStyles(styles)(ConfirmOrder));
