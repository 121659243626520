import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";

import backgroundImage from "../../../images/image_11.png";

const styles = (theme) => ({
  root: {
    //minHeight: 600,
    /* [theme.breakpoints.up('sm')]: {
      minHeight: 500,
      maxHeight: 1300,
    },*/
    /*paddingLeft: theme.spacing(20),
    paddingRight: theme.spacing(20),*/
  },
  background: {
    position: "absolute",
    left: 845,
    right: 0,
    top: 140,
    bottom: 0,
    minHeight: 800,
    //backgroundSize: 'contain',
    backgroundRepeat: "no-repeat",
    zIndex: -2,
    backgroundImage: `url(${backgroundImage})`,

    [theme.breakpoints.down("sm")]: {
      //marginTop: theme.spacing(30),
      left: 500,
      top: 0,
      minHeight: 200,

    },
  },
});

function ProductHeroLayout(props) {
  const { children } = props;

  return (
      <Container style={{padding:7}}>
        {children}
      {/* <div className={classes.background} /> */}
      </Container>
  );
}

ProductHeroLayout.propTypes = {
  backgroundClassName: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProductHeroLayout);
