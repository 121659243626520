// User reducer types
export const SET_AUTHENTICATED = 'SET_AUTHENTICATED';
export const SET_UNAUTHENTICATED = 'SET_UNAUTHENTICATED';
export const SET_USER = 'SET_USER';
export const SET_COMMENTS = 'SET_COMMENTS';
export const LOADING_USER = 'LOADING_USER';
export const MARK_NOTIFICATIONS_READ = 'MARK_NOTIFICATIONS_READ';
// UI reducer types
export const SET_ERRORS = 'SET_ERRORS';
export const LOADING_UI = 'LOADING_UI';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';
export const LOADING_DATA = 'LOADING_DATA';
export const UPDATE_DATA = 'UPDATE_DATA';
export const STOP_LOADING_UI = 'STOP_LOADING_UI';
// Data reducer types
export const SET_TOOL = 'SET_TOOL';
export const SET_TOOL_LIST = 'SET_TOOL_LIST';
export const SET_CATEGORY_LIST = 'SET_CATEGORY_LIST';
export const SET_RATING_LIST = 'SET_RATING_LIST';
export const SET_BRAND_LIST = 'SET_BRAND_LIST';
export const SET_PRODUCTS_NAME_LIST = 'SET_PRODUCTS_NAME_LIST';
export const SET_TOOLS_USER = 'SET_TOOLS_USER';
export const LIKE_TOOL = 'LIKE_TOOL';
export const UNLIKE_TOOL = 'UNLIKE_TOOL';
export const DELETE_TOOL = 'DELETE_TOOL';
export const POST_TOOL = 'POST_TOOL';
export const SUBMIT_COMMENT = 'SUBMIT_COMMENT';
export const UPDATED_TOOL = 'UPDATE_TOOL';
export const ADD_PRODUCT_TO_CART = 'ADD_PRODUCT_TO_CART';
export const SET_PRODUCTS_TO_CART = 'SET_PRODUCTS_TO_CART';
export const DELETE_NOTIFICATION = 'DELETE_NOTIFICATION';
export const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS';
export const DELETE_PRODUCT_FROM_CART = 'DELETE_PRODUCT_FROM_CART';
export const SET_PAYMENT = 'SET_PAYMENT';


