import React, { Component, Fragment } from "react";

// --- Post bootstrap -----
import Navbar from "../components/layout/Navbar";
import BlogNavBar from "../components/blog/BlogNavbar";


import Grid from "@material-ui/core/Grid";
import Select from "@material-ui/core/Select";

import {
  TextField,
  MenuItem,
} from "@material-ui/core";

import {
  getPostId,
  addNewPost,
  getAllPosts
} from "./BlogService";

import SendOutlinedIcon from '@material-ui/icons/SendOutlined';

import { RichTextEditor } from "../components/blog/blog";

class EditorForm extends Component {
  state = {
    content: `<h1>Toowlz | Template</h1><p><a href="http://toowlz.web.app/" target="_blank"><strong>Toowlz</strong></a></p><p><br></p><p><strong>Lorem Ipsum</strong>&nbsp;is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries</p>`,
    title:"", 
    category:"", 
    time:"", 
    autor:""
  };

  handleContentChange = contentHtml => {
    this.setState({
      content: contentHtml
    });
  };

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };


  handleSend = () => {
    let { title, content, category, time, autor, type } = this.state;
    if (title === "") return;

    addNewPost({
      title: title,
      content: content,
      category: category,
      type: type,
      time: time,
      autor: autor,
      image: "https://firebasestorage.googleapis.com/v0/b/toowlz.appspot.com/o/bg_post.jpg?alt=media&token=3c5f5a94-2c18-4e54-bd1c-cbee74e8ae36",

    }).then(data => {
      
      console.log(data)
    });
  };



  render() {
    return (
      <Fragment>

        <Navbar isTransparent={false}/>
        <BlogNavBar isTransparent={false}/>
        <Grid container spacing={3}>
        <Grid item sm={10} 
        style={{
          padding:"0px 150px",
          margin:"14px 0px"
        }}>
          <TextField
            placeholder="Title"
            value={this.state.title}
            id="title"
            name="title"
            onChange={this.handleChange}
            // onKeyDown={sendMessageOnEnter}
            fullWidth
            size="small"
            variant="outlined"
          />

        </Grid>
        <Grid item sm={7}
         style={{
          padding:"0px 150px",
          margin:"14px 0px"
        }}>
                          <Select
                            id="type"
                            name="type"
                            label="Type"
                            value={this.state.type}
                            onChange={this.handleChange}
                            variant="outlined"
                            size="small"
                            fullWidth
                            style={{ height: "48px" }}
                          >
                            <MenuItem value="videos">Video</MenuItem> 
                            <MenuItem value="articles">Article</MenuItem> 
                            <MenuItem value="podcast">Podcast</MenuItem> 

                          </Select>
        </Grid>
        <Grid item sm={7} 
        style={{
          padding:"0px 150px",
          margin:"14px 0px"
        }}>
          <TextField
            placeholder="Category"
            value={this.state.category}
            id="category"
            name="category"
            onChange={this.handleChange}
            // onKeyDown={sendMessageOnEnter}
            fullWidth
            size="small"
            variant="outlined"
          />

        </Grid>
        <Grid item sm={6}
        style={{
          padding:"0px 150px",
          margin:"14px 0px"
        }}>
          <TextField
            placeholder="Autor"
            value={this.state.autor}
            id="autor"
            name="autor"
            onChange={this.handleChange}
            // onKeyDown={sendMessageOnEnter}
            fullWidth
            size="small"
            variant="outlined"
          />

        </Grid>
        
        <Grid item sm={7} 
        style={{
          padding:"0px 150px",
          margin:"14px 0px"
        }}>
          <TextField
            placeholder="Time"
            value={this.state.time}
            id="time"
            name="time"
            onChange={this.handleChange}
            // onKeyDown={sendMessageOnEnter}
            fullWidth
            size="small"
            variant="outlined"
          />

        </Grid>
        
        <Grid item sm={12} style={{
          padding:"0px 150px"
        }}>
          <RichTextEditor
            content={this.state.content}
            handleContentChange={this.handleContentChange}
            placeholder="insert text here..."
          />
        </Grid>

        <Grid item sm={12} 
        style={{
          padding:"0px 150px",
          margin:"14px 0px"
        }}>
            <SendOutlinedIcon
              onClick={() => {
                this.handleSend();
              }}
              color="secondary"
            >
            </SendOutlinedIcon>
          </Grid>
          </Grid>
      </Fragment>
    );
  }
}

export default EditorForm;
