import React, { Component } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import Navbar from "../components/layout/Navbar";


// MUI Stuff
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  Card,
  Checkbox,
  FormGroup,
  FormControlLabel,
  CardContent,
} from "@material-ui/core";

// Redux stuff
import { connect } from "react-redux";
import { signupUser } from "../redux/actions/userActions";

import rectangle from "../images/rectangle.jpg";

class signup extends Component {
  constructor() {
    super();
    this.state = {
      email: "",
      password: "",
      confirmPassword: "",
      handle: "",
      lastName: "",
      errors: {},
    };
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.UI.errors) {
      this.setState({ errors: nextProps.UI.errors });
    }
  }
  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({
      loading: true,
    });
    const newUserData = {
      email: this.state.email,
      password: this.state.password,
      confirmPassword: this.state.confirmPassword,
      handle: this.state.handle,
      firstName: this.state.handle,
      lastName: this.state.lastName,
    };
    this.props.signupUser(newUserData, this.props.history);
  };
  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };
  render() {
    const {
      classes,
      UI: { loading },
    } = this.props;
    const { errors } = this.state;

    return (
      <div className={classes.root}>
      <Navbar isTransparent={true}/>
        <div className={classes.background} />
        <div style={{padding:"50px 20px 0px 20px"}}>

        <Card className={classes.card} display="flex" justifyContent="center">
          <CardContent className={classes.cardcontent}>
            <Grid container className={classes.form} spacing={3}>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Typography
                      color="inherit"
                      align="left"
                      variant="h4"
                      marked="left"
                      className={classes.h5}
                    >
                      Create an account
                    </Typography>
                </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Grid container spacing={3}>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <form noValidate onSubmit={this.handleSubmit} spacing={5}>
                      <Grid container spacing={2}>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <TextField
                            id="handle"
                            name="handle"
                            type="text"
                            label="First Name"
                            className={classes.textField}
                            helperText={errors.handle}
                            error={errors.handle ? true : false}
                            value={this.state.handle}
                            onChange={this.handleChange}
                            size="small"
                            variant="outlined"
                            fullWidth
                            InputLabelProps={{
                              classes: {
                                root: classes.cssLabel,
                                focused: classes.cssFocused,
                              },
                            }}
                            InputProps={{
                              classes: {
                                root: classes.cssOutlinedInput,
                                focused: classes.cssFocused,
                                notchedOutline: classes.notchedOutline,
                              },
                              inputMode: "numeric",
                            }}
                          />
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <TextField
                            id="lastName"
                            name="lastName"
                            type="text"
                            label="Last Name"
                            className={classes.textField}
                            helperText={errors.lastName}
                            error={errors.lastName ? true : false}
                            value={this.state.lastName}
                            onChange={this.handleChange}
                            size="small"
                            variant="outlined"
                            fullWidth
                            InputLabelProps={{
                              classes: {
                                root: classes.cssLabel,
                                focused: classes.cssFocused,
                              },
                            }}
                            InputProps={{
                              classes: {
                                root: classes.cssOutlinedInput,
                                focused: classes.cssFocused,
                                notchedOutline: classes.notchedOutline,
                              },
                              inputMode: "numeric",
                            }}
                          />
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <TextField
                            id="email"
                            name="email"
                            type="email"
                            label="Email"
                            className={classes.textField}
                            helperText={errors.email}
                            error={errors.email ? true : false}
                            value={this.state.email}
                            onChange={this.handleChange}
                            variant="outlined"
                            size="small"
                            fullWidth
                            InputLabelProps={{
                              classes: {
                                root: classes.cssLabel,
                                focused: classes.cssFocused,
                              },
                            }}
                            InputProps={{
                              classes: {
                                root: classes.cssOutlinedInput,
                                focused: classes.cssFocused,
                                notchedOutline: classes.notchedOutline,
                              },
                              inputMode: "numeric",
                            }}
                          />
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <TextField
                            id="password"
                            name="password"
                            type="password"
                            label="Password"
                            className={classes.textField}
                            helperText={errors.password}
                            error={errors.password ? true : false}
                            value={this.state.password}
                            onChange={this.handleChange}
                            size="small"
                            variant="outlined"
                            fullWidth
                            InputLabelProps={{
                              classes: {
                                root: classes.cssLabel,
                                focused: classes.cssFocused,
                              },
                            }}
                            InputProps={{
                              classes: {
                                root: classes.cssOutlinedInput,
                                focused: classes.cssFocused,
                                notchedOutline: classes.notchedOutline,
                              },
                              inputMode: "numeric",
                            }}
                          />
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <TextField
                            id="confirmPassword"
                            name="confirmPassword"
                            type="password"
                            label="Confirm Password"
                            className={classes.textField}
                            helperText={errors.confirmPassword}
                            error={errors.confirmPassword ? true : false}
                            value={this.state.confirmPassword}
                            onChange={this.handleChange}
                            size="small"
                            variant="outlined"
                            fullWidth
                            InputLabelProps={{
                              classes: {
                                root: classes.cssLabel,
                                focused: classes.cssFocused,
                              },
                            }}
                            InputProps={{
                              classes: {
                                root: classes.cssOutlinedInput,
                                focused: classes.cssFocused,
                                notchedOutline: classes.notchedOutline,
                              },
                              inputMode: "numeric",
                            }}
                          />
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <FormControlLabel
                            control={<Checkbox name="checkedC" />}
                            label={
                              <small className={classes.term}>
                                By signing up or signing in, I agree to Toowlz Terms of Service and Privacy Policy, confirm that I am 18 years of age or older
                              </small>
                            }
                          />
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          {errors.general && (
                            <Typography
                              variant="body2"
                              className={classes.customError}
                            >
                              {errors.general}
                            </Typography>
                          )}
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <Button
                            color="secondary"
                            className={classes.button}
                            variant="contained"
                            type="submit"
                            disabled={loading}
                            fullWidth
                          >
                            Sign Up
                            {loading && (
                              <CircularProgress
                                size={30}
                                className={classes.progress}
                              />
                            )}
                          </Button>
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <small className={classes.more}>
                            Already have an account ?
                          </small>
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <Button
                            type="link"
                            variant="outlined"
                            color="secondary"
                            className={classes.button}
                            disabled={loading}
                            fullWidth
                            href="/login"
                          >
                            Sign in
                          </Button>
                        </Grid>
                      </Grid>
                    </form>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <div className={classes.side} >
            <FormGroup>
                <FormControlLabel
                  classes={{ label: classes.label }}
                  control={<Checkbox value={true} checked={true}/>}
                  label="Add your tools"
                />
                <FormControlLabel
                  classes={{ label: classes.label }}
                  control={<Checkbox value={true} checked={true}/>}
                  label="Rent tools"
                />
                <FormControlLabel
                  classes={{ label: classes.label }}
                  control={<Checkbox value={true} checked={true}/>}
                  label="Start making money"
                />
              </FormGroup>
            </div>
          </CardContent>
        </Card>
        </div>
      </div>
    );
  }
}

signup.propTypes = {
  classes: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  UI: PropTypes.object.isRequired,
  signupUser: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user,
  UI: state.UI,
});

const styles = (theme) => ({
  root:{
    minHeight: 900,
  },
  side:{
    minWidth: "210px", 
    marginLeft:"40px",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    }

  },
  label:{
    fontFamily: "Open Sans",
    fontSize: "16px",
    fontWeight: 600,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.5,
    letterSpacing: "0.15px",
    color: "rgba(0, 0, 0, 0.87)",
  },
  card: {
    display: "flex",
    marginLeft: "auto",
    marginRight: "auto",
    maxWidth: "650px",
    marginTop: theme.spacing(10),
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(5),
    }
  },
  form:{
    maxWidth: "400px", 
    borderRight: "solid 1px gray", 
    paddingRight: "18px",
    [theme.breakpoints.down("sm")]: {
      borderRight: "solid 0px gray", 
      paddingRight: "12px"    
    }
  },

  term: {
    fontFamily: "Open Sans",
    fontSize: "11px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.45,
    letterSpacing: "0.4px",
    color: "rgba(0, 0, 0, 0.6)",
  }
  ,
  cardcontent: {
    //margin: '4px',
    padding: "30px",
    "&:last-child": {
      paddingBottom: "30px",
    },
    display:"flex",
    alignItems: "center",
  },
  button: {
    borderRadius: 5,
    height: "48px",
    fontSize: 14,
    margin: theme.spacing(0),
  },
  h5: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(0),
    fontFamily: "Open Sans",
    fontSize: "24px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1,
    letterSpacing: "0.18px",
    color: "rgba(0, 0, 0, 0.87)",
    alignItems: "left",
    justifyContent: "left",
    textAlign: "left",
  },
  more: {
    display: "flex",
    fontFamily: "Open Sans",
    fontSize: "12px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: " normal",
    lineHeight: "1.33",
    letterSpacing: "0.4px",
    color: "#000000",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
  },
  background: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    minHeight: 900,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    zIndex: -10,
    backgroundImage: `url(${rectangle})`,
  },
  cssLabel: {
    color: "gray",
  },
  cssOutlinedInput: {
    "&$cssFocused $notchedOutline": {
      borderColor: `gray !important`,
      color: "gray",
    },
  },
  cssFocused: { color: "gray" },

  notchedOutline: {
    borderColor: "gray !important",
  },
});

export default connect(mapStateToProps, { signupUser })(
  withStyles(styles)(signup)
);
