import React, { useState } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Paper from '@material-ui/core/Paper';
import header from "../../images/bg_blog.jpg";
import PostCard from "./PostCard"


const styles = (theme) => ({
  background: {
    position: "relative",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    minHeight: "560px",
    objectFit: "contain",
    backgroundSize: 'contain',
    backgroundRepeat: "no-repeat",
    zIndex: -10,
    backgroundImage: `url(${header})`,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  card:{
    position: "absolute",
    left: 205,
    right: 0,
    top: 395,
    bottom: 0,
    maxHeight: "278px",
    maxWidth: "280px",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  }  
});

function Header(props) {
  const { classes, post } = props;
  return (
      <Container style={{padding:"0px"}}>
        <div className={classes.background} />
        <div 
          className={classes.card}
        > 
        {post ?  (
        <PostCard 
          media={false} 
          width={290} 
          post={post}
          ></PostCard>
        ):""}
        </div>
      </Container>
  );
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Header);
