import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import Avatar from "@material-ui/core/Avatar";
import Rating from "@material-ui/lab/Rating";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  details: {
    display: "flex",
    flexDirection: "column",
  },
  large: {
    width: "170px",
    height: "170px",
    margin: theme.spacing(1),
  },
  button: {
    borderRadius: 5,
    height: "40px",
    fontSize: 14,
    margin: theme.spacing(0),
  },
  main: { 
    display: "flex" 
  },
  left: { 
    position: "absolute", 
    right: 170 
  },
  rating: {
    width: 200,
    display: "flex",
    alignItems: "center",
  },
}));

export default function MediaControlCard(user) {
  const classes = useStyles();
  console.log(user);
  return (
    <Card className={classes.root} elevation={0}>
      <Avatar
        alt={user.user.credentials.firstName}
        src={user.user.credentials.imageUrl}
        className={classes.large}
      />
      <div className={classes.details}>
        <CardContent className={classes.content}>
          <div className={classes.main}>
            <div className={classes.a}>
              <Typography component="h5" variant="h5">
                {user.user.credentials.firstName}
              </Typography>
            </div>

            <div className={classes.left}>
              <Button
                color="secondary"
                className={classes.button}
                variant="outlined"
                fullWidth
              >
                Edit
              </Button>
            </div>
          </div>
          <div className={classes.rating}>
            <Rating
              name="simple-controlled"
              value={user.user.credentials.rating}
              readOnly
              precision={0.2}
            />
            <Box ml={2}>({`${user.user.credentials.noc}`})</Box>
          </div>

          <Typography variant="body2" color="textSecondary">
            Sunt amet reprehenderit exercitation dolore aliqua sit aliquip
            excepteur enim reprehenderit. Magna culpa tempor enim proident irure
            eu esse dolore incididunt. Ex voluptate proident laborum duis
            adipisicing adipisicing qui consectetur consectetur reprehenderit
            nisi mollit nostrud. Exercitation reprehenderit aute adipisicing
            commodo ex esse in adipisicing magna sunt.
          </Typography>
        </CardContent>
      </div>
    </Card>
  );
}
