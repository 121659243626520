import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Card,
  Box,
  Chip,
  Grid,
  CardActionArea,
  Link,
  CardMedia,
  CardContent,
  Typography,
} from "@material-ui/core";

import Avatar from "@material-ui/core/Avatar";

import ShareIcon from '@material-ui/icons/Share';
import ThumbUpOutlinedIcon from '@material-ui/icons/ThumbUpOutlined';

import { connect } from "react-redux";

import { format } from "date-fns";


const useStyles = makeStyles((theme) => ({
  root: {
    padding: "16px",
  },
  chip: {
    padding: theme.spacing(0),
    margin: theme.spacing(0),
    fontFamily: "Open Sans",
    fontSize: "12px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: " normal",
    lineHeight: "1.2",
    letterSpacing: "0.1px",
    color: "#217cbd",
    maxHeight:"22px"
  },
  media: {
    height: 0,
    paddingTop: "100%",//"56.25%", // 16:9,
    backgroundSize: "cover",
    backgroundPosition: "center",
    borderRadius:"12px"
    // transform: "scaleY(1.5)"
  },
  rating: {
    // width: 200,
    display: "flex",
    alignItems: "center",
  },
  ratingText: {
    fontFamily: "Open Sans",
    fontSize: "12px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.33,
    letterSpacing: "0.4px",
    color: "rgba(0, 0, 0, 0.6)",
  },
  productName: {
    fontFamily: "Open Sans",
    fontSize: "16px",
    fontWeight: 600,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.5,
    letterSpacing: "0.15px",
    color: "#000000",
  },
  flex: {
    display: "flex",
  },
  details: {
    display: "flex",
    flexDirection: "column",

  },
  large: {
    width: "40px",
    height: "40px",
    margin: "8px 8px 8px 0px",
  },
  button: {
    borderRadius: 5,
    height: "40px",
    fontSize: 14,
    margin: theme.spacing(0),
  },
  main: { 
    display: "flex" 
  },
  left: { 
    position: "absolute", 
    right: 170 
  }
}));

const PostCard = ({ post, media, width }) => {
  const classes = useStyles();


  const handleClickDetail = () => {
    window.location.href = `/post/${post.postId}`
  }

  return (
      <Card
        elevation={0}
        className={classes.root} 
        style={{width: `${width}`}}

        >
        <CardActionArea style={{padding:0}}>
          <Link
            className="rental-detail-link"
            onClick={() => {
              handleClickDetail()
  }}
           // href={`/detail/${post.toolId}`}
          >
          {media ? (
            <CardMedia
              m={50}
              className={classes.media}
              image={(post.image)?post.image:null}
              title={post.title}
            />):""}
          </Link>
        </CardActionArea>
          <CardContent style={{padding:0}}>
          <Typography style={
            {
              fontFamily: "Open Sans",
              fontSize: "10px",
              fontWeight: "600",
              fontStretch: "normal",
              fontStyle: "normal",
              lineHeight: "1.6",
              letterSpacing: "1.5px",
              color: "rgba(0, 0, 0, 0.87)",
              margin:"12px 0px 12px 0"
            }
          }> {post.time} MINUTES READ </Typography>

            <div className="spaced__item" style={{
                            margin:"0 0px 14px 0"
            }}>
              <div>
                <Chip
                label={post.category}
                style={{ marginTop: 3, 
                fontFamily: "Open Sans",
                fontSize: "14px",
                fontWeight: "600",
                fontStretch: "normal",
                fontStyle: "normal",
                lineHeight: "1.6",
                letterSpacing: "1.5px",
                color: "#cb2f30",
                borderRadius:"4px",
                backgroundColor: "rgba(203, 47, 48, 0.2)",
  }}
              // className={classes.chip}
            />
              </div>
              <div style={{display:"flex", padding:"0 6px 0 0px"}}>
                  <ShareIcon
                    style={{ color: "gray", fontSize: 16, margin: "4 0 0 2"}}
                  />
                  <div style={{
                    margin:"2px 10px 0 4px",
                    fontFamily: "Open Sans",
                    fontSize: "14px",
                    fontWeight: "normal",
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: "1.43",
                    letterSpacing: "0.25px",
                    color: "rgba(0, 0, 0, 0.6)",
                  }}>
            {post.shares}
          </div>

                  <ThumbUpOutlinedIcon
                    style={{ color: "gray", fontSize: 16, margin: "4 0 0 2" }}
                  />
                  <div style={{
                    margin:"2px 10px 0 4px",
                    fontFamily: "Open Sans",
                    fontSize: "14px",
                    fontWeight: "normal",
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: "1.43",
                    letterSpacing: "0.25px",
                    color: "rgba(0, 0, 0, 0.6)",
                  }}>
            {post.likes}
          </div>
              </div>
            </div>

            <Link
              href={`/post/${post.postId}`}
          >
            <div style={
              {
                fontFamily: "Open Sans",
                fontSize: "24px",
                fontWeight: "bold",
                fontStretch: "normal",
                fontStyle: "normal",
                lineHeight: 1.25,
                letterSpacing: "0.18px",
                color: "rgba(0, 0, 0, 0.87)",
                margin:"0 0px 14px 0"
              }
            }>
              {post.title}

              {/* Nostrud anim incidi dunt ipsum amet an nostrud deserun ea. */}
            </div>
            </Link>

            <Card className={classes.flex} elevation={0}>
      <Avatar
        alt="Remy Sharp"
        src="https://firebasestorage.googleapis.com/v0/b/toowlz.appspot.com/o/no-user.png?alt=media&token=4c4ef840-fada-4119-8fbc-7f853a004687"
        className={classes.large}
      />
      <div className={classes.details}>
        <CardContent style={{padding:"7px 0 0 0"}}>
          <div className={classes.main}>
              <Typography variant="body2" style={
                {
                  fontFamily: "Open Sans",
                  fontSize: "16px",
                  fontWeight: "bold",
                  fontStretch: "normal",
                  fontStyle: "normal",
                  lineHeight: 1.5,
                  letterSpacing: "0.15px",
                  color: "rgba(0, 0, 0, 0.87)",
                }
              }>
                {post.autor}
              </Typography>
          </div>
          <div style={
            {
              fontFamily: "Open Sans",
              fontSize: "14px",
              fontWeight: "normal",
              fontStretch: "normal",
              fontStyle: "normal",
              lineHeight: "1.43",
              letterSpacing: "0.25px",
              color: "rgba(0, 0, 0, 0.38)",
            }
          }>
          {console.log("post.date", post.date)}
          {  post.date ? format(
                                    new Date(post.date).getTime(),
                                    "MMMM dd yyyy"
                                  ): null}
          </div>
        </CardContent>
      </div>
    </Card>

            
          </CardContent>
      </Card>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(PostCard);
