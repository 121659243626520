import React, { Component } from "react";

import { Link } from "react-router-dom";


class Brand extends Component {
  state = {};
  render() {
    const { isTransparent } = this.props;

    let src = isTransparent ? "/white_logo.png" : "/black_logo.png";

    return (
      <div style={{padding:"0px"}} className="flex flex-middle flex-space-between brand-area">
        <div style={{padding:"0px"}} className="flex flex-middle brand">
        <Link
                tip="HOME"
                onClick={() => {
                  window.location.href = "/";
                }}
                // color="inherit"
              >
          <img src={src} alt="company-logo" />
        </Link>
        </div>
        {this.props.children}
      </div>
    );
  }
}

export default Brand;
